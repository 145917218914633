import { takeLatest, put, call, select } from "redux-saga/effects";
import { actions } from "./users.slice";
import requestSaga from "../requestSaga";
import { RootState } from "../rootReducer";
import { loadRoleRequestName, User } from "../user";
import { eventGA } from "../../utils/googleAnalytics";

type UsersResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: User[];
  };
} | void;

const limit = 20;

function formatUsers(res: UsersResponse) {
  if (!res) {
    return {
      listUsers: null,
    };
  }

  return {
    listUsers: {
      ...res.data.results,
      items: res.data.results.map((item) => ({
        ...item,
      })),
      count: res.data.count,
      next: res.data.next,
      previous: res.data.previous,
    },
  };
}

export const loadUsersRequestName = "loadUsers";

function* loadUsers(action: ReturnType<typeof actions.load>) {
  const { pageRows, sort, page } = action.payload;
  const filterParam = action?.payload?.filter
    ? Object.entries(action?.payload?.filter).reduce(
        (prev, curr) =>
          (prev += curr[1] ? `${curr[0]}=${String(curr[1])}&` : ""),
        ""
      )
    : "";

  const localStorageRows = localStorage.getItem("pageRows");
  const res: UsersResponse = yield call(requestSaga, {
    name: loadUsersRequestName,
    errorNotification: {
      message: "Invalid token.",
    },
    drf: {
      method: "get",
      url: action?.payload
        ? `/api/v1/accounts/?${filterParam}ordering=${
            sort?.dir === "asc" ? sort?.field : `-${sort?.field}`
          }&page=${page || 1}&per_page=${pageRows || localStorageRows || 10}`
        : `/api/v1/accounts/`,
    },
  });

  if (res) {
    // @ts-ignore
    yield put(actions.loaded(formatUsers(res)));
    yield call(eventGA, "Users", `Load ${limit} Users`, "Table");
  }
}

export const loadMoreUsersRequestName = "loadMoreUsers";

function* loadMoreUsers(action: ReturnType<typeof actions.loadedMore>) {
  // @ts-ignore
  const nextUrl = yield select(
    (state: RootState) => state.users.data.listUsers?.next ?? null
  );
  const res: UsersResponse = yield call(requestSaga, {
    name: loadMoreUsersRequestName,
    errorNotification: {
      message: "Invalid token.",
    },
    drf: {
      method: "get",
      url: nextUrl,
    },
  });

  if (res) {
    // @ts-ignore
    yield put(actions.loadedMore(formatUsers(res)));
    yield call(eventGA, "Users", `Load ${limit} Users`, "Table");
  }
}

const deleteUserAPIRequestName = "deleteUserAPI";
const disableUserAPIRequestName = "disableUserAPI";
export const deleteUserRequestNames = [
  disableUserAPIRequestName,
  deleteUserAPIRequestName,
];

function* deleteUserSaga(action: ReturnType<typeof actions.deleteUser>) {
  const userData = new FormData();
  userData.append("username", action.payload);
  // @ts-ignore
  const response = yield call(requestSaga, {
    drf: {
      url: "/auth/delete/",
      method: "delete",
      data: userData,
    },
    name: deleteUserAPIRequestName,
  });

  if (response) {
    // @ts-ignore
    const filters = yield select((state: RootState) => state.users.filterQuery);
    yield put(actions.load(filters));
    yield call(eventGA, "Users", `Delete ${action.payload} User`, "Button");
  }
}

export const deleteUsersAPIRequestName = "deleteUsersAPI";
function* deleteUsersSaga(action: ReturnType<typeof actions.deleteUsers>) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "post",
        url: "/auth/delete/",
        data: {
          users: action.payload,
        },
      },
      name: deleteUserAPIRequestName,
      successNotification: {
        message: `${action.payload.length} users has been deleted`,
      },
    });

    if (response) {
      // @ts-ignore
      const filters = yield select(
        (state: RootState) => state.users.filterQuery
      );
      yield put(actions.load(filters));
      yield call(
        eventGA,
        "Users",
        `Delete ${action.payload.length} Users`,
        "Button"
      );
    }
  }
}

export const alertUsersAPIRequestName = "alertUsersAPI";
function* alertUsersSaga(action: ReturnType<typeof actions.alertUsers>) {
  const users = action.payload.users;
  const op_notes = action.payload.op_notes;
  if (users && op_notes) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "post",
        url: "/api/v1/accounts/alert/",
        data: {
          op_notes: op_notes,
          users: users,
        },
      },
      name: deleteUserAPIRequestName,
      successNotification: {
        message: `${users.length} users has been alerted about ${op_notes.length} OpNotes`,
      },
    });

    if (response) {
      yield call(eventGA, "Users", `Alert ${users.length} Users`, "Button");
    }
  }
}

export const reInviteUserRequestName = "reInviteUser";

function* reInviteUserSaga(action: ReturnType<typeof actions.reInviteUser>) {
  // yield call(requestSaga, {
  //   rest: {
  //     apiName: 'AdminQueries',
  //     url: '/reinviteUser',
  //     method: 'post',
  //     data: {
  //       body: {
  //         username: action.payload,
  //       },
  //     },
  //   },
  //   name: reInviteUserRequestName,
  //   successNotification: {
  //     message: 'Success reinviting NewUser',
  //   },
  // });
}

export const saga = function* () {
  yield takeLatest(actions.load.type, loadUsers);
  yield takeLatest(actions.loadMore.type, loadMoreUsers);
  yield takeLatest(actions.deleteUser.type, deleteUserSaga);
  yield takeLatest(actions.deleteUsers.type, deleteUsersSaga);
  yield takeLatest(actions.alertUsers.type, alertUsersSaga);
  yield takeLatest(actions.reInviteUser.type, reInviteUserSaga);
};
