import React, { useEffect, useState } from "react";
import "./singleblog.css";
import { Container, Col, Row, Button } from "react-bootstrap";
import Playlogo from "../../image/Playlogo.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { blogData } from "../../utils/Blogs/BlogData";

const SingleBlog = ({ match }: any) => {
  const [loading, setLoading] = useState<any>(false);
  const [singleBlog, setSingleBlog] = useState<any>();

  useEffect(() => {
    setLoading(true);
    const params = match.params;
    const singleBlogResult = blogData.find(
      (item: any) => item.blogId === params.singleBlogId
    );
    setSingleBlog(singleBlogResult);
    setLoading(false);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Row className="blog-head-main-row">
          <Col className="blog-head-main">
            <div className="blog-text-title">Gale AI Insights</div>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="blog-text-second-title">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores{" "}
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <div className="d-md-flex align-items-stretch blog-container">
        <div className="blogMain">
          <div id="content" className="p-4 p-md-5 pt-5 blog-main">
            <Row className=" blog-row">
              {loading
                ? "Loading"
                : singleBlog?.blogFullBody && (
                    <Col
                      xl={12}
                      xxl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="mb-5 singleBlogCol"
                    >
                      {singleBlog.blogFullBody}
                    </Col>
                  )}
            </Row>
          </div>
          <nav id="sidebar" className="sidebar-container">
            {/* <div className="p-4 pt-5 pb-0 sidebar">
              <Col className="blog-featured-col">
                <div className="blog-featured">Featured</div>
                <div className="blog-featured-second">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
              </Col>
            </div> */}

            <div className="part-contain">
              <Col md={{ span: 7, offset: 1 }}>
                <div className="forever-text">
                  Be a part of changing healthcare — forever
                </div>
                <div style={{ paddingTop: "2rem" }}>
                  <Button
                    variant="outline-success"
                    className="btn-btn-get-blog"
                  >
                    <span className="get-text-blog">Get Started</span>
                    &nbsp;&nbsp;It’s Free
                  </Button>
                </div>
                <div>
                  <img className="blog-play-img" alt="img" src={Playlogo} />
                </div>
              </Col>
            </div>
          </nav>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleBlog;
