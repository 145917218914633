/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type OpNoteCptCodesApproveRejectInput = {
  opNoteId: string,
  cptCodeCodes?: Array< string > | null,
};

export type CreateTenantInput = {
  name: string,
};

export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Tenant = {
  __typename: "Tenant",
  id?: string,
  name?: string,
  active?: boolean,
  createdBy?: string,
  updatedBy?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  _version?: number | null,
};

export type CreateOpNoteInput = {
  text: string,
};

export type ModelOpNoteConditionInput = {
  text?: ModelStringInput | null,
  type?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOpNoteConditionInput | null > | null,
  or?: Array< ModelOpNoteConditionInput | null > | null,
  not?: ModelOpNoteConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type OpNote = {
  __typename: "OpNote",
  id?: string,
  text?: string,
  type?: string | null,
  tenantId?: string | null,
  owner?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  approvedCptCodes?: ModelOpNoteCptCodeApprovedConnection,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type ModelOpNoteCptCodeApprovedConnection = {
  __typename: "ModelOpNoteCptCodeApprovedConnection",
  items?:  Array<OpNoteCptCodeApproved | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OpNoteCptCodeApproved = {
  __typename: "OpNoteCptCodeApproved",
  id?: string,
  opNoteId?: string,
  cptCodeCode?: string,
  tenantId?: string | null,
  approvedBy?: string | null,
  approvedAt?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateOpNoteInput = {
  id: string,
  text?: string | null,
  _version?: number | null,
};

export type DeleteOpNoteInput = {
  id?: string | null,
  _version?: number | null,
};

export type OpNoteCptCodeConsolidated = {
  __typename: "OpNoteCptCodeConsolidated",
  cptCode?: CptCode,
  linkType?: OpNoteCptCodeLinkType,
  approvedBy?: string | null,
  approvedAt?: string | null,
};

export type CptCode = {
  __typename: "CptCode",
  code?: string,
  description?: string | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
  createdAt?: string,
  updatedAt?: string,
};

export enum OpNoteCptCodeLinkType {
  APPROVED = "APPROVED",
  SUGGESTED = "SUGGESTED",
  REJECTED = "REJECTED",
  ASSIGNED = "ASSIGNED",
  CODED = "CODED",
}


export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items?:  Array<Tenant | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOpNoteFilterInput = {
  id?: ModelIDInput | null,
  text?: ModelStringInput | null,
  type?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOpNoteFilterInput | null > | null,
  or?: Array< ModelOpNoteFilterInput | null > | null,
  not?: ModelOpNoteFilterInput | null,
};

export type ModelOpNoteConnection = {
  __typename: "ModelOpNoteConnection",
  items?:  Array<OpNote | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCptCodeFilterInput = {
  code?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCptCodeFilterInput | null > | null,
  or?: Array< ModelCptCodeFilterInput | null > | null,
  not?: ModelCptCodeFilterInput | null,
};

export type ModelCptCodeConnection = {
  __typename: "ModelCptCodeConnection",
  items?:  Array<CptCode | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOpNoteCptCodeApprovedFilterInput = {
  id?: ModelIDInput | null,
  opNoteId?: ModelIDInput | null,
  cptCodeCode?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  approvedBy?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  and?: Array< ModelOpNoteCptCodeApprovedFilterInput | null > | null,
  or?: Array< ModelOpNoteCptCodeApprovedFilterInput | null > | null,
  not?: ModelOpNoteCptCodeApprovedFilterInput | null,
};

export type SearchableOpNoteFilterInput = {
  id?: SearchableIDFilterInput | null,
  text?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  tenantId?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableOpNoteFilterInput | null > | null,
  or?: Array< SearchableOpNoteFilterInput | null > | null,
  not?: SearchableOpNoteFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableOpNoteSortInput = {
  field?: SearchableOpNoteSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOpNoteSortableFields {
  id = "id",
  text = "text",
  type = "type",
  tenantId = "tenantId",
  owner = "owner",
  updatedBy = "updatedBy",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableOpNoteConnection = {
  __typename: "SearchableOpNoteConnection",
  items?:  Array<OpNote | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type SearchableCptCodeFilterInput = {
  code?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  and?: Array< SearchableCptCodeFilterInput | null > | null,
  or?: Array< SearchableCptCodeFilterInput | null > | null,
  not?: SearchableCptCodeFilterInput | null,
};

export type SearchableCptCodeSortInput = {
  field?: SearchableCptCodeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCptCodeSortableFields {
  code = "code",
  description = "description",
}


export type SearchableCptCodeConnection = {
  __typename: "SearchableCptCodeConnection",
  items?:  Array<CptCode | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ApproveOpNoteCptCodesMutationVariables = {
  input?: OpNoteCptCodesApproveRejectInput,
};

export type SubmitOpNoteCptCodesMutationVariables = {
  input?: OpNoteCptCodesApproveRejectInput,
};

export type ApproveOpNoteCptCodesMutation = {
  approveOpNoteCptCodes: Array< string >,
};

export type RejectOpNoteCptCodesMutationVariables = {
  input?: OpNoteCptCodesApproveRejectInput,
};

export type RejectOpNoteCptCodesMutation = {
  rejectOpNoteCptCodes: Array< string >,
};

export type ActivateTenantMutationVariables = {
  tenantId?: string,
};

export type ActivateTenantMutation = {
  activateTenant?: string | null,
};

export type DeactivateTenantMutationVariables = {
  tenantId?: string,
};

export type DeactivateTenantMutation = {
  deactivateTenant?: string | null,
};

export type DeleteTenantMutationVariables = {
  tenantId?: string,
};

export type DeleteTenantMutation = {
  deleteTenant?: string | null,
};

export type CreateTenantMutationVariables = {
  input?: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    active: boolean,
    createdBy: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input?: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    active: boolean,
    createdBy: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOpNoteMutationVariables = {
  input?: CreateOpNoteInput,
  condition?: ModelOpNoteConditionInput | null,
};

export type CreateOpNoteMutation = {
  createOpNote?:  {
    __typename: "OpNote",
    id: string,
    text: string,
    type?: string | null,
    tenantId?: string | null,
    owner?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedCptCodes?:  {
      __typename: "ModelOpNoteCptCodeApprovedConnection",
      items?:  Array< {
        __typename: "OpNoteCptCodeApproved",
        id: string,
        opNoteId: string,
        cptCodeCode: string,
        tenantId?: string | null,
        approvedBy?: string | null,
        approvedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOpNoteMutationVariables = {
  input?: UpdateOpNoteInput,
  condition?: ModelOpNoteConditionInput | null,
};

export type UpdateOpNoteMutation = {
  updateOpNote?:  {
    __typename: "OpNote",
    id: string,
    text: string,
    type?: string | null,
    tenantId?: string | null,
    owner?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedCptCodes?:  {
      __typename: "ModelOpNoteCptCodeApprovedConnection",
      items?:  Array< {
        __typename: "OpNoteCptCodeApproved",
        id: string,
        opNoteId: string,
        cptCodeCode: string,
        tenantId?: string | null,
        approvedBy?: string | null,
        approvedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOpNoteMutationVariables = {
  input?: DeleteOpNoteInput,
  condition?: ModelOpNoteConditionInput | null,
};

export type DeleteOpNoteMutation = {
  deleteOpNote?:  {
    __typename: "OpNote",
    id: string,
    text: string,
    type?: string | null,
    tenantId?: string | null,
    owner?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedCptCodes?:  {
      __typename: "ModelOpNoteCptCodeApprovedConnection",
      items?:  Array< {
        __typename: "OpNoteCptCodeApproved",
        id: string,
        opNoteId: string,
        cptCodeCode: string,
        tenantId?: string | null,
        approvedBy?: string | null,
        approvedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type MySecurityGroupsQuery = {
  mySecurityGroups: Array< string >,
};

export type MyTenantIdQuery = {
  myTenantId?: string | null,
};

export type ListApprovedSuggestedOpNoteCptCodesQueryVariables = {
  opNoteId?: string,
  cptSuggestionMode?: string | null,
  cptSuggestionSize?: number,
};

export type ListApprovedSuggestedOpNoteCptCodesQuery = {
  listApprovedSuggestedOpNoteCptCodes:  Array< {
    __typename: "OpNoteCptCodeConsolidated",
    cptCode:  {
      __typename: "CptCode",
      code: string,
      description?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    linkType: OpNoteCptCodeLinkType,
    approvedBy?: string | null,
    approvedAt?: string | null,
  } >,
};

export type ListCptSuggestionModesQuery = {
  listCptSuggestionModes: Array< string >,
};

export type SyncTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTenantsQuery = {
  syncTenants?:  {
    __typename: "ModelTenantConnection",
    items?:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      active: boolean,
      createdBy: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id?: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    active: boolean,
    createdBy: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items?:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      active: boolean,
      createdBy: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOpNotesQueryVariables = {
  filter?: ModelOpNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOpNotesQuery = {
  syncOpNotes?:  {
    __typename: "ModelOpNoteConnection",
    items?:  Array< {
      __typename: "OpNote",
      id: string,
      text: string,
      type?: string | null,
      tenantId?: string | null,
      owner?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      approvedCptCodes?:  {
        __typename: "ModelOpNoteCptCodeApprovedConnection",
        items?:  Array< {
          __typename: "OpNoteCptCodeApproved",
          id: string,
          opNoteId: string,
          cptCodeCode: string,
          tenantId?: string | null,
          approvedBy?: string | null,
          approvedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOpNoteQueryVariables = {
  id?: string,
};

export type GetOpNoteQuery = {
  getOpNote?:  {
    __typename: "OpNote",
    id: string,
    text: string,
    type?: string | null,
    tenantId?: string | null,
    owner?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    approvedCptCodes?:  {
      __typename: "ModelOpNoteCptCodeApprovedConnection",
      items?:  Array< {
        __typename: "OpNoteCptCodeApproved",
        id: string,
        opNoteId: string,
        cptCodeCode: string,
        tenantId?: string | null,
        approvedBy?: string | null,
        approvedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type SyncCptCodesQueryVariables = {
  filter?: ModelCptCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCptCodesQuery = {
  syncCptCodes?:  {
    __typename: "ModelCptCodeConnection",
    items?:  Array< {
      __typename: "CptCode",
      code: string,
      description?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCptCodeQueryVariables = {
  code?: string,
};

export type GetCptCodeQuery = {
  getCptCode?:  {
    __typename: "CptCode",
    code: string,
    description?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCptCodesQueryVariables = {
  code?: string | null,
  filter?: ModelCptCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCptCodesQuery = {
  listCptCodes?:  {
    __typename: "ModelCptCodeConnection",
    items?:  Array< {
      __typename: "CptCode",
      code: string,
      description?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOpNoteCptCodeApprovedsQueryVariables = {
  filter?: ModelOpNoteCptCodeApprovedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOpNoteCptCodeApprovedsQuery = {
  syncOpNoteCptCodeApproveds?:  {
    __typename: "ModelOpNoteCptCodeApprovedConnection",
    items?:  Array< {
      __typename: "OpNoteCptCodeApproved",
      id: string,
      opNoteId: string,
      cptCodeCode: string,
      tenantId?: string | null,
      approvedBy?: string | null,
      approvedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchOpNotesQueryVariables = {
  filter?: SearchableOpNoteFilterInput | null,
  sort?: SearchableOpNoteSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOpNotesQuery = {
  searchOpNotes?:  {
    __typename: "SearchableOpNoteConnection",
    items?:  Array< {
      __typename: "OpNote",
      id: string,
      text: string,
      type?: string | null,
      tenantId?: string | null,
      owner?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      approvedCptCodes?:  {
        __typename: "ModelOpNoteCptCodeApprovedConnection",
        items?:  Array< {
          __typename: "OpNoteCptCodeApproved",
          id: string,
          opNoteId: string,
          cptCodeCode: string,
          tenantId?: string | null,
          approvedBy?: string | null,
          approvedAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchCptCodesQueryVariables = {
  filter?: SearchableCptCodeFilterInput | null,
  sort?: SearchableCptCodeSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCptCodesQuery = {
  searchCptCodes?:  {
    __typename: "SearchableCptCodeConnection",
    items?:  Array< {
      __typename: "CptCode",
      code: string,
      description?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    active: boolean,
    createdBy: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    active: boolean,
    createdBy: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};
