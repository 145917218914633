import "@fontsource/plus-jakarta-sans";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "./header.css";
import mail from "../../image/mail.svg";
import facebook from "../../image/fb.svg";
import twitter from "../../image/twit.svg";
import instagram from "../../image/insta.svg";
import logo from "../../image/image.png";
import close from "../../image/close.svg";
import image from "../../image/logo02.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: 8,
    flexShrink: 0,
    justifyContent: "center",

    "& p": {
      margin: 0,
    },
  },
  list: {
    display: "flex",
    margin: "0 8px 0 0",
    padding: 0,

    "& li": {
      padding: 0,
      margin: "0 8px 0 0",
      listStyle: "none",
    },
  },
});

const Header = () => {
  const history = useHistory();
  const styles = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const { pathname } = useLocation();
  const isHome = pathname === "/";
  const isLogin = pathname === "/login";

  const onToogle = () => {
    setExpanded((prev) => !prev);
  };

  const onCloseHeader = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (!isLogin) {
      const onScroll = () => {
        const currentPosition = window.pageYOffset;
        if (currentPosition === 0) {
          setScrolled(false);
        } else if (currentPosition > 1) setScrolled(true);
      };

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [isLogin]);

  if (isLogin) return <></>;

  return (
    <>
      <Navbar
        className={`nav-navbar ${
          expanded ? "expanded-bg-header" : scrolled ? "bg-header" : ""
        } ${isHome ? "" : " about-navbar"}`}
        id="header"
        expand="lg"
        fixed="top"
        expanded={expanded}
        onSelect={onToogle}
        collapseOnSelect
      >
        <Container className="nav-container-main" style={{ padding: "10px" }}>
          {expanded && (
            <div className="close-icon">
              <img onClick={onToogle} src={close} alt="close-icon" />
            </div>
          )}
          <NavLink to="/">
            {isHome && !expanded ? (
              <img src={logo} alt="logo" />
            ) : (
              <>
                <img src={image} alt="logo" className="white-logo" />
                <img src={logo} alt="logo" className="color-logo" />
              </>
            )}
          </NavLink>
          <Navbar.Collapse id="navbarScroll">
            <Nav navbarScroll style={{ marginLeft: "auto" }}>
              {expanded && (
                <span className="d-flex" onClick={onCloseHeader}>
                  <NavLink
                    className={`nav-navbar-text ${
                      pathname === "/" ? "active-nav" : ""
                    }`}
                    to="/"
                  >
                    Home
                  </NavLink>
                </span>
              )}
              <span className="d-flex" onClick={onCloseHeader}>
                <NavLink
                  className={`nav-navbar-text ${
                    pathname === "/about" ? "active-nav" : ""
                  }`}
                  to="/about"
                >
                  About
                </NavLink>
              </span>
              <span className="d-flex" onClick={onCloseHeader}>
                <NavLink
                  className={`nav-navbar-text ${
                    pathname === "/solution" ? "active-nav" : ""
                  }`}
                  to="/solution"
                >
                  Solution
                </NavLink>{" "}
              </span>

              {/* <span className="d-flex" onClick={onCloseHeader}>
                <NavLink className={`nav-navbar-text`} to="#">
                  Pricing
                </NavLink>{" "}
              </span>*/}
              <span className="d-flex" onClick={onCloseHeader}>
                <NavLink
                  className={`nav-navbar-text ${
                    pathname === "/why-galeai" ? "active-nav" : ""
                  }`}
                  to="/why-galeai"
                >
                  Why Gale AI
                </NavLink>{" "}
              </span>
              <span className="d-flex" onClick={onCloseHeader}>
                <NavLink
                  className={`nav-navbar-text ${
                    pathname === "/blog" ? "active-nav" : ""
                  }`}
                  to="/blog"
                >
                  Blog
                </NavLink>{" "}
              </span>

              <span className="d-flex" onClick={onCloseHeader}>
                <NavLink
                  className={`nav-navbar-text ${
                    pathname === "/contact" ? "active-nav" : ""
                  }`}
                  to="/contact"
                >
                  Contact
                </NavLink>
              </span>

              {expanded && (
                <div>
                  <span className="d-flex" onClick={onCloseHeader}>
                    <NavLink
                      className={`nav-navbar-text ${
                        pathname === "/login" ? "active-nav" : ""
                      }`}
                      to="/login"
                    >
                      Log in
                    </NavLink>
                  </span>
                  <span style={{ display: "flex" }}>
                    <Row
                      // xs="auto"
                      style={{
                        padding: "1rem 1rem 1rem 0",
                        flexDirection: "row",
                      }}
                      className="rowCustom"
                    >
                      <Col className="column">
                        <FontAwesomeIcon
                          icon={faFacebookSquare as IconProp}
                          size="2x"
                          color="white"
                        />
                      </Col>
                      <Col className="column">
                        <FontAwesomeIcon
                          icon={faTwitter as IconProp}
                          size="2x"
                          color="white"
                        />
                      </Col>
                      <Col className="column">
                        <FontAwesomeIcon
                          icon={faInstagram as IconProp}
                          size="2x"
                          color="white"
                        />
                      </Col>
                      <Col className="column">
                        {/* <div>
                          <img src={mail} alt="fb" className="whiteColor" />
                        </div> */}
                        <FontAwesomeIcon
                          icon={faEnvelope as IconProp}
                          size="2x"
                          color="white"
                        />
                      </Col>
                    </Row>
                  </span>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>

          {!expanded && (
            <Navbar.Toggle onClick={onToogle} aria-controls="navbarScroll" />
          )}
          {!expanded && (
            <span className="buttonForm">
              <Form style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="outline-success"
                  className="btn-login"
                  onClick={() => history.push("/login")}
                  // to="/login"
                  // component={NavLink}
                >
                  <span className="btn-text-name">Log in</span>
                </Button>
                <Row
                  // xs="auto"
                  style={{
                    padding: "1rem 1rem 1rem 1rem",
                    flexDirection: "row",
                  }}
                  className="rowCustom"
                >
                  <Col className="column">
                    <div>
                      <img src={facebook} alt="fb" />
                    </div>
                  </Col>
                  <Col className="column">
                    <div>
                      <img src={twitter} alt="fb" />
                    </div>
                  </Col>
                  <Col className="column">
                    <div>
                      <img src={instagram} alt="fb" />
                    </div>
                  </Col>
                  <Col className="column">
                    <div>
                      <img src={mail} alt="fb" />
                    </div>
                  </Col>
                </Row>
              </Form>
            </span>
          )}
        </Container>
        {/* {!expanded && (
          <Row
            // xs="auto"
            style={{ padding: "1rem 1rem 1rem 1rem", flexDirection: "row" }}
            className="rowCustom"
          >
            <Col className="column">
              <div>
                <img src={facebook} alt="fb" />
              </div>
            </Col>
            <Col className="column">
              <div>
                <img src={twitter} alt="fb" />
              </div>
            </Col>
            <Col className="column">
              <div>
                <img src={instagram} alt="fb" />
              </div>
            </Col>
            <Col className="column">
              <div>
                <img src={mail} alt="fb" />
              </div>
            </Col>
          </Row>
        )} */}
      </Navbar>
    </>
  );
};

export default Header;
