import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, TextField, Grid, FormLabel } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import {actions, createCPTCodeRequestName} from "../../store/cptCodes";
import { isProcessingSelector } from '../../store/requests';
import { Breadcrumbs, BreadcrumbsItem } from '../../Components/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {pageViewGA} from "../../utils/googleAnalytics";

const regExp = /\S/;
const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/cptcodes',
    title: 'CptCodes',
  },
  {
    title: 'Enter new CptCode',
  },
];

export const NewCptCode: FC = () => {
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const { push } = useHistory();
  const isProcessing = useSelector(isProcessingSelector([createCPTCodeRequestName]));
  const dispatch = useDispatch();
  const isValidCode = useMemo(() => {
    return regExp.test(code);
  }, [code]);
  const isValidDescription = useMemo(() => {
    return regExp.test(description);
  }, [description]);

  const handleChangeCode = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCode(e.target.value);
    },
    [setCode]
  );
  const handleChangeDescription = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
    },
    [setDescription]
  );
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      dispatch(
        actions.create({code: code.trim(), description: description.trim()})
      );
    },
    [code, description, dispatch]
  );

  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);

  return (
    <Page>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify="space-between">
              <Breadcrumbs items={breadcrumbs} />
              <div>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => push('/cptcodes/upload')}
                  startIcon={<CloudUploadIcon />}
                  style={{ marginRight: 16 }}
                >
                  Upload
                </Button>
                <Button
                  disabled={isProcessing || !isValidCode || !isValidDescription}
                  type="submit"
                  color="default"
                  variant="contained"
                >
                  Submit
              </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Please enter CptCode code:</FormLabel>
              <TextField
                fullWidth
                multiline
                value={code}
                onChange={handleChangeCode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Please enter CptCode description:</FormLabel>
              <TextField
                fullWidth
                multiline
                value={description}
                onChange={handleChangeDescription}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Page>
  );
};
