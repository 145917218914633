import React, { Component } from 'react';
import { Button, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { Breadcrumbs } from '../../Components/Breadcrumbs';
import {customAxios} from "../../store/customAxios";

const breadcrumbs = [
    {
    title: 'Feedbacks',
    href: '/feedbacks',
  },
  {
    title: 'Enter new Feedback',
  },
];

export class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      text: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  onAddClick = async () => {
    const endpoint = `${process.env.REACT_APP_API_SERVER}/api/v1/feedbacks/`;
    let data;
    try{
      data = {
        'opnote_id': this.props.location.state.opnote_id,
        'subject': this.state.subject,
        'message': this.state.text
      };
    }
    catch (e) {
      data = {
        'opnote_id': null,
        'subject': this.state.subject,
        'message': this.state.text
      };
    }
    const requestOptions = {
      method: "post",
       headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      data: data
    };

    customAxios(endpoint, requestOptions)
      .then((response) => {
      });

    this.props.history.push('/feedbacks/confirm');
  };

  render() {
    return (
      <Page>
        <Grid item xs={12}>
          <form onSubmit={this.onAddClick}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Breadcrumbs items={breadcrumbs} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Choose the feedback subject:</InputLabel>
                <Select
                  fullWidth
                  multiline
                  name="subject"
                  value={this.subject}
                  onChange={this.onChange}
                >
                  <MenuItem value={"Technical issue"}>Technical issue</MenuItem>
                  <MenuItem value={"User feedback"}>User feedback</MenuItem>
                  <MenuItem value={"Code denial"}>Code denial</MenuItem>
                  <MenuItem value={"Suggest a feature"}>Suggest a feature</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Please write the text of the feedback:</FormLabel>
                <TextField
                  fullWidth
                  multiline
                  name="text"
                  value={this.text}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <Button
                  disabled={!this.state.text && !this.state.subject}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Page>
    );
  }
}