import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  AppBar,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Drawer,
  Button
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Route, Switch, NavLink, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/rootReducer";
import "./App.css";
import { isProcessingSelector } from "./store/requests";
import { actions, loadRoleRequestName } from "./store/user";
import { getHomeRoute, routes } from "./routes";
import { makeStyles } from "@material-ui/core/styles";
import { NotFound } from "./screens/NotFound";

// import logo from './assets/logo.png';
import { Footer } from "./layout/Footer";
import CookieConsent, {
  getCookieConsentValue,
  Cookies
} from "react-cookie-consent";
import { initGA } from "./utils/googleAnalytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "./assets/galeailogo.png";
import { forwardTo } from "./history";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
const useStyles = makeStyles(({ palette: { primary, grey } }) => ({
  drawer: {
    width: 200,
    textDecoration: "none",
    background: `linear-gradient( "175deg","#3c8ce7","#00eaff")`
  },
  logo: {
    display: "inline-flex",
    alignItems: "center",

    "& img": {
      marginRight: 8
    }
  },
  navLink: {
    alignItems: "center",
    textDecoration: "none",
    padding: "16px",
    background: "white",
    color: "#3F51B5"
  },
  activeLink: {
    textDecoration: "none",
    border: "none",
    color: "white",
    background: `linear-gradient( 175deg,#3c8ce7,#00eaff)`
  },
  arrow: {
    display: "flex",
    padding: "10px",
    alignItems: "center",
    borderBottom: "2px solid #3F51B5",
    justifyContent: " right",
    textAlign: "left"
  },
  appBar: {
    background: `linear-gradient( 175deg,#3c8ce7,#00eaff)`
  }
}));

const App = () => {
  const styles = useStyles();
  const { push } = useHistory();
  const user = useSelector((state: RootState) => state.user.data);
  const userRole = useSelector((state: RootState) => state.user.role);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navLinks = useMemo(() => {
    return routes.filter(
      (route) =>
        route.linkLabel &&
        (route?.roles?.some((role) => userRole.includes(role)) ?? true)
    );
  }, [userRole]);
  const isLoading = useSelector(isProcessingSelector([loadRoleRequestName]));

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  const handleAcceptCookie = () => {
    initGA("UA-209776356-1");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const handleMenu = useCallback(
    (e: any) => {
      setAnchorEl(e.target);
    },
    [setAnchorEl]
  );
  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen((value) => !value);
  }, [setIsDrawerOpen]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return !user ? (
    <div className='app-spinner'>
      <CircularProgress />
    </div>
  ) : (
    <div className='App'>
      <AppBar position='static' className={styles.appBar}>
        <Toolbar>
          {navLinks.length > 1 && (
            <IconButton
              onClick={toggleDrawer}
              edge='start'
              className='menuButton'
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          )}
          <Drawer
            classes={{
              paper: styles.drawer
            }}
            open={isDrawerOpen}
            onClose={toggleDrawer}
          >
            <span className={styles.arrow} onClick={toggleDrawer}>
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                size='2x'
                color='#3F51B5'
              />
            </span>

            {routes
              .filter(
                (route) =>
                  route.linkLabel &&
                  (route?.roles?.some((role) => userRole.includes(role)) ??
                    true)
              )
              .map((item, index) => (
                <NavLink
                  key={index}
                  to={item.path}
                  onClick={toggleDrawer}
                  className={styles.navLink}
                  activeClassName={styles.activeLink}
                >
                  {item.linkLabel}
                </NavLink>
              ))}
          </Drawer>
          <Typography variant='h6' color='inherit' className='title'>
            <NavLink to={getHomeRoute().path} className={styles.logo}>
              <img src={logo} alt='Bill-e-Note' />
            </NavLink>
          </Typography>
          <div className='accountButton'>
            <Typography className='username'>
              {user?.username[0].toUpperCase() + user?.username.substring(1)}
            </Typography>
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  push("/profile");
                }}
              >
                Profile
              </MenuItem>
              <Divider />
              <Button
                color='primary'
                variant='contained'
                style={{ margin: "8px 8px 0" }}
                onClick={async () => {
                  try {
                    dispatch(actions.signOut());
                  } catch (e) {}
                }}
              >
                Sign Out
              </Button>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Switch>
        <Redirect exact path='/' to={getHomeRoute().path} />
        {routes.reduce<ReactElement[]>((res, route, index) => {
          const content = <Route key={index} {...route} />;

          if (
            route?.roles?.length &&
            !userRole.some((role: any) => route?.roles?.includes(role))
          ) {
            return res;
          }

          return [...res, content];
        }, [])}
        <Route component={NotFound} />
      </Switch>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Footer />
    </div>
  );
};

export default App;
