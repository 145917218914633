import React, { useEffect, useState } from "react";
import "./blog.css";

import { Container, Col, Row, Button } from "react-bootstrap";
import blog1 from "../../image/blog1.png";
import blog2 from "../../image/blog2.png";
import blog3 from "../../image/blog3.png";
import blog4 from "../../image/blog4.png";
import blog5 from "../../image/blog5.png";
import blog6 from "../../image/blog6.png";
import Playlogo from "../../image/Playlogo.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Pagination from "../Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { blogData } from "../../utils/Blogs/BlogData";
// import { blog02 } from "../../utils/Blogs/BlogData";

const Blog = () => {
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [BlogData, setBlogData] = useState<any>([
    {
      id: "blog11",
      heading: "The Impact of AI in Healthcare",
      paragraph:
        "Let’s be honest: artificial intelligence is the future, especially when it comes to healthcare. As we journey deeper into the digitally-driven world before us, we can’t help but reflect on how far we’ve come and how the usage of automation has grown in a way to help companies succeed. This change can be directly attributed to the complex technology behind artificial intelligence and natural language processing creation and implementation. At GaleAI, we believe that the future of healthcare is in our hands",
      longParagraph1:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
      longParagraph1a:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      heading2: "Sed ut perspiciatis unde omnis iste natus",
      longParagraph2:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
      longParagraph2a:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      heading3: "Lorem ipsum dolor",
      longParagraph3:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
      longParagraph3a:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      image: blog1,
    },
  ]);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const history = useHistory();
  const contentPerPage = 6;
  const lastContentIndex = currentPage * contentPerPage;
  const firstContentIndex = lastContentIndex - contentPerPage;
  const ClickHandler = (id: string) => {
    history.push(`/blog/${id}`);
  };
  return (
    <>
      <Header />
      <Container>
        <Row className="blog-head-main-row">
          <Col className="blog-head-main">
            <div className="blog-text-title">Gale AI Insights</div>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="blog-text-second-title">
               Stay up-to-date on the latest industry trends, current news, new releases, and important information on the future of AI and NLP in medical coding and billing with us.{" "}
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <div className="d-md-flex align-items-stretch blog-container">
        <div className="blogMain">
          <div id="content" className="p-4 p-md-5 pt-5 blog-main">
            <Row className="blog-row">
              {blogData.length &&
                blogData
                  .slice(firstContentIndex, lastContentIndex)
                  ?.map((blog, index) => {
                    return (
                      <Col
                        xl={6}
                        xxl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mb-5 blogDataCol"
                        key={index}
                      >
                        <span
                          onClick={() => history.push(`/blog/${blog.blogId}`)}
                        >
                          {" "}
                          {blog.blogBody}
                        </span>
                      </Col>
                    );
                  })}
            </Row>

            {BlogData.length > 1 && (
              <Pagination
                postsPerPage={6}
                totalPosts={BlogData?.length}
                paginate={paginate}
                setBlogData={setBlogData}
                currentPage={currentPage}
                lastContentIndex={lastContentIndex}
                contentPerPage={contentPerPage}
              />
            )}
          </div>
          <nav id="sidebar" className="sidebar-container">
            {/* <div className="p-4 pt-5 pb-0 sidebar">
              <Col className="blog-featured-col">
                <div className="blog-featured">Featured</div>
                <div className="blog-featured-second">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
                <div className="blog-featured-second-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit sed
                  tempor
                </div>
              </Col>
            </div> */}

            <div className="part-contain">
              <Col md={{ span: 7, offset: 1 }}>
                <div className="forever-text">
                  Be a part of changing healthcare — forever
                </div>
                <div style={{ paddingTop: "2rem" }}>
                  <Button
                    variant="outline-success"
                    className="btn-btn-get-blog"
                  >
                    <span className="get-text-blog">Get Started</span>
                    &nbsp;&nbsp;It’s Free
                  </Button>
                </div>
                <div>
                  <img className="blog-play-img" alt="img" src={Playlogo} />
                </div>
              </Col>
            </div>
          </nav>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blog;
