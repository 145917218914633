import React, { FC, useEffect } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
} from '@material-ui/core';
import { TenantSelector } from '../../../TenantSelector';
import { isSuperUser, User } from '../../../../store/user';

export type Props = {
  disabled: boolean;
};

export const TenantField: FC<Props> = (props) => {
  const {
    setFieldValue,
    values: { groups },
  } = useFormikContext<User>();
  const { disabled } = props;
  // const isVisible = !isSuperUser(Groups);
  const isVisible = true;
    useEffect(() => {
    if (!isVisible) {
      setFieldValue('tenant', '');
    }
  }, [isVisible, setFieldValue]);

  if (!isVisible) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Field name="tenant">
        {(fieldProps: FieldProps) => {
          return (
            <FormControl fullWidth>
              <InputLabel>Tenant</InputLabel>
              <TenantSelector {...fieldProps.field} disabled={disabled} />
              {!disabled && fieldProps.meta.error && (
                <FormHelperText error>{fieldProps.meta.error}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      </Field>
    </Grid>
  );
};
