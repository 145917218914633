import * as ReactGA from "react-ga";

export const initGA = (id: string) => {
    ReactGA.initialize(id, {
        debug: true,
        gaOptions: {
            siteSpeedSampleRate: 100
        }
    });
};

export const pageViewGA = (page: string) => {
    ReactGA.pageview(page);
}

export const modalViewGA = (modal: string) => {
    ReactGA.pageview(modal);
}

export const eventGA = (categoryName: string, actionName: string, labelName: string) => {
    ReactGA.event({
        category: categoryName,
        action: actionName,
        label: labelName,
    });
}

export const timingGA = (categoryName: string, variableName: string, valueNum: number) => {
    ReactGA.timing({
        category: categoryName,
        variable: variableName,
        value: valueNum
    });
}

export const exceptionGA = (detailDescription: string) => {
    ReactGA.exception({ description: detailDescription});
};