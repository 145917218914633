import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateOpNoteMutationVariables,
  UpdateOpNoteInput,
  SearchableOpNoteFilterInput,
} from '../../API';
import { Sort } from '../../Components/Table';

export type EdNote = {
  id: string;
  text: string;
  filename: string | null;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
};

export type EdNotes = {
  listEdNotes: {
    count: number;
    next: string | null;
    previous: string | null;
    items: EdNote[] | null;
  } | null;
};

export type EdNotesState = {
  data: EdNotes;
  selectedEdNote: EdNote | null;
  cptCodeDescription?: string;
};

export type LoadRequestParams = {
  filter: SearchableOpNoteFilterInput | null;
  sort?: Sort;
} | null;

const initialState: EdNotesState = {
  data: {
    listEdNotes: null,
  },
  selectedEdNote: null,
};

const slice = createSlice({
  name: 'edNotes',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<string | undefined>) => state,
    loadMore: (state) => state,
    loaded: (state, action: PayloadAction<EdNotes>) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    loadedMore: (state, action: PayloadAction<EdNotes>) => {
      if (state.data && state.data.listEdNotes) {
        return {
          ...state,
          data: {
            listEdNotes: {
              ...state.data.listEdNotes,
              ...action.payload.listEdNotes,
              items: [
                ...(state.data?.listEdNotes?.items ?? []),
                ...(action.payload?.listEdNotes?.items ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    create: (state, action: PayloadAction<CreateOpNoteMutationVariables>) =>
      state,
    loadEdNote: (state, action: PayloadAction<string>) => state,
    loadedEdNote: (state, action: PayloadAction<EdNote | null>) => {
      return {
        ...state,
        selectedEdNote: action.payload,
      };
    },
    update: (state, action: PayloadAction<UpdateOpNoteInput>) => state,
    updated: (
      state,
      action: PayloadAction<{ data: EdNote; updater: string }>
    ) => ({
      ...state,
      selectedEdNote: {
        ...state.selectedEdNote,
        ...action.payload.data,
      },
    }),
    download: (state) => state,
    downloadEdNotes: (state, actions: PayloadAction<string[]>) => state,
    delete: (state) => state,
    deleteEdNotes: (state, actions: PayloadAction<string[]>) => {
      if (state.data && state.data.listEdNotes && state.data.listEdNotes.items) {
        return {
          ...state,
          data: {
            listEdNotes: {
              ...state.data.listEdNotes,
              items: [
                ...(state.data.listEdNotes?.items.filter(item => !actions.payload.includes(item.id)) ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    deleted: (state) => ({
      ...state,
      selectedEdNote: null,
    }),
    loadCptCodeDescription: (state, action: PayloadAction<string>) => ({
      ...state,
      cptCodeDescription: undefined,
    }),
    loadedCptCodeDescription: (state, action: PayloadAction<string>) => ({
      ...state,
      cptCodeDescription: action.payload,
    }),
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
