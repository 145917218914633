import React, {useEffect} from 'react';
import { Page } from '../../layout/Page';
import { Typography } from '@material-ui/core';
import {pageViewGA} from "../../utils/googleAnalytics";

export const NotFound = () => {
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);

  return (
    <Page>
      <Typography align="center" variant="h3">
        Not Found
      </Typography>
    </Page>
  );
};
