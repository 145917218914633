import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Roles } from "./roles";
import { customAxios } from "../customAxios";
import { forwardTo } from "../../history";
import { OpNote } from "../opNotes";

export type Group = {
  id: string;
  name: string;
};

export type UserState = {
  data: User | null;
  role: Roles[];
  tenantId: string;
  profile: User | null;
  groups: Group[] | null;
};

export type User = {
  id: string;
  last_login: string;
  is_superuser: boolean;
  first_name: string;
  last_name: string;
  date_joined: string;
  username: string;
  created_at: string;
  updated_at: string;
  phone_number: string;
  practice: string;
  password: string;
  specialty: string;
  is_active: boolean;
  email: string;
  is_staff: boolean;
  agreement: boolean;
  tenant: string | null;
  groups: number[];
  user_permissions: number[] | null;
  email_verified: boolean;
  phone_number_verified: boolean;
  listOpNotes?: OpNote[];
};

export const initialUser: User = {
  id: "",
  last_login: "",
  is_superuser: false,
  first_name: "",
  last_name: "",
  date_joined: "",
  username: "",
  created_at: "",
  updated_at: "",
  phone_number: "",
  practice: "",
  password: "",
  specialty: "",
  is_active: false,
  email: "",
  is_staff: false,
  agreement: false,
  tenant: null,
  groups: [],
  user_permissions: [],
  email_verified: false,
  phone_number_verified: true,
};

const initialState: UserState = {
  data: null,
  role: [],
  tenantId: "",
  profile: null,
  groups: null,
};

export const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => ({
      ...state,
      data: action.payload,
    }),
    load: (state) => state,
    loadProfile: (state, action: PayloadAction<string | null>) => state,
    loadedProfile: (state, action: PayloadAction<User>) => ({
      ...state,
      profile: action.payload,
    }),
    updateProfile: (state, action: PayloadAction<User>) => state,
    updatedProfile: (state, action: PayloadAction<User>) => ({
      ...state,
      profile: action.payload,
    }),
    loadRole: (state) => state,
    loadedRole: (state, action: PayloadAction<Roles[]>) => ({
      ...state,
      role: action.payload,
    }),
    loadGroups: (state) => state,
    loadedGroups: (state, action: PayloadAction<Group[]>) => ({
      ...state,
      groups: action.payload,
    }),
    loadTenantId: (state) => state,
    loadedTenantId: (state, action: PayloadAction<string>) => ({
      ...state,
      tenantId: action.payload,
    }),
    create: (
      state,
      action: PayloadAction<User & { isTenantAdmin?: boolean }>
    ) => state,
    created: (state) => state,
    agreement: (state) => state,
    signOut: () => {
      customAxios
        .post(
          "auth/logout/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then(() => {
          localStorage.removeItem("idToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expiresOn");
          localStorage.removeItem("userId")
          forwardTo("/");
          window.location.reload();
        });
    },
  },
});

export const { actions, reducer } = slice;
