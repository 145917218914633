import React, { useEffect } from "react";
import "@fontsource/plus-jakarta-sans";
import { Button, Container, Col, Row } from "react-bootstrap";

import "./index.css";
import image from "../../image/01.png";
import image2 from "../../image/02.png";
import group1 from "../../image/group1.svg";
import group2 from "../../image/group2.svg";
import group3 from "../../image/group3.svg";

// import Vector01 from "../../image/Vector01.png";
// import Vector02 from "../../image/Vector02.png";
// import home from "../../image/home.png";
import ThoughtSlider from "../ThoughtSlider";
import { useHistory } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";

const Home = () => {
  const user = useSelector((state) => state);
  const history = useHistory();
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      history.push("/opnotes/new");
    }
  }, []);
  return (
    <>
      <Header />
      <Container fluid className="banner">
        <Container className="container-main">
          <Row>
            <Col className="main-text-col">
              <Row>
                <Col md={{ span: 8, offset: 0 }}>
                  <div className="main-text">
                    Artificially Intelligent <br />
                    Medical Coding
                  </div>
                </Col>
              </Row>
              <div className="mian-second-text">
                <p>
                  With artificially intelligent medical coding at your
                  fingertips
                  <br />
                  you can automate billing and coding.
                </p>
              </div>
              <div className="header-btn">
                <Button
                  variant="outline-success"
                  className="btn-btn-get-home"
                  onClick={() => history.push("/contact")}
                  // to="/contact"
                  // as={NavLink}
                >
                  &nbsp;Get&nbsp; started&nbsp; for&nbsp; free&nbsp; now!&nbsp;
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Three Parts */}
      <Container className="container-main">
        <Row className="groupRow">
          <Col className="group-col" md={4}>
            <div className="group-div">
              <div className="img-set">
                <img src={group1} alt="group1" />
              </div>
              <div className="group-text improve">Improves reimbursement</div>
              <Col>
                <div className="group-second-text">
                  Remove the administrative burdens that cost health systems
                  millions in revenue.
                </div>
              </Col>
            </div>
          </Col>

          <Col className="group-col" md={4}>
            <div className="group-div">
              <div className="img-set">
                <img src={group2} alt="group2" />
              </div>
              <div className="group-text decrease">Decreases coding time</div>
              <Col>
                <div className="group-second-text">
                  Eliminate the uncertainty and delays in receiving payments
                  from patients and payers.
                </div>
              </Col>
            </div>
          </Col>

          <Col className="group-col" md={4}>
            <div className="group-div moreTime">
              <div className="img-set">
                <img src={group3} alt="group3" />
              </div>
              <Col>
                <div className="group-text">Improve your work-life balance</div>
              </Col>
              <Col>
                <div className="group-second-text">
                  Proactively address issues and guide care with real-time value
                  calculations that detail top-line growth and financial
                  insight.
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="container-main">
        <Row className="authorization-row1">
          <Col md={5} className="new-dot-home-col">
            <div className="new-dot-home"></div>
            <div className="contain-text">AI-powered prior authorizations</div>
            <Col>
              <div className="contain-text-second">
                Assess and visually map workflows based on speed and impact to
                prioritize your automation roadmap and project its financial
                value to your organization.
              </div>
            </Col>
          </Col>

          <Col md={7}>
            <div className="authorization-img">
              <img src={image} alt="img" />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="container-main">
        <Row className="authorization-row2">
          <Col md={6}>
            <div className="authorization-img">
              <img src={image2} alt="img" />
            </div>
          </Col>
          <Col md={6}>
            <Col md={{ span: 10, offset: 2 }} className="authorization-text">
              <div className="new-dot-home"></div>
              <div className="contain-text">
                Utilize AI & NLP to solve this problem
              </div>
            </Col>
            <Col md={{ span: 10, offset: 2 }} className="authorization-text">
              <div className="contain-text-second">
                Point-of-care approvals mean faster care for your members and
                industry-breaking turnaround times to dominate your markets.
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <Container fluid className="Thoughts-back-img container-main">
        <Container>
          <Row>
            <Col style={{ padding: "66px 0 0 0" }}>
              <div className="thought-text">Thoughts on GaleAI</div>
              <ThoughtSlider />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="changing-back container-main">
        <Container className="get-started">
          <Row>
            <Col md={7} className="getStartedDiv">
              <Col className="getStartedCol">
                <div className="forever-text-home">
                  The future of healthcare in your hands- join the movement.
                </div>
              </Col>
              <div className="get-started-btn">
                <Button
                  variant="outline-success"
                  className="btn-btn-get-home-bottom"
                  onClick={() => history.push("/contact")}
                  // to="/contact"
                  // as={NavLink}
                >
                  <span className="get-text">
                    &nbsp;Get&nbsp; started&nbsp; for&nbsp; free&nbsp;
                    now!&nbsp;
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
