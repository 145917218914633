import React, {FC, memo, useCallback, useEffect, useMemo, useState,} from 'react';
import {Button, Chip, Dialog, DialogActions, DialogContent, FormLabel, Grid, TextField,} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../store/rootReducer';
import {isProcessingSelector} from '../../../../store/requests';
import {actions, assignCPTCodeRequestName, CPTCode, loadCPTCodesRequestName,} from '../../../../store/cptCodes';
import {makeStyles} from '@material-ui/core/styles';
import usePrevious from '../../../../hooks/usePrevious';
import {OpNoteCptCodeLinkType} from '../../../../API';
import useUserRoleChecker from "../../../../hooks/useUserRoleChecker";
import {Roles} from "../../../../store/user";

export type Props = {
  open: boolean;
  opNoteId: string;
  onClose: () => void;
  assignedCodes: CPTCode[];
};

type Option = {
  value: string;
  label: string;
  meta: string;
  data: CPTCode;
};

const useStyles = makeStyles({
  root: {
    width: 400,
  },
});

export const AddCPTCodeDialog: FC<Props> = memo((props) => {
  const {open, onClose, opNoteId, assignedCodes} = props;
  const dispatch = useDispatch();
  const styles = useStyles();
  const isCoder = useUserRoleChecker([Roles.Coder]);
  const isProcessing = useSelector(
    isProcessingSelector([assignCPTCodeRequestName])
  );
  const previousIsProcessing = usePrevious(isProcessing);
  const disabledCodes = useMemo(() => {
    return new Set([
      ...assignedCodes
        .filter((code) => code.linkType === OpNoteCptCodeLinkType.APPROVED)
        .map((code) => code.code),
    ]);
  }, [assignedCodes]);
  const [selectedCPTCode, setSelectedCPTCode] = useState<Option[] | null>(null);
  const options = useSelector((state: RootState) =>
    state.cptCodes.searchedCodes.map((item) => ({
      value: item.code,
      label: item.code,
      meta: item.description,
      data: item,
    }))
  );
  const isLoading = useSelector(
    isProcessingSelector([loadCPTCodesRequestName])
  );

  const clear = useCallback(() => {
    dispatch(actions.search(''));
  }, [dispatch]);
  const handleClose = useCallback(() => {
    onClose();
    clear();
  }, [onClose, clear]);

  useEffect(() => {
    if (!isProcessing && previousIsProcessing) {
      onClose();
    }
  }, [isProcessing, previousIsProcessing, onClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className={styles.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>CPT Code</FormLabel>
            <Autocomplete
              multiple
              options={options}
              loading={isLoading}
              onChange={useCallback((_, option) => {
                setSelectedCPTCode(option);
              }, [])}
              getOptionSelected={useCallback((option, selectedOption) => {
                return option.value === selectedOption.value;
              }, [])}
              filterOptions={useCallback((options) => options, [])}
              getOptionLabel={useCallback((option) => option.label, [])}
              renderOption={useCallback(
                (option) => (
                  <span title={option.meta}>{option.label}</span>
                ),
                []
              )}
              getOptionDisabled={useCallback(
                (option) => {
                  return disabledCodes.has(option.value);
                },
                [disabledCodes]
              )}
              renderTags={useCallback((value, getTagProps) => {
                return value.map((item: Option, index: number) => {
                  return (
                    <Chip
                      title={item.meta}
                      {...getTagProps(index)}
                      key={item.value}
                      label={item.label}
                    />
                  );
                });
              }, [])}
              onInputChange={useCallback(
                (e) => {
                  dispatch(actions.search(e.target.value));
                },
                [dispatch]
              )}
              renderInput={useMemo(
                () => (renderProps) => <TextField autoFocus {...renderProps} />,
                []
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isProcessing || !selectedCPTCode}
          color="primary"
          variant="contained"
          onClick={useCallback(() => {
            if (selectedCPTCode) {
              if (isCoder) {
                dispatch(
                  actions.code({
                    cptCodes: selectedCPTCode.map((item) => item.data),
                    opNoteId,
                  })
                );
              } else {
                dispatch(
                  actions.assign({
                    cptCodes: selectedCPTCode.map((item) => item.data),
                    opNoteId,
                  })
                );
              }
            }
          }, [dispatch, selectedCPTCode, opNoteId])}
        >
          Submit
        </Button>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});
