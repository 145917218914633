import React, { FC, useState } from 'react';
import { FormHelperText, Grid, TextField } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import {
  BackToSignInLink,
  useBackToSignInNav,
} from '../../../BackToSignInLink';
import { LoadingButton } from '../../../../../../Components/LoadingButton';
import {customAxios} from "../../../../../../store/customAxios";

export type Props = {
  username: string;
};

export const NewPassword: FC<Props> = (props) => {
  const { username } = props;
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const backToSignIn = useBackToSignInNav();

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={code}
          label="Verification code"
          placeholder="Enter code"
          onChange={(e) => setCode(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="password"
          value={password}
          label="New password"
          placeholder="Enter your new password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText error>{error}</FormHelperText>
      </Grid>
      <Grid container justify="space-between" item xs={12}>
        <BackToSignInLink />
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          disabled={!username || !password}
          onClick={async () => {
            setLoading(true);
            try {
              const userData = new FormData();
              userData.append('username', username);
              userData.append('confirmation_code', code);
              userData.append('password', password);
              await customAxios.post('/auth/reset-password/', userData);
              backToSignIn();
            } catch (e) {
              // setError(e.message);
            }
            setLoading(false);
          }}
        >
          Submit
        </LoadingButton>
      </Grid>
    </>
  );
};
