import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type Feedback = {
  id: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  subject: string;
  status: string;
  message: string;
  op_note_id?: string | null;
};

export type Feedbacks = {
  listFeedbacks: {
    count?: number | null;
    next?: string | null;
    previous?: string | null;
    items: Feedback[];
  };
};

export type FeedbacksState = {
  data: Feedbacks;
  selectedFeedback: Feedback | null;
};

const initialState: FeedbacksState = {
  data: {
    listFeedbacks: {
      items: [],
    },
  },
  selectedFeedback: null,
};

const slice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
    }),
    loaded(state, action: PayloadAction<Feedbacks>) {
      return {
        ...state,
        data: action.payload,
      };
    },
    loadMore: (state, action: PayloadAction<string | undefined>) => state,
    loadedMore: (state, action: PayloadAction<Feedbacks>) => {
      if (state.data && state.data.listFeedbacks) {
        return {
          ...state,
          data: {
            listFeedbacks: {
              ...state.data.listFeedbacks,
              ...action.payload.listFeedbacks,
              items: [
                ...(state.data?.listFeedbacks?.items ?? []),
                ...(action.payload?.listFeedbacks?.items ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    loadFeedback: (state, action: PayloadAction<string>) => state,
    loadedFeedback: (state, action: PayloadAction<Feedback>) => ({
      ...state,
      selectedFeedback: action.payload,
    }),
    create: (state, action: PayloadAction<string>) => state,
    deleteFeedback: (state, action: PayloadAction<string>) => state,
    deleteFeedbacks: (state, action: PayloadAction<string[]>) => {
      if (state.data && state.data.listFeedbacks && state.data.listFeedbacks.items) {
        return {
          ...state,
          data: {
            listFeedbacks: {
              ...state.data.listFeedbacks,
              items: [
                ...(state.data.listFeedbacks?.items.filter(item => !action.payload.includes(item.id)) ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    doneFeedbacks: (state, action: PayloadAction<string[]>) => {
      if (state.data && state.data.listFeedbacks && state.data.listFeedbacks.items) {
        return {
          ...state,
          data: {
            listFeedbacks: {
              ...state.data.listFeedbacks,
              items: [
                ...(state.data.listFeedbacks?.items.map(i => i.status === 'Pending' ? Object.assign({}, i, {status: 'Done'}): i) ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;