import {Feedback} from "./feedbacks.slice";
import {actions} from "../feedbacks";
import requestSaga, {Request} from "../requestSaga";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {RootState} from "../rootReducer";
import {eventGA} from "../../utils/googleAnalytics";

const limit = 20;


type FeedbacksResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Feedback[];
  };
} | void;

const formatFeedbacks = (res: FeedbacksResponse) => {
  if (!res) {
    return {listFeedbacks: null};
  }

  return {
    listFeedbacks: {
      ...res.data.results,
      items: res.data.results.map((item) => ({
        ...item,
      })),
      count: res.data.count,
      next: res.data.next,
      previous: res.data.previous,
    },
  };
};

export const loadFeedbacksRequestName = 'loadFeedbacks';

function* loadFeedbacksSaga(action: ReturnType<typeof actions.load>) {
  const res: Request<FeedbacksResponse> = yield call(requestSaga, {
      name: loadFeedbacksRequestName,
      errorNotification: {
        message: 'Invalid token.',
      },
      drf: {
        method: 'get',
        url: action.payload ? `/api/v1/feedbacks/?${action.payload}` : '/api/v1/feedbacks/',
        perPage: limit,
      },
    },
  );

  if (res) {
    // @ts-ignore
    yield put(actions.loaded(formatFeedbacks(res)));
    yield call(eventGA, 'Feedbacks', `Load ${limit} Feedbacks`, 'Table')
  }
}

export const loadMoreFeedbacksRequestName = 'loadMoreFeedbacks';

function* loadMoreFeedbacksSaga(action: ReturnType<typeof actions.loadMore>) {
  // @ts-ignore
  const nextUrl = yield select(
    (state: RootState) => state.feedbacks.data.listFeedbacks?.next ?? null
  );
  const res: Request<FeedbacksResponse> = yield call(requestSaga, {
      name: loadMoreFeedbacksRequestName,
      errorNotification: {
        message: 'Invalid token.',
      },
      drf: {
        method: 'get',
        url: nextUrl,
      },

    },
  );
  if (res) {
    // @ts-ignore
    yield put(actions.loaded(formatFeedbacks(res)));
    yield call(eventGA, 'Feedbacks', `Load ${limit} Feedbacks`, 'Table')
  }
}


export const deleteFeedbacksRequestName = 'deleteFeedbacks';

function* deleteFeedbacksSaga(action: ReturnType<typeof actions.deleteFeedbacks>) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {
        drf: {
          method: 'post',
          url: '/api/v1/feedbacks/delete/',
          data: {
            'feedbacks': action.payload,
          },
        },
        name: deleteFeedbacksRequestName,
        successNotification: {
          message: `${action.payload.length} feedbacks has been deleted`,
        },
      },
    );
    if (response){
          yield call(eventGA, 'Feedbacks', `Delete ${action.payload.length} Feedbacks`, 'Button')
    }
  }
}

export const doneFeedbacksRequestName = 'doneFeedbacks';

function* doneFeedbacksSaga(action: ReturnType<typeof actions.doneFeedbacks>) {
  if (action.payload) {
    yield call(requestSaga, {
        drf: {
          method: 'post',
          url: '/api/v1/feedbacks/done/',
          data: {
            'feedbacks': action.payload,
          },
        },
        name: doneFeedbacksRequestName,
        successNotification: {
          message: `${action.payload.length} feedbacks has been doned`,
        },
      },
    );
  }
}

export const saga = function* () {
  yield takeLatest(actions.load, loadFeedbacksSaga);
  yield takeLatest(actions.loadMore, loadMoreFeedbacksSaga);
  yield takeLatest(actions.deleteFeedbacks, deleteFeedbacksSaga);
  yield takeLatest(actions.doneFeedbacks, doneFeedbacksSaga);
  // yield takeLatest(actions.toggle, toggleTenantSaga);
  // yield takeLatest(actions.create, createTenantSaga);
  // yield takeLatest(actions.update, updateTenantSaga);
  // yield takeLatest(actions.deleteTenant, deleteTenantSaga);
  // yield takeLatest(actions.loadTenant, loadTenantSaga);
  // yield takeLatest(actions.loadUserFeedbacks, loadUserProfileFeedbacksSaga);
};
