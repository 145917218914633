import React, {FC, useEffect} from 'react';
import { Page } from '../../layout/Page';
import { createUserRequestName, initialUser, Roles, User } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../store/requests';
import { actions } from '../../store/user';
import { Breadcrumbs, BreadcrumbsItem } from '../../Components/Breadcrumbs';
import { UserProfileForm } from '../../Components/UserProfileForm';
import { Grid } from '@material-ui/core';
import {eventGA, pageViewGA} from "../../utils/googleAnalytics";

const breadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'Users',
    href: '/users',
  },
  {
    title: 'Enter new User',
  },
];

export const CreateUser: FC = () => {
  const dispatch = useDispatch();
  const data: User = initialUser;
  const isSaving = useSelector(isProcessingSelector([createUserRequestName]));

  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <UserProfileForm
        initialValues={data}
        mode="create"
        disabled={isSaving}
        onSubmit={(values) => {
          dispatch(actions.create(values));
        }}
      />
    </Page>
  );
};
