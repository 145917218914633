import React, { FC, useState } from 'react';
import { FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { User } from '../../../../store/user';
import { BackToSignInLink } from '../BackToSignInLink';
import { LoadingButton } from '../../../../Components/LoadingButton';
import {customAxios} from "../../../../store/customAxios";

export type Props = {
  user: User | null;
  onSuccess: () => void;
};

export const ChangePassword: FC<Props> = (props) => {
  const { user, onSuccess } = props;
  const [error, setError] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Change Password</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="password"
          value={oldPassword}
          label="Old password"
          placeholder="Enter your old password"
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="password"
          value={password}
          label="New password"
          placeholder="Enter your new password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText error>{error}</FormHelperText>
      </Grid>
      <Grid container justify="space-between" item xs={12}>
        <BackToSignInLink />
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          disabled={!oldPassword || !password}
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
               try {
                    const userData = new FormData();
                    userData.append('old_password', oldPassword);
                    userData.append('new_password', password);
                    await customAxios.post('/auth/change-password/', userData, {  headers: {
                      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    },});
                    onSuccess();
                } catch (e) {
                  // setError(e?.message);
                }

              // if (user) {
              //   await Auth.completeNewPassword(user, password, {
              //     name: oldPassword,
              //   });
              //   onSuccess();
              // }
            } catch (e) {
              // setError(e.message);
            }
            setLoading(false);
          }}
        >
          Change
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
