import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  setBlogData,
  currentPage,
  lastContentIndex,
  contentPerPage
}: any) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.map((number) => {
          return (
            <li
              key={number}
              className={number === currentPage ? "active-page" : "page-item"}
            >
              <a
                onClick={() => {
                  paginate(number);
                }}
                className='page-link'
              >
                {number}
              </a>
            </li>
          );
        })}
        <span
          onClick={() => paginate(Math.ceil(totalPosts / postsPerPage))}
          className='RightArrow'
        >
          <FontAwesomeIcon
            icon={faLongArrowAltRight as IconProp}
            color='#26bbff'
            size='2x'
          />
        </span>
      </ul>
    </nav>
  );
};

export default Pagination;
