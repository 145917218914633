import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./custom-slider.css";
import { Col } from "react-bootstrap";

import Vector01 from "../../image/Vector01.png";
import Vector02 from "../../image/Vector02.png";

const ThoughtSlider = () => {
  const settings = {
    dots: true,
    className: "slider variable-width",
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const data = [
    {
      title:
        "“Gale AI has been amazing in cutting down on the time spent coding”",
      name: "Matt Koepplinger,DO Orthopedic Hand Surgeon Houston, TX",
    },
    {
      title: "“This is incredible!”",
      name: "Edward Birdsong, MD Orthopedic Surgeon Pittsburgh, PA",
    },
  ];

  return (
    <Slider {...settings}>
      {data.map(({ title, name }, index) => (
        <span key={index}>
          <Col style={{ position: "relative" }} md={{ span: 8, offset: 2 }}>
            <div className="vector-img-main">
              <img src={Vector01} className="vector-img1" alt="vector img" />
            </div>
            <div className="practice-text">{title}</div>
            <div className="vector-img-main">
              <img src={Vector02} className="vector-img2" alt="vector img" />
            </div>
          </Col>
          <div className="General-text sliderText">— {name}</div>
        </span>
      ))}
    </Slider>
  );
};
export default ThoughtSlider;
