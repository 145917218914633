import { ADMINS, Roles, SUPERS } from "./store/user";
import { ComponentType } from "react";
import { OpNotes } from "./screens/OpNotes";
import { ViewOpNote } from "./screens/ViewOpNote";
import { NewOpNote } from "./screens/NewOpNote";
import { Users } from "./screens/Users";
import { UserProfile } from "./screens/UserProfile";
import { CreateUser } from "./screens/CreateUser";
import { MyProfile } from "./screens/MyProfile";
import { Tenants } from "./screens/Tenants";
import { NewTenant } from "./screens/NewTenant";
import { EditTenant } from "./screens/EditTenant";
// @ts-ignore
import { Feedback } from "./screens/NewFeedback/Feedback";
import { FeedbackConfirmationPage } from "./screens/NewFeedback/FeedbackConfirmationPage";
import { UploadOpNote } from "./screens/UploadOpNote";
import { Feedbacks } from "./screens/Feedbacks";
import { CptCodes } from "./screens/CptCodes";
import { NewCptCode } from "./screens/NewCptCode";
import { UploadCptCodes } from "./screens/UploadCptCodes";
import { Metrics } from "./screens/Metrics";
import { Models } from "./screens/Models";
import { Finances } from "./screens/Finances";
import { CRM } from "./screens/CRM/CRM";
import { EdNotes } from "./screens/EdNotes";
import { NewEdNote } from "./screens/NewEdNote";
import { UploadEdNote } from "./screens/UploadEdNote/UploadEdNote";
import { ViewEdNote } from "./screens/ViewEdNote/ViewEdNote";
import Home from "./Components/Home";
import About from "./Components/About";

export type Route = {
  path: string;
  roles?: Roles[];
  exact: boolean;
  linkLabel?: string;
  component: ComponentType;
};

export const routes: Route[] = [
  {
    path: "/opnotes/new",
    exact: true,
    component: NewOpNote,
  },
  {
    path: "/opnotes",
    exact: true,
    linkLabel: "OpNotes",
    component: OpNotes,
  },
  {
    path: "/opnotes/upload",
    exact: true,
    component: UploadOpNote,
  },
  {
    path: "/opnotes/:id",
    exact: true,
    component: ViewOpNote,
  },
  {
    path: "/ednotes",
    exact: true,
    linkLabel: "EdNotes",
    component: EdNotes,
  },
  {
    path: "/ednotes/new",
    exact: true,
    component: NewEdNote,
  },
  {
    path: "/ednotes/upload",
    exact: true,
    component: UploadEdNote,
  },
  {
    path: "/ednotes/:id",
    exact: true,
    component: ViewEdNote,
  },
  {
    path: "/cptcodes",
    exact: true,
    linkLabel: "CptCodes",
    component: CptCodes,
    roles: SUPERS,
  },
  {
    path: "/cptcodes/new",
    exact: true,
    component: NewCptCode,
    roles: SUPERS,
  },
  {
    path: "/cptcodes/upload",
    exact: true,
    component: UploadCptCodes,
    roles: SUPERS,
  },
  {
    path: "/profile",
    exact: true,
    component: MyProfile,
  },
  {
    path: "/users",
    exact: true,
    linkLabel: "Users",
    roles: ADMINS,
    component: Users,
  },
  {
    path: "/users/new",
    exact: true,
    roles: ADMINS,
    component: CreateUser,
  },
  {
    path: "/users/:id",
    exact: true,
    roles: ADMINS,
    component: UserProfile,
  },
  {
    path: "/tenants",
    exact: true,
    component: Tenants,
    linkLabel: "Tenants",
    roles: [Roles.SuperAdmin],
  },
  {
    path: "/tenants/new",
    exact: true,
    component: NewTenant,
    roles: [Roles.SuperAdmin],
  },
  {
    path: "/tenants/:id",
    exact: true,
    component: EditTenant,
    roles: [Roles.SuperAdmin],
  },
  {
    path: "/feedbacks",
    exact: true,
    linkLabel: "Feedback",
    component: Feedbacks,
  },
  {
    path: "/feedbacks/new",
    exact: true,
    component: Feedback,
  },
  {
    path: "/feedbacks/confirm",
    exact: true,
    component: FeedbackConfirmationPage,
  },
  {
    path: "/finances",
    exact: true,
    linkLabel: "Finances",
    component: Finances,
    roles: ADMINS,
  },
  {
    path: "/metrics",
    exact: true,
    linkLabel: "Metrics",
    component: Metrics,
    roles: ADMINS,
  },
  {
    path: "/crm",
    exact: true,
    linkLabel: "CRM",
    component: CRM,
    roles: ADMINS,
  },
  {
    path: "/",
    exact: true,
    // linkLabel: 'Home',
    component: Home,
    // roles: ADMINS,
  },
  {
    path: "/models",
    exact: true,
    linkLabel: "Models",
    component: Models,
    roles: ADMINS,
  },
];

export const getHomeRoute = () => routes[0];
