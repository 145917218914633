import React, { FC, memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OpNote } from '../../../../store/opNotes';
import formatDate from '../../../../utils/formatDate';
import { Table } from '../../../../Components/Table';
import { CPTCode } from '../../../../store/cptCodes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { Roles } from '../../../../store/user';

export type Props = {
  open: boolean;
  onClose: () => void;
  data: OpNote;
  deletable: boolean;
  onDelete: () => void;
  codes: CPTCode[];
};

const useStyles = makeStyles({
  root: {
    width: 560,
  },
});

export const OpNoteDetailsDialog: FC<Props> = memo((props) => {
  const { open, onClose, data, codes, deletable, onDelete } = props;
  const isDataEntry = useSelector(
    (state: RootState) => state.user.role
  ).includes(Roles.DataEntry);
  const styles = useStyles();

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick>
      <DialogContent>
        <Table>
          <colgroup>
            <col width={160} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell padding="none">OpNote ID</TableCell>
              <TableCell padding="none">{data.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none">Created</TableCell>
              <TableCell padding="none">
                {formatDate(data.created_at)}{' '}
                {!isDataEntry && <>by {data.created_by}</>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none">Updated</TableCell>
              <TableCell padding="none">
                {formatDate(data.updated_at)}{' '}
                {data.updated_by&& <>by {data.updated_by}</>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none">CPT codes approved</TableCell>
              <TableCell padding="none">
                {Object.keys(
                  codes.reduce<{
                    [key: string]: string;
                  }>((res, item) => {
                    if (item.approvedBy) {
                      return {
                        ...res,
                        [item.approvedBy]: item.approvedBy,
                      };
                    }

                    return res;
                  }, {}) ?? {}
                ).join(', ')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions className={styles.root}>
          <Button color="secondary" variant="contained" onClick={onDelete}>
            Delete
          </Button>
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});
