import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Check } from "@material-ui/icons";
import { Page } from "../../layout/Page";
import { Sort, TableFilters } from "../../Components/Table";
import { Button, Checkbox, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import {
  actions,
  loadTenantsRequestName,
  loadMoreTenantsRequestName,
  Tenant,
  actions as tenantsActions,
} from "../../store/tenants";
import { isProcessingSelector } from "../../store/requests";
import { FormikValues } from "formik";
import { ModelTenantFilterInput, SearchableSortDirection } from "../../API";
import formatDate from "../../utils/formatDate";
import { makeStyles } from "@material-ui/core/styles";
import { forwardTo } from "../../history";
import CreateIcon from "@material-ui/icons/Create";
import { buttonsUseStyles } from "../../layout/Styles/Buttons";
import { DeleteTenants } from "./components/DeleteTenants";
import { BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { pageViewGA } from "../../utils/googleAnalytics";

const initialSort = {
  field: "created_at",
  dir: SearchableSortDirection.desc,
};

const getFilter = (values: FormikValues, sort: Sort | undefined) => {
  const sortStringValue = `${
    sort ? `ordering=${sort.dir === "desc" ? "-" : ""}${sort.field}` : ""
  }`;
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : "";
  return value ? `${fieldName}=${value}&${sortStringValue}` : { sort: sort };
};

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",

    "& button": {
      marginLeft: 8,
    },
  },
});

export const Tenants = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const isLoading = useSelector(isProcessingSelector([loadTenantsRequestName]));
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreTenantsRequestName])
  );
  const storeItems = useSelector(
    (state: RootState) => state.tenants.data.listTenants?.items ?? []
  );
  const storeCount = useSelector(
    (state: RootState) => state.tenants.data.listTenants?.count ?? 0
  );
  const hasMore = useSelector(
    (state: RootState) => !!state.tenants.data.listTenants?.next ?? false
  );
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const allSelectableIds = useMemo(() => {
    return storeItems.reduce<string[]>((res, User) => {
      return [...res, User.id];
    }, []);
  }, [storeItems]);
  const handleSelectAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(new Set(e.target.checked ? allSelectableIds : []));
    },
    [setSelected, allSelectableIds]
  );
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: "Tenants",
      href: "/tenants",
    },
    {
      title: `${storeCount} items`,
    },
  ];
  const handleAdd = useCallback(() => {
    push("/tenants/new");
  }, [push]);

  const localStorageRows = localStorage.getItem("pageRows");

  useEffect(() => {
    dispatch(tenantsActions.load({}));
    pageViewGA(window.location.pathname + window.location.search);
  }, [dispatch]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          singleColumnFilter
          initialSort={initialSort}
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          breadcrumbs={breadcrumbs}
          columns={[
            {
              label: (
                <Checkbox
                  color="primary"
                  checked={selected.size === allSelectableIds.length}
                  onChange={handleSelectAllChange}
                />
              ),
              name: "",
              render: (data: Tenant) => (
                <Checkbox
                  checked={selected.has(data.id)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      selected.add(data.id);
                    } else {
                      selected.delete(data.id);
                    }
                    setSelected(new Set(selected));
                  }}
                  color="primary"
                />
              ),
            },
            {
              name: "id",
              label: "ID",
              filter: {
                name: "id",
              },
              sort: true,
              render: (data: Tenant) => (
                <NavLink title={data.id} to={`tenants/${data.id}`}>
                  {data.id.split("-")[0]}
                </NavLink>
              ),
            },
            {
              name: "name",
              label: "Tenant Name",
              filter: {
                name: "name",
              },
              sort: true,
            },
            {
              name: "created_by",
              label: "Created By",
              filter: {
                name: "created_by",
              },
              sort: true,
            },
            {
              name: "created_at",
              label: "Created",
              format: formatDate,
              filter: {
                name: "created",
                type: "date",
              },
              sort: true,
            },
            {
              name: "updated_by",
              label: "Updated By",
              filter: {
                name: "updated_by",
              },
              sort: true,
            },
            {
              name: "updated_at",
              label: "Updated",
              format: formatDate,
              filter: {
                name: "updated",
                type: "date",
              },
              sort: true,
            },
            {
              name: "active",
              label: "Active",
              render: (data: Tenant) => {
                return <Check color={data.active ? "primary" : "disabled"} />;
              },
              filter: {
                name: "active",
                type: "text",
              },
              sort: true,
            },
            {
              label: "Notes",
              name: "count_opnotes",
              sort: true,
              render: (data: Tenant) => {
                return (
                  <div className={styles.buttons}>
                    <Button
                      color="default"
                      variant="contained"
                      disabled={!data.active}
                      onClick={() => forwardTo(`/opnotes/?tenant=${data.name}`)}
                    >
                      {data.count_opnotes} opNotes
                    </Button>
                    <Button
                      color="default"
                      variant="contained"
                      disabled={!data.active}
                      onClick={() => forwardTo(`/ednotes/?tenant=${data.name}`)}
                    >
                      {data.count_ednotes} edNotes
                    </Button>
                    {/*// <div className={styles.buttons}>*/}
                    {/*//   <ToggleTenant id={id} active={active} />*/}
                    {/*//   <DeleteTenant id={id} active={active} />*/}
                  </div>
                );
              },
            },
          ]}
          columnsData={storeItems}
          onSubmit={useCallback(
            (values, sort) => {
              dispatch(actions.load(getFilter(values, sort)));
            },
            [dispatch, getFilter]
          )}
          onSubmitMore={useCallback(
            (values: any) => {
              dispatch(actions.load(values));
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <Button
              color="primary"
              variant="contained"
              onClick={handleAdd}
              startIcon={<CreateIcon />}
              className={buttonsUseStyles().main}
            >
              Add Tenant
            </Button>
          }
          colGroup={
            <colgroup>
              <col width={10} />
              <col width={150} />
              <col width={200} />
              <col width={200} />
              <col width={200} />
              <col width={200} />
              <col width={200} />
              <col width={200} />
            </colgroup>
          }
        />
        <Grid container>
          <Grid container item xs={6}>
            <DeleteTenants selected={selected} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
