import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Sort, TableFilters } from "../../Components/Table";
import { Button, Checkbox, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import {
  actions as usersActions,
  actions,
  loadMoreUsersRequestName,
  loadUsersRequestName,
  User,
} from "../../store/users";
import { isProcessingSelector } from "../../store/requests";
import { FormikValues } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { Roles } from "../../store/user";
import { loadUserProfileTenantsRequestName } from "../../store/tenants";
import { forwardTo } from "../../history";
import { buttonsUseStyles } from "../../layout/Styles/Buttons";
import CreateIcon from "@material-ui/icons/Create";
import formatDate from "../../utils/formatDate";
import { DeleteUsers } from "./components/DeleteUsers";
import { AlertUsers } from "./components/AlertUsers";
import { BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { pageViewGA } from "../../utils/googleAnalytics";
import { SearchableSortDirection } from "../../API";

const initialSort = {
  field: "created_at",
  dir: SearchableSortDirection.desc,
};

const getFilter = (values: FormikValues, sort: Sort | undefined) => {
  const sortStringValue = `${
    sort ? `ordering=${sort.dir === "desc" ? "-" : ""}${sort.field}` : ""
  }`;
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : "";
  return value ? `${fieldName}=${value}&${sortStringValue}` : { sort: sort };
};

const useStyles = makeStyles({
  buttonGroup: {
    textAlign: "right",

    "& button": {
      margin: 4,
    },
  },
});

export const Users = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const isLoading = useSelector(
    isProcessingSelector([
      loadUsersRequestName,
      loadUserProfileTenantsRequestName,
    ])
  );
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreUsersRequestName])
  );
  const storeItems = useSelector(
    (state: RootState) => state.users.data.listUsers.items
  );
  const storeCount = useSelector(
    (state: RootState) => state.users.data.listUsers.count
  );
  const hasMore = useSelector(
    (state: RootState) => !!state.users.data.listUsers?.next
  );
  const isSuperAdmin = currentUserRole.includes(Roles.SuperAdmin);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const allSelectableIds = useMemo(() => {
    return storeItems.reduce<string[]>((res, User) => {
      return [...res, User.username];
    }, []);
  }, [storeItems]);
  const handleSelectAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(new Set(e.target.checked ? allSelectableIds : []));
    },
    [setSelected, allSelectableIds]
  );
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: "Users",
      href: "/users",
    },
    {
      title: `${storeCount} items`,
    },
  ];
  const handleAdd = useCallback(() => {
    push("/users/new");
  }, [push]);

  useEffect(() => {
    dispatch(usersActions.load({}));
    pageViewGA(window.location.pathname + window.location.search);
  }, [dispatch, isSuperAdmin]);

  // @ts-ignore
  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          singleColumnFilter
          initialSort={initialSort}
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          breadcrumbs={breadcrumbs}
          columns={[
            {
              label: (
                <Checkbox
                  color="primary"
                  checked={selected.size === allSelectableIds.length}
                  onChange={handleSelectAllChange}
                />
              ),
              name: "",
              render: (data: User) => (
                <Checkbox
                  checked={selected.has(data.username)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      selected.add(data.username);
                    } else {
                      selected.delete(data.username);
                    }
                    setSelected(new Set(selected));
                  }}
                  color="primary"
                />
              ),
            },
            {
              name: "id",
              label: "ID",
              filter: {
                name: "id",
              },
              sort: true,
              render: (data: User) => (
                <NavLink title={data.id} to={`/users/${data.id}`}>
                  {data.id.split("-")[0]}
                </NavLink>
              ),
            },
            {
              name: "username",
              label: "Name",
              filter: {
                name: "full_name",
              },
              sort: true,
              render: (data: User) => (
                <Typography title={`${data.first_name} ${data.last_name}`}>
                  {data.username}
                </Typography>
              ),
            },
            {
              label: "Created",
              name: "created_at",
              format: formatDate,
              filter: {
                name: "created",
                type: "date",
              },
              sort: true,
            },
            ...(isSuperAdmin
              ? [
                  {
                    name: "tenant",
                    label: "Tenant",
                    filter: {
                      name: "tenant",
                    },
                    sort: true,
                  },
                ]
              : []),
            {
              name: "email",
              label: "Email",
              filter: {
                name: "email",
              },
              sort: true,
            },
            {
              label: "Updated",
              name: "updated_at",
              format: formatDate,
              filter: {
                name: "updated",
                type: "date",
              },
              sort: true,
            },
            {
              name: "phone_number",
              label: "Phone",
              filter: {
                name: "phone_number",
              },
              sort: true,
            },
            {
              name: "groups",
              label: "Role",
              format: (value: string[]) => value.join(", ") ?? "",
              filter: {
                name: "groups",
              },
              sort: true,
            },
            {
              name: "specialty",
              label: "Specialty",
              filter: {
                name: "specialty",
              },
              sort: true,
            },
            {
              name: "practice",
              label: "Practice",
              filter: {
                name: "practice",
              },
              sort: true,
            },
            {
              label: "Last login",
              name: "last_login",
              format: formatDate,
              filter: {
                name: "last_login",
                type: "date",
              },
              sort: true,
            },
            {
              label: "Notes",
              name: "count_opnotes",
              sort: true,
              render: (data: User) => {
                return (
                  <div className={styles.buttonGroup}>
                    <Button
                      color="default"
                      variant="contained"
                      disabled={!data.is_active}
                      onClick={() =>
                        forwardTo(`/opnotes/?created_by=${data.username}`)
                      }
                    >
                      {data.count_opnotes} opNotes
                    </Button>
                    <Button
                      color="default"
                      variant="contained"
                      disabled={!data.is_active}
                      onClick={() =>
                        forwardTo(`/ednotes/?created_by=${data.username}`)
                      }
                    >
                      {data.count_ednotes} edNotes
                    </Button>
                  </div>
                );
              },
            },
          ]}
          columnsData={storeItems}
          onSubmit={useCallback(
            (values, sort) => {
              dispatch(actions.load(getFilter(values, sort)));
            },
            [dispatch]
          )}
          onSubmitMore={useCallback(
            (values: FormikValues) => {
              dispatch(actions.load(values));
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <Button
              color="primary"
              variant="contained"
              onClick={handleAdd}
              startIcon={<CreateIcon />}
              className={buttonsUseStyles().main}
            >
              Add User
            </Button>
          }
          colGroup={
            <colgroup>
              <col width={10} />
              <col width={130} />
              <col width={150} />
              <col width={150} />
              <col width={150} />
              <col width={200} />
              <col width={150} />
              <col width={150} />
              <col width={130} />
              <col width={170} />
              <col width={150} />
              <col width={200} />
            </colgroup>
          }
        />
        <Grid container>
          <Grid container item xs={6}>
            <DeleteUsers selected={selected} />
            <AlertUsers selected={selected} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
