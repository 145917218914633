import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateOpNoteMutationVariables,
  UpdateOpNoteInput,
  SearchableOpNoteFilterInput,
} from "../../API";
import { Sort } from "../../Components/Table";

export type OpNote = {
  id: string;
  text: string;
  filename: string | null;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  cpt_codes_approved: string[];
  cpt_codes_rejected: string[];
  cpt_codes_assigned: string[];
  cpt_codes_coded: string[];
};

export type OpNotes = {
  listOpNotes: {
    count: number;
    next: string | null;
    previous: string | null;
    items: OpNote[] | null;
  } | null;
};

export type OpNotesState = {
  data: OpNotes;
  selectedOpNote: OpNote | null;
  cptCodeDescription?: string;
};

export type LoadRequestParams = {
  filter: SearchableOpNoteFilterInput | null;
  sort?: Sort;
} | null;

const initialState: OpNotesState = {
  data: {
    listOpNotes: null,
  },
  selectedOpNote: null,
};

const slice = createSlice({
  name: "opNotes",
  initialState,
  reducers: {
    load: (state, action: PayloadAction<any | undefined>) => state,
    loadMore: (state, action: any) => state,
    loaded: (state, action: PayloadAction<OpNotes>) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    loadedMore: (state, action: PayloadAction<OpNotes>) => {
      if (state.data && state.data.listOpNotes) {
        return {
          ...state,
          data: {
            listOpNotes: {
              ...state.data.listOpNotes,
              ...action.payload.listOpNotes,
              items: [...(action.payload?.listOpNotes?.items ?? [])],
            },
          },
        };
      }

      return state;
    },
    create: (state, action: PayloadAction<CreateOpNoteMutationVariables>) =>
      state,
    loadOpNote: (state, action: PayloadAction<any>) => state,
    loadedOpNote: (state, action: PayloadAction<OpNote | null>) => {
      return {
        ...state,
        selectedOpNote: action.payload,
      };
    },
    update: (state, action: PayloadAction<UpdateOpNoteInput>) => state,
    updated: (
      state,
      action: PayloadAction<{ data: OpNote; updater: string }>
    ) => ({
      ...state,
      selectedOpNote: {
        ...state.selectedOpNote,
        ...action.payload.data,
      },
    }),
    download: (state) => state,
    downloadOpNotes: (state, actions: PayloadAction<string[]>) => state,
    delete: (state) => state,
    deleteOpNotes: (state, actions: PayloadAction<string[]>) => {
      if (
        state.data &&
        state.data.listOpNotes &&
        state.data.listOpNotes.items
      ) {
        return {
          ...state,
          data: {
            listOpNotes: {
              ...state.data.listOpNotes,
              items: [
                ...(state.data.listOpNotes?.items.filter(
                  (item) => !actions.payload.includes(item.id)
                ) ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    deleted: (state) => ({
      ...state,
      selectedOpNote: null,
    }),
    loadCptCodeDescription: (state, action: PayloadAction<string>) => ({
      ...state,
      cptCodeDescription: undefined,
    }),
    loadedCptCodeDescription: (state, action: PayloadAction<string>) => ({
      ...state,
      cptCodeDescription: action.payload,
    }),
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
