import { Container, Col, Row, Button } from "react-bootstrap";
import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./galeai.css";
import Hospital from "../../image/hospital.png";
import Chairs from "../../image/chairs.png";
import Circles from "../../image/circles.png";
import VectorRight from "../../image/VectorRight.png";
import Analysis from "../../image/analysis.png";
import Doctors from "../../image/doctors.png";
import Sales from "../../image/sales.png";
import Time from "../../image/time.png";
import whygaleimg1 from "../../image/whygaleimg1.png";
import whygaleimg2 from "../../image/whygaleimg2.png";
import whygaleimg3 from "../../image/whygaleimg3.png";
import whygaleimg4 from "../../image/whygaleimg4.png";
import TwoColumnBanner from "../TwoColumnBanner";

const GaleAi = () => {
  const checkListData = [
    "Upwards of 150 billion lost revenue from coding mishaps",
    "A 2-3 month delay in accounts receivable",
    "Millions in lost revenue in poor RCM.",
    "A high staff turnover rate leading to inconsistency on the job.",
    "Inaccurate, inefficient, and variable coding",
  ];

  const galeSolutions = [
    { heading: "Increases revenue capture", icon: Sales },
    { heading: "Decreases AR time", icon: Time },
    {
      heading: "Improves physicians lives by limiting administration burden",
      icon: Doctors,
    },
    {
      heading: "Improves efficiency, consistency, and lack of variability.",
      icon: Analysis,
    },
  ];

  const galeHospital = [
    {
      heading: "Flip the script on the current market",
      paragraph:
        "Did you know that insurance companies deny around 1 in every 7 medical claims during a first-time submission? By utilizing a smart automation system, getting one step ahead of the insurance market helps ensure a higher approval percentage, leading to fewer headaches for you and your team.",
      buttonText: "Learn More",
      image: whygaleimg1,
      buttonLink:"/contact"
    },
    {
      heading: "Enjoy more time with the people who matter most",
      paragraph:
        "Nothing can quite replace the feeling of spending more time with the people you love. From your family and friends to the patients you hold close to your heart, spending more time with them matters.",
      paragraph2:
        "The best way to cut down on your hours at the office? By automating the workflow involved in your medical coding and billing process. Not only does GaleAI save you money and headaches, but we also save you time too.",
      buttonText: "Explore Your Options",
      image: whygaleimg2,
      reverse: true,
      buttonLink:"/contact"
    },
    {
      heading: "Tap into a trusted team of advisors",
      paragraph:
        "As doctors ourselves, we understand the burden that coding and billing can cause especially after long days of taking care of patients, performing surgeries, or completing all our necessary/mandatory administrative tasks.",
      paragraph2:
        "We recognize that patient care is top-priority and that is why we created GaleAI so that providers can focus on their patients instead of computer tasks. With GaleAI we bring transformative ways to take on the day-to-day operations and allow the provider to focus on what they do best, patient care.",
      paragraph3: "From one doctor to another, we are here for you.",
      buttonText: " Meet The Expert Team",
      image: whygaleimg3,
      buttonLink:"/about"
    },
    {
      heading: "Nix the extra cost at the source",
      paragraph:
        "The entire medical coding and billing process is expensive and only getting more costly. From extra hours, people and time, these costs can be astronomical for all of us.",
      paragraph2:
        "With GaleAI, you can remove the administrative burdens that cost the healthcare system millions in revenue each year for fractions of a percent. Automate these processes to to improve yourbottom line.",
      buttonText: "Increase Revenue",
      image: whygaleimg1,
      reverse: true,
      buttonLink:"/contact"
    },
  ];

  return (
    <>
      <Header />
      <Container className="ContainerMain">
        <Row className="galeContainer">
          <Col className="galeHeadingCol">
            <div className="galeHeading">Why Gale AI?</div>
            <div className="galeSubHeading">
              Where comprehensive care meets innovative technology.{" "}
            </div>{" "}
            <div className="galeBlueHeading">
              The future of healthcare is here.
            </div>{" "}
            <div className="galeParagraph">
              <p>
                By using artificial intelligence and natural language processing
                to code across an array of diagnoses and procedures accurately,
                healthcare centers, providers, and specialty medical clinics
                across the country can code medical notes in real-time, submit
                claims more efficiently and receive a higher revenue stream than
                ever before.
              </p>
            </div>
            <div className="galeSubHeading">
              An advanced and inclusive system created with your unique needs in
              mind.
            </div>
            <div className="galeBlueDiv mb-5 mt-4">
              <div className="galeGradiant"></div>
              <p className="galeGradiantParagraph">
                Did you know that more than 40% of physicians reported feeling
                burnt out over the last year? If you’re in that percentage,
                you’re not alone.{" "}
              </p>
            </div>
            <p className="galeGradiantSecPara">
              By reorganizing your priorities and automating key systems in your
              process system, GaleAI is here to nix the burnout.
            </p>
          </Col>
        </Row>
        <Row className="threeColumnsRow">
          <Col className="threeColumns">
            <h5 className="threeColumnsHeading">Hospitals</h5>
            <img src={Hospital} className="threeColumnsImages" />
            <p>
              A faster, more thorough automated tool that allows you to process
              high volume claims daily.
            </p>
          </Col>
          <Col className="threeColumns">
            <h5 className="threeColumnsHeading">Private Practices</h5>
            <img src={Chairs} className="threeColumnsImages" />
            <p>
              A more reliable way to ensure you get your money faster, with
              codes getting approved more frequently.
            </p>
          </Col>
          <Col className="threeColumns">
            <h5 className="threeColumnsHeading">Medical Coders</h5>
            <img src={Circles} className="threeColumnsImages" />
            <p>
              A simplified process created with a direct improvement to your
              workflow in mind. Get more work done in less time.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="pt-5">
            <p className="galeSubHeading pb-0 mb-1">
              It’s time to level up in our industry.{" "}
              <span className="text-info">GaleAI is the answer.</span>
            </p>

            <div className="galeParagraph">
              {" "}
              <p className="mb-5 pt-0">
                Let’s get straight to the point for a second.{" "}
              </p>
              <p className="mb-5">
                {" "}
                The current medical coding and billing system no longer fits our
                schedules, lifestyle, or technological advancements.
              </p>
              <p className="mb-5">
                There are various detrimental results that cost us time and
                money.{" "}
              </p>
            </div>

            <div className="checkListDiv">
              <h6 className="checkListHeading">
                Most notably, we are seeing:{" "}
              </h6>
              <div className="checkListRow">
                {checkListData?.map((item, index) => (
                  <span className="checkListSpan" key={index}>
                    <img src={VectorRight} className="rightVector" />
                    <p className="mb-2">{item}</p>
                  </span>
                ))}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <h5 className="text-center theSolution pt-5 pb-3">
            The solution GaleAI.
          </h5>
          <p className="text-center pb-4">
            With industry-leading artificial intelligence GaleAI:
          </p>
          <Col className="d-flex align-items-center justify-content-around ">
            {galeSolutions?.map((item, index) => (
              <div key={index} className="w-auto galeSolutions">
                <div className="galeSolutionsDiv">
                  <img src={item?.icon} className="galeSolutionsImage" />
                  <p>{item?.heading}</p>
                </div>
              </div>
            ))}
          </Col>
        </Row>

        <Row className="d-flex justify-content-center pt-5">
          <Col className="galeSubHeadingShort">
            GaleAI makes your hospital lean, makes physicians happier, and
            increases the bottom line.{" "}
          </Col>
        </Row>
        {galeHospital?.map((item, index) => {
          return <TwoColumnBanner item={item} />;
        })}

        <Row className="pt-5 pb-5">
          <Col className="d-flex align-items-center justify-content-center flex-column pt-5">
            <h5 className="galeSubHeadingShort">
              Don’t waste another day or dollar on a broken system.
            </h5>
            <p className="galeFocusPara">
              Focus on the future of healthcare and tap into the resources that
              meet your needs. With GaleAI, the future of healthcare is in your
              hands.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default GaleAi;
