import React, {useEffect} from 'react';
import {Page} from '../../layout/Page';
import {pageViewGA} from "../../utils/googleAnalytics";
import {Breadcrumbs, BreadcrumbsItem} from "../../Components/Breadcrumbs";
import {Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {actions as actionsOpNotes} from "../../store/opNotes";
import {actions as actionsCptCodes} from "../../store/cptCodes";
import {actions as actionsUsers} from "../../store/users";
import {actions as actionsTenants} from "../../store/tenants";

export const Models = () => {
  const dispatch = useDispatch();
  const storeOpNotesCount = useSelector((state: RootState) => state.opNotes.data.listOpNotes?.count ?? 0);
  const storeCptCodesCount = useSelector((state: RootState) => state.cptCodes.listCodes?.count ?? 0);
  const storeUsersCount = useSelector((state: RootState) => state.users.data.listUsers?.count ?? 0);
  const storeTenantsCount = useSelector((state: RootState) => state.tenants.data.listTenants?.count ?? 0);
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: 'Models',
      href: '/models'
    },
    {
      title: 'System information'
    }
  ];
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
    dispatch(actionsOpNotes.load({}));
    dispatch(actionsCptCodes.load({}));
    dispatch(actionsUsers.load({}));
    dispatch(actionsTenants.load({}));
  }, [dispatch]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <Grid item>
          <Breadcrumbs items={breadcrumbs}/>
        </Grid>
        <Typography>Version Information:</Typography>
        <Typography>- Django Rest Framework version: 3.12.4</Typography>
        <Typography>- React JS version: 17.0.0</Typography>

        <Typography>Content Information:</Typography>
        <Typography>- Total Operation Notes: {storeOpNotesCount}</Typography>
        <Typography>- Total Cpt Codes: {storeCptCodesCount}</Typography>
        <Typography>- Total Tenants: {storeTenantsCount}</Typography>
        <Typography>- Total Users: {storeUsersCount}</Typography>

        <Typography>ML/NLP Algorithm Information:</Typography>
        <Typography>- Speed: None</Typography>
        <Typography>- Accuracy: None</Typography>
      </Grid>
    </Page>
  )
}