import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import "./index.css";
import App from "./App";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { store } from "./store";
import { Notifications } from "./Components/Notifications";
import history from "./history";
import { Authenticator } from "./screens/Authenticator";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home";
import About from "./Components/About";
import GaleAi from "./Components/GaleAi";
import Contact from "./Components/Contact";
import Solution from "./Components/Solution";
import Blog from "./Components/blog";
import SingleBlog from "./Components/SingleBlog";
import ScrollToTop from "./Components/ScrollToTop";
import Header from "./Components/Header/Header";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/solution" component={Solution} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:singleBlogId" component={SingleBlog} />
          <Route exact path="/why-galeai" component={GaleAi} />
          <Authenticator>
            <App />
          </Authenticator>
        </Switch>
        <ToastContainer autoClose={2000} />
      </Router>
      <Notifications />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
