import { API, Auth, graphqlOperation } from 'aws-amplify';
import { put, call } from 'redux-saga/effects';
import { actions as requestActions } from './requests/requests.slice';
import {
  actions as notificationsActions,
  NewNotification,
} from './notifications';
import { customAxios } from './customAxios';
import {FilterOptions} from "../Components/Filters/FilterItem";
import {filter} from "lodash";
import {SearchableSortDirection} from "../API";

export type RequestConfig = {
  name: string;
  successNotification?: NewNotification;
  errorNotification?: NewNotification;
  variables?: any;
  operation?: any;
  rest?: {
    url: string;
    apiName: string;
    data?: Object;
    method: 'get' | 'post' | 'put' | 'del';
  };
  drf?: {
    url: string;
    method: | 'get' | 'GET'
      | 'delete' | 'DELETE'
      | 'head' | 'HEAD'
      | 'options' | 'OPTIONS'
      | 'post' | 'POST'
      | 'put' | 'PUT'
      | 'patch' | 'PATCH'
      | 'purge' | 'PURGE'
      | 'link' | 'LINK'
      | 'unlink' | 'UNLINK';
    type?: 'blob' | 'stream';
    data?: Object;
    perPage?: number;
    page?: number;
    sort?: String;
  };
  propagateError?: boolean;
};

export type RequestError = {
  message: string;
  path: string | null;
};
export type Request<T = any> = {
  data: T | null;
  errors: RequestError[];
} | void;

export default function* requestSaga(config: RequestConfig) {
  const {
    name,
    rest,
    drf,
    operation,
    variables,
    successNotification,
    errorNotification,
    propagateError,
  } = config;
  try {
    yield put(requestActions.start(name));
    let response: Request;
    if (operation) {
      response = yield call(
        [API, 'graphql'],
        graphqlOperation(operation, variables)
      );
    } else if (rest) {
      // @ts-ignore
      const session = yield call([Auth, 'currentSession']);
      response = yield call(
        // @ts-ignore
        [API, rest.method],
        rest.apiName,
        rest.url,
        {
          headers: {
            Authorization: session.getAccessToken().getJwtToken(),
          },
          ...rest.data,
        }
      );
    }
    else if (drf) {
      response = yield call(
        customAxios, drf.url,
        {
          params: {
            'per_page': drf.perPage,
            'page': drf.page,
            'ordering': drf.sort,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          responseType: drf.type ? drf.type : 'stream',
          method: drf.method,
          data: drf.data
        }
      );
    }
    else {
      console.error('Unknown API call');
      return;
    }

    yield put(requestActions.finish(name));

    if (successNotification) {
      yield put(
        notificationsActions.add({
          type: 'success',
          ...successNotification,
        })
      );
    }

    return response as any;
  } catch (e) {
    yield put(requestActions.finish(name));

    yield put(
      requestActions.error({
        name,
        error: String(e),
      })
    );
    if (propagateError) {
      // throw Error(e);
    } else {
      if (errorNotification) {
        yield put(
          notificationsActions.add({
            type: 'error',
            ...errorNotification,
          })
        );
      } else {
        let message:any = e;

        // if (e.response && e.response.data) {
        //   message = e.response.data.message;
        // } else if (e.errors) {
        //   message = (e.errors as RequestError[])
        //     .reduce<string[]>((res, error) => [...res, error.message], [])
        //     .join(', ');
        // }

        yield put(
          notificationsActions.add({
            type: 'error',
            message,
          })
        );
      }
    }
  }
}
