import React, {useEffect} from 'react';
import {Page} from '../../layout/Page';
import {pageViewGA} from "../../utils/googleAnalytics";
import {Breadcrumbs, BreadcrumbsItem} from "../../Components/Breadcrumbs";
import {Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";


export const Finances = () => {
  const dispatch = useDispatch();
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: 'Finances',
      href: '/finances'
    },
    {
      title: 'Stripe'
    }
  ];
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, [dispatch]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <Grid item>
          <Breadcrumbs items={breadcrumbs}/>
        </Grid>
        <Typography>In development...</Typography>
      </Grid>
    </Page>
  )
}