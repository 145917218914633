import { useEffect } from "react";
import { History } from "history";

import { withRouter } from "react-router-dom";

type Props = {
  history: History;
};
const ScrollToTop = ({ history }: Props) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);
