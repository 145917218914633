import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { IconProp} from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: 8,
    flexShrink: 0,
    justifyContent: 'center',

    '& p': {
      margin: 0,
    },
  },
  list: {
    display: 'flex',
    margin: '0 8px 0 0',
    padding: 0,

    '& li': {
      padding: 0,
      margin: '0 8px 0 0',
      listStyle: 'none',
    },
  },
});

const currentYear = new Date().getFullYear();
const estYear = 2021;

export const Footer = memo(() => {
  const styles = useStyles();

  return (
    <footer className={styles.root}>
      <ul className={styles.list}>
        <li>
          <a
            href="https://twitter.com/BilleNote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter as IconProp} />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/billenote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook as IconProp} />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/billenote/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram as IconProp} />
          </a>
        </li>
        <li>
          <a href="mailto:tyleragonzalezmed@gmail.com?subject%20=%20Bill-e-Note">
            <FontAwesomeIcon icon={faEnvelope as IconProp} />
          </a>
        </li>
      </ul>
      <p>
        © {estYear} {estYear < currentYear ? ` -  ${currentYear}` : ''} e. All
        rights reserved.
      </p>
    </footer>
  );
});
