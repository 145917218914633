import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FileHeader } from './FileHeader';

export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
  onUpload: (file: File, url: string) => void;
}

export function SingleFileUploadWithProgress({
                                               file,
                                               onDelete,
                                               onUpload,
                                             }: SingleFileUploadWithProgressProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function upload() {
      const url = await uploadFile(file, setProgress);
      onUpload(file, url);
    }

    upload();
  }, []);

  return (
    <Grid item>
      <FileHeader file={file} onDelete={onDelete} />
      <LinearProgress variant="determinate" value={progress} />
    </Grid>
  );
}

async function uploadFile(file: File, onProgress: (percentage: number) => void) {

  return new Promise<string>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', `${process.env.REACT_APP_API_SERVER}/api/v1/cpt-codes/upload/`);
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / file.size) * 100;
        onProgress(Math.round(percentage));
      }
    };
    const formData = new FormData();
    formData.append("file", file);
    xhr.send(formData);
  });
}