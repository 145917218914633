import React, { Component } from 'react';
import { Page } from '../../layout/Page';
import { Typography } from '@material-ui/core';

export class FeedbackConfirmationPage extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push('/opnotes/new');
    }, 5000)
  };


  render() {
    return (
      <Page>
        <Typography align="center" variant="h3">
          Thank you for your feedback, we will respond to you within 24-48 hours
        </Typography>
      </Page>
    );
  }
}
