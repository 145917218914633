import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const TwoColumnBanner = ({ item }: any) => {
  const history = useHistory()
  return (
    <Row
      style={{
        flexDirection: item.reverse ? "row-reverse" : "row",
        justifyContent: "center",
      }}
      className="pt-5"
    >
      <Col
        className="galeTextContainer  pt-5 mr-lg-0 mr-sm-0 mr-xs-0 ml-lg-0 ml-sm-0 ml-xs-0"
        style={{
          marginRight: !item.reverse ? "50px" : "",
          marginLeft: item.reverse ? "50px" : "",
        }}
      >
        <p className="galeSubHeading pb-0 mb-1 textLeft">{item.heading}</p>
        <div className="contain-text-second">{item.paragraph}</div>
        <div className="get-started-btn">
          <Button
            variant="outline-success"
            className="btn-btn-get-home-bottom galeCustomBtn"
            onClick={() => history.push(item.buttonLink)}
          >
            <span className="get-text learnMore">{item.buttonText} </span>
          </Button>
        </div>
      </Col>

      <Col className="galeTextContainer pt-5 mr-sm-0 mr-xs-0">
        <img src={item.image} alt="wHY GALE AI" />
      </Col>
    </Row>
  );
};

export default TwoColumnBanner;
