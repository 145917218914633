import React, {useEffect} from 'react';
import {Page} from '../../layout/Page';
import {
  AnalyticsDashboard,
  SessionsByDateChart,
  SessionsGeoChart,
  ActiveUsersChart,
  TableChart,
  SessionsBySourceChart,
  SessionsByHourChart,
  PageViewsPerPathChart,
  GeoChart,
} from "react-analytics-charts";
import {pageViewGA} from "../../utils/googleAnalytics";
import {Breadcrumbs, BreadcrumbsItem} from "../../Components/Breadcrumbs";
import {Grid} from "@material-ui/core";

export const Metrics = () => {
  const limit = 7;
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: 'Metrics',
      href: '/metrics'
    },
    {
      title: 'Google Analytics'
    }
  ];
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <Grid item>
          <Breadcrumbs items={breadcrumbs}/>
        </Grid>
        <Grid item style={{ margin: 10}}>
          <AnalyticsDashboard
            authOptions={{clientId: '722477844462-optt7sk2e5iunk5jbrf701v44o6ecrgv.apps.googleusercontent.com'}}
            renderCharts={(gapi, viewId) => {
              return (
                <div>
                  <PageViewsPerPathChart
                    gapi={gapi}
                    viewId={viewId}
                    days={limit}
                  />
                  <SessionsByDateChart
                    gapi={gapi}
                    viewId={viewId}
                    days={limit}
                    showPageViews
                    showUsers
                  />
                  <SessionsByHourChart
                    gapi={gapi}
                    viewId={viewId}
                    days={limit}
                  />
                  <ActiveUsersChart
                    gapi={gapi}
                    viewId={viewId}
                    days={limit}
                  />
                  {/*<SessionsGeoChart*/}
                  {/*  gapi={gapi}*/}
                  {/*  viewId={viewId}*/}
                  {/*  days={limit}*/}
                  {/*  showPageViews*/}
                  {/*  options={{width: '100vw', height: '100vh', region: 'US', resolution: 'provinces'}}*/}
                  {/*/>*/}
                  <GeoChart
                    gapi={gapi}
                    query={{
                      metrics: "ga:sessions,ga:pageviews",
                      dimensions: "ga:region",
                      "start-date": `${limit}daysAgo`,
                      "end-date": "today",
                      ids: viewId,
                    }}
                    container="usa-traffic-geo-chart"
                    options={{
                      width: '100vw',
                      height: '100vh',
                      region: "US",
                      resolution: "provinces",
                    }}
                  />
                </div>
              );
            }}
          />
        </Grid>
      </Grid>
    </Page>
  )
}