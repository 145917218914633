import React, { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { Page } from '../../layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../store/requests';
import {
  actions,
  loadTenantRequestName,
  updateTenantRequestName,
} from '../../store/tenants';
import { Breadcrumbs, BreadcrumbsItem } from '../../Components/Breadcrumbs';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../store/rootReducer';
import { useParams } from 'react-router-dom';
import {eventGA, pageViewGA} from "../../utils/googleAnalytics";

export const getFieldTestId = (name: string) => `tenant-form-field-${name}`;
const getInputProps = (name: string) => ({
  'data-testid': getFieldTestId(name),
});
const breadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'Tenants',
    href: '/tenants',
  },
  {
    title: 'Edit Tenant',
  },
];

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(35, 'Must be 35 characters or less')
    .required('Required'),
});

const useStyles = makeStyles({
  root: {
    maxWidth: 700,
    margin: '0 auto',
  },
});

export const EditTenant: FC = () => {
  const styles = useStyles();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.tenants.selectedTenant);
  const isLoading = useSelector(isProcessingSelector([loadTenantRequestName]));
  const isSaving = useSelector(isProcessingSelector([updateTenantRequestName]));

  useEffect(() => {
    dispatch(actions.loadTenant(id));
    pageViewGA(window.location.pathname + window.location.search);
  }, [dispatch, id]);

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      {isLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        data && (
          <Formik
            validateOnBlur={false}
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={data}
            onSubmit={(values) => {
              dispatch(actions.update(values));
            }}
          >
            <Grid item xs={12}>
              <Form className={styles.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="name">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          fullWidth
                          {...field}
                          value={field.value || ''}
                          error={!!meta.error}
                          helperText={meta.error}
                          label="Name"
                          inputProps={getInputProps(field.name)}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSaving}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Formik>
        )
      )}
    </Page>
  );
};
