import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { actions as userActions } from "../user";

export type User = {
  count_opnotes: number;
  count_ednotes: number;
  id: string;
  password: string;
  last_login: string;
  is_superuser: boolean;
  first_name: string;
  last_name: string;
  date_joined: string;
  username: string;
  created_at: string;
  updated_at: string;
  phone_number: string;
  practice: string | null;
  specialty: string | null;
  is_active: boolean;
  email: string;
  is_staff: boolean;
  tenant: string | null;
  agreement: boolean;
  groups: number[] | null;
  user_permissions: number[] | null;
  email_verified: boolean;
  phone_number_verified: boolean;
};

export type Users = {
  listUsers: {
    count: number;
    next: string | null;
    previous: string | null;
    items: User[];
  };
};

export type UsersState = {
  data: Users;
  filterQuery: string;
};

const initialState: UsersState = {
  data: {
    listUsers: {
      count: 0,
      next: null,
      previous: null,
      items: [],
    },
  },
  filterQuery: "",
};

const slice = createSlice({
  initialState,
  name: "users",
  reducers: {
    load: (state, action: PayloadAction<any | undefined>) => ({
      ...state,
      filterQuery: action.payload || "",
    }),
    loaded: (state, action: PayloadAction<Users>) => ({
      ...state,
      data: action.payload,
    }),
    loadMore: (state, action: PayloadAction<any | undefined>) => state,
    loadedMore: (state, action: PayloadAction<Users>) => {
      return {
        ...state,
        data: {
          listUsers: {
            ...state.data.listUsers,
            ...action.payload.listUsers,
            items: [...(action.payload?.listUsers?.items ?? [])],
          },
        },
      };
    },
    deleteUser: (state, action: PayloadAction<string>) => state,
    alertUsers: (
      state,
      action: PayloadAction<{ op_notes: string[]; users: string[] }>
    ) => state,
    deleteUsers: (state, action: PayloadAction<string[]>) => {
      if (state.data && state.data.listUsers && state.data.listUsers.items) {
        return {
          ...state,
          data: {
            listUsers: {
              ...state.data.listUsers,
              items: [
                ...(state.data.listUsers?.items.filter(
                  (item) => !action.payload.includes(item.username)
                ) ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    reInviteUser: (state, action: PayloadAction<string>) => state,
  },
});

export const { actions, reducer } = slice;
