import { call, put, select, takeLatest } from "redux-saga/effects";
import requestSaga, { Request } from "../requestSaga";
import { createOpNote } from "../../graphql/mutations";
import { actions, OpNote } from "../opNotes";
import { RootState } from "../rootReducer";
import { User } from "../user";
import { forwardTo } from "../../history";
import fileDownload from "js-file-download";
import { eventGA } from "../../utils/googleAnalytics";

type OpNotesResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: OpNote[];
  };
} | void;

const formatOpNote = (res: OpNotesResponse) => {
  if (!res) {
    return { listOpNotes: null };
  }

  return {
    listOpNotes: {
      ...res.data.results,
      items: res.data.results.map((item) => ({
        ...item,
      })),
      count: res.data.count,
      next: res.data.next,
      previous: res.data.previous,
    },
  };
};

export const loadOpNotesRequestName = "loadOpNotes";

function* loadOpNotesSaga(action: ReturnType<typeof actions.load>) {
  const { pageRows, sort, page } = action.payload;
  const localStorageRows = localStorage.getItem("pageRows");

  const filterParam = action?.payload?.filter
    ? Object.entries(action?.payload?.filter).reduce(
        (prev, curr) =>
          (prev += curr[1] ? `${curr[0]}=${String(curr[1])}&` : ""),
        ""
      )
    : "";
  const res: OpNotesResponse = yield call(requestSaga, {
    name: loadOpNotesRequestName,
    drf: {
      method: "get",
      url:
        typeof action?.payload === "string"
          ? `/api/v1/op-notes/?${String(action.payload)}`
          : typeof action?.payload !== "string"
          ? `/api/v1/op-notes/?${filterParam}ordering=${
              sort?.dir === "asc" ? sort?.field : `-${sort?.field}`
            }&page=${page || 1}&per_page=${pageRows || localStorageRows || 10}`
          : `/api/v1/op-notes/`,
    },
  });
  if (res && res.data) {
    yield put(actions.loaded(formatOpNote(res)));
    yield call(
      eventGA,
      "OpNotes",
      `Load ${pageRows || localStorageRows || 10} OpNotes`,
      "Table"
    );
  }
}

export const loadMoreOpNotesRequestName = "loadMoreOpNotes";

function* loadMoreOpNotesSaga(pageNumber: any) {
  const localStorageRows = localStorage.getItem("pageRows");
  // @ts-ignore
  const nextUrl = yield select(
    (state: RootState) => state.opNotes.data.listOpNotes?.next ?? null
  );
  const res: OpNotesResponse = yield call(requestSaga, {
    name: loadMoreOpNotesRequestName,
    errorNotification: {
      message: "Invalid token.",
    },
    drf: {
      method: "get",
      url: nextUrl,
      page: pageNumber?.payload,
    },
  });

  if (res) {
    yield put(actions.loadedMore(formatOpNote(res)));
    yield call(
      eventGA,
      "OpNotes",
      `Load ${localStorageRows || 10} OpNotes`,
      "Table"
    );
  }
}

export const loadOpNoteRequestName = "loadOpNote";

function* loadOpNoteSaga(action: ReturnType<typeof actions.loadedOpNote>) {
  const response: Request<OpNote> = yield call(requestSaga, {
    name: loadOpNoteRequestName,
    drf: {
      method: "get",
      url: `/api/v1/op-notes/${action.payload}`,
    },
  });
  if (response) {
    yield put(actions.loadedOpNote(response.data));
    yield call(eventGA, "OpNotes", `Load ${action.payload} OpNote`, "Link");
  }
}

export const createOpNoteRequestName = "createOpNote";

function* createOpNoteSaga(action: ReturnType<typeof actions.create>) {
  const response: { data: OpNote | null } | null = yield call(requestSaga, {
    name: createOpNoteRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/`,
      data: {
        text: action.payload.input?.text,
      },
    },
    errorNotification: {
      message: "Something went wrong. Please try again later.",
    },
  });

  if (response && response.data) {
    yield forwardTo(`/opnotes/${response.data.id}`);
    yield call(eventGA, "OpNotes", "Create OpNote", "Button");
  }
}

export const updateOpNoteRequestName = "updateOpNote";

function* updateOpNoteSaga(action: ReturnType<typeof actions.update>) {
  const user: User = yield select((state: RootState) => state.user.data);
  const response: { data: OpNote | null } | null = yield call(requestSaga, {
    drf: {
      method: "patch",
      url: `/api/v1/op-notes/${action.payload.id}/`,
      data: {
        text: action.payload.text,
        updated_by: user.username,
      },
    },
    name: updateOpNoteRequestName,
    successNotification: {
      message: "OpNote has been updated",
    },
  });
  if (response && response.data) {
    yield put(
      actions.updated({
        data: response.data,
        updater: user.username,
      })
    );
    yield call(eventGA, "OpNotes", "Update OpNote", "Button");
  }
}

export const deleteOpNoteRequestName = "deleteOpNote";

function* deleteOpNoteSaga() {
  // @ts-ignore
  const opNote = yield select(
    (state: RootState) => state.opNotes.selectedOpNote
  );
  if (opNote) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "delete",
        url: `/api/v1/op-notes/${opNote.id}/`,
      },
      name: deleteOpNoteRequestName,
      successNotification: {
        message: "OpNote has been deleted",
      },
    });

    if (response) {
      yield put(actions.deleted());
      yield forwardTo("/");
      yield call(eventGA, "OpNotes", "Delete OpNote", "Button");
    }
  }
}

export const deleteOpNotesRequestName = "deleteOpNotes";

function* deleteOpNotesSaga(action: ReturnType<typeof actions.deleteOpNotes>) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "post",
        url: `/api/v1/op-notes/delete/`,
        data: {
          op_notes: action.payload,
        },
      },
      name: deleteOpNotesRequestName,
      successNotification: {
        message: `${action.payload.length} OpNotes has been deleted`,
      },
    });
    if (response) {
      yield call(
        eventGA,
        "OpNotes",
        `Delete ${action.payload.length} OpNotes`,
        "Button"
      );
    }
  }
}

export const downloadOpNoteRequestName = "downloadOpNote";

function* downloadOpNoteSaga() {
  // @ts-ignore
  const opNote = yield select(
    (state: RootState) => state.opNotes.selectedOpNote
  );
  if (opNote) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "get",
        type: "blob",
        url: `/api/v1/op-notes/${opNote.id}/download/`,
      },
      name: downloadOpNoteRequestName,
    });

    if (response) {
      fileDownload(
        response.data,
        response.headers["content-disposition"]
          ? response.headers["content-disposition"].split("filename=")[1]
          : "OpNotes.docx"
      );
      yield call(eventGA, "OpNotes", `Export ${opNote.id} OpNote`, "Button");
    }
  }
}

export const downloadOpNotesRequestName = "downloadOpNotes";

function* downloadOpNotesSaga(
  action: ReturnType<typeof actions.downloadOpNotes>
) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "post",
        type: "blob",
        url: `/api/v1/op-notes/download/`,
        data: {
          op_notes: action.payload,
        },
      },
      name: downloadOpNotesRequestName,
    });

    if (response) {
      fileDownload(
        response.data,
        response.headers["content-disposition"]
          ? response.headers["content-disposition"].split("filename=")[1]
          : "OpNotes.zip"
      );
      yield call(
        eventGA,
        "OpNotes",
        `Export ${action.payload.length} OpNotes`,
        "Button"
      );
    }
  }
}

export const saga = function* opNotesSaga() {
  yield takeLatest(actions.load.type, loadOpNotesSaga);
  yield takeLatest(actions.loadMore.type, loadMoreOpNotesSaga);
  yield takeLatest(actions.create.type, createOpNoteSaga);
  yield takeLatest(actions.loadOpNote.type, loadOpNoteSaga);
  yield takeLatest(actions.update.type, updateOpNoteSaga);
  yield takeLatest(actions.delete.type, deleteOpNoteSaga);
  yield takeLatest(actions.deleteOpNotes.type, deleteOpNotesSaga);
  yield takeLatest(actions.download.type, downloadOpNoteSaga);
  yield takeLatest(actions.downloadOpNotes.type, downloadOpNotesSaga);
};
