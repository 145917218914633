import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "../../layout/Page";
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import {
  actions,
  deleteOpNoteRequestName,
  downloadOpNoteRequestName,
  loadOpNoteRequestName,
  OpNote,
  updateOpNoteRequestName
} from "../../store/opNotes";
import { Breadcrumbs, BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { RootState } from "../../store/rootReducer";
import { isProcessingSelector } from "../../store/requests";
import { OpNoteDetailsDialog } from "./components/OpNoteDetailsDialog";
import { OpNoteCPTCodes } from "./components/OpNoteCPTCodes";
import usePrevious from "../../hooks/usePrevious";
import {
  actions as CPTCodesActions,
  loadApprovedSuggestedOpNoteCptCodesRequestName
} from "../../store/cptCodes";
import { OpNoteCptCodeLinkType } from "../../API";
import { ConfirmationDialog } from "../../Components/ConfirmationDialog";
import { usePermission } from "../../Components/Permission";
import { Roles } from "../../store/user";
import { NotFound } from "../NotFound";
import { Expandable } from "../../Components/Expandable";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SuggestionModes } from "./components/SuggestionModes";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CreateIcon from "@material-ui/icons/Create";
import { pageViewGA } from "../../utils/googleAnalytics";
import fileDownload from "js-file-download";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  TextRun
} from "docx";
import { Modal, Container, Form } from "react-bootstrap";

const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: "/opnotes",
    title: "OpNotes"
  },
  {
    title: "View OpNote"
  }
];

const useStyles = makeStyles({
  form: {
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "& > *": {
      padding: 8,
      flexBasis: "auto"
    }
  },
  text: {
    whiteSpace: "pre-wrap"
  }
});

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1)
    }
  })
);

export const ViewOpNote: FC = () => {
  const styles = useStyles();
  const classes = useStyles2();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [suggestedCPTCodesListSize, setSuggestedCPTCodesListSize] =
    useState(10);
  const [suggestionMode, setSuggestionMode] = useState("");
  const [fileName, setFileName] = useState("");
  const [editingText, setEditingText] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [details, setDetails] = useState<OpNote | null>(null);
  const isLoadingApprovedSuggestedCodes = useSelector(
    isProcessingSelector([loadApprovedSuggestedOpNoteCptCodesRequestName])
  );
  const isLoading = useSelector(isProcessingSelector([loadOpNoteRequestName]));
  const isDownloading = useSelector(
    isProcessingSelector([downloadOpNoteRequestName])
  );
  const isUpdating = useSelector(
    isProcessingSelector([updateOpNoteRequestName])
  );
  const isDeleting = useSelector(
    isProcessingSelector([deleteOpNoteRequestName])
  );
  const approvedSuggestedCodes = useSelector(
    (state: RootState) => state.cptCodes.opNoteApprovedSuggestedCodes
  );
  const data = useSelector((state: RootState) => state.opNotes.selectedOpNote);
  const previousData = usePrevious(data);
  const isDataEntry = usePermission([Roles.DataEntry]);
  const isCoder = usePermission([Roles.Coder]);
  const waitingForSuggestionMode = !isDataEntry && !isCoder && !suggestionMode;
  const canBeEdited = useMemo(() => {
    return (
      !waitingForSuggestionMode &&
      !isLoadingApprovedSuggestedCodes &&
      (!isDataEntry ||
        (isDataEntry &&
          (approvedSuggestedCodes.length === 0 ||
            approvedSuggestedCodes.every(
              (item) => item.linkType === OpNoteCptCodeLinkType.SUGGESTED
            ))))
    );
  }, [
    isDataEntry,
    approvedSuggestedCodes,
    waitingForSuggestionMode,
    isLoadingApprovedSuggestedCodes
  ]);

  const handleOpenDetails = useCallback(() => {
    setDetails(data);
  }, [setDetails, data]);
  const handleCloseDetails = useCallback(() => {
    setDetails(null);
  }, [setDetails]);
  const handleEditBtnClick = useCallback(() => {
    setIsEditable((value) => {
      if (value) {
        setShowEditConfirmation(true);
        return value;
      }
      setEditingText(data?.text ?? "");
      return true;
    });
  }, [setIsEditable, setEditingText, data]);
  const handleChangeText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEditingText(e.target.value);
    },
    [setEditingText]
  );
  const handleDialogClose = useCallback(
    () => setShowConfirmation(false),
    [setShowConfirmation]
  );
  const handleDialogSubmit = useCallback(() => {
    dispatch(actions.delete());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.loadOpNote(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (
      data &&
      previousData &&
      data !== previousData &&
      editingText &&
      isEditable
    ) {
      setEditingText("");
      setIsEditable(false);
    }
  }, [
    data,
    previousData,
    editingText,
    isEditable,
    setEditingText,
    setIsEditable
  ]);
  useEffect(() => {
    if (data && !waitingForSuggestionMode) {
      dispatch(
        CPTCodesActions.loadOpNoteApprovedSuggestedCodes({
          opNoteId: data.id,
          cptSuggestionMode: suggestionMode || null,
          cptSuggestionSize: suggestedCPTCodesListSize
        })
      );
    }
    pageViewGA(window.location.pathname + window.location.search);
  }, [
    dispatch,
    data,
    suggestionMode,
    waitingForSuggestionMode,
    suggestedCPTCodesListSize
  ]);
  const fileNameSubmit = async () => {
    const downloadData = approvedSuggestedCodes?.filter(
      (itm) => itm.linkType === OpNoteCptCodeLinkType.APPROVED
    );
    const table = new Table({
      columnWidths: [3505, 5505],
      rows: [
        {
          id: "Id",
          code: "CPT Code",
          description: "CPT Code Description",
          created_at: "2021-08-16T10:37:00.954840Z",
          created_by: "Grantulla",
          linkType: "SUGGESTED",
          updated_at: "2022-04-12T15:09:48.774670Z",
          updated_by: "Grantulla"
        },
        ...downloadData
      ]
        .slice(0, suggestedCPTCodesListSize + 1)
        // .filter((item, index) => !index || selected.has(item.code))
        .map((col) => {
          return new TableRow({
            children: [
              new TableCell({
                width: {
                  size: 3505,
                  type: WidthType.DXA
                },
                children: [new Paragraph(col.code)]
              }),
              new TableCell({
                width: {
                  size: 5505,
                  type: WidthType.DXA
                },
                children: [new Paragraph(String(col.description))]
              })
            ]
          });
        })
    });

    let text = data?.text || "";
    text = text.replace(/\n/g, "newLineForDoc");
    text = text.replace(/[^ -~]+/g, "");
    text = text.replace(/newLineForDoc/g, "\n");
    const textRuns = text
      .split("\n")
      .map((line) => new TextRun({ break: 1, text: line }));

    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: `ID: ${data?.id}`
            }),
            new Paragraph({
              text: ""
            }),
            new Paragraph({
              text: String([
                ...downloadData
                  .slice(0, suggestedCPTCodesListSize + 1)
                  .map((item, index) => {
           
                    if (suggestedCPTCodesListSize === 1) {
                      return index === 0 && `${item.code}`;
                    } else {
                      return !index ? `${item.code}` : ` ${item.code}`;
                    }
                  })
                  .filter((item) => {
                    return item;
                  })
              ])
            }),
            new Paragraph({
              text: ""
            }),
            new Paragraph({
              children: textRuns
            }),
            new Paragraph({
              text: ""
            }),
            table
          ]
        }
      ]
    });
    const blob = await Packer.toBlob(doc);
    fileDownload(blob, `${fileName}.docx`);

    setIsOpen(false);
    setFileName("");
  };
  const isExit = approvedSuggestedCodes?.find(
    (itm) => itm?.linkType === OpNoteCptCodeLinkType.APPROVED
  );
  if (!isLoading && !data) {
    return <NotFound />;
  }

  return (
    <Page withoutScroll={!isEditable}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (data) {
            dispatch(
              actions.update({
                id: data.id,
                text: editingText
              })
            );
          }
        }}
      >
        {isLoading ? (
          <Grid container item xs={12} justify='center'>
            <CircularProgress />
          </Grid>
        ) : data ? (
          <>
            <Grid container item xs={12} justify='space-between'>
              <Breadcrumbs items={breadcrumbs} />
              <div>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => push("/opnotes/new")}
                  startIcon={<CreateIcon />}
                  className={classes.button}
                >
                  Add OpNote
                </Button>
                <Button
                  color='default'
                  variant='contained'
                  onClick={() => push("/opnotes/upload")}
                  startIcon={<CloudUploadIcon />}
                  className={classes.button}
                >
                  Upload
                </Button>
              </div>
            </Grid>
            {data.filename ? (
              <Grid item xs={12}>
                <FormLabel>Filename:</FormLabel>
                <Typography className={styles.text} component='pre'>
                  {data.filename}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <FormLabel>ID:</FormLabel>
                <Typography className={styles.text} component='pre'>
                  {data.id}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormLabel>Text:</FormLabel>
              {isEditable ? (
                <TextField
                  fullWidth
                  multiline
                  value={editingText}
                  onChange={handleChangeText}
                />
              ) : (
                <Expandable>
                  <Typography className={styles.text} component='pre'>
                    {data.text}
                  </Typography>
                </Expandable>
              )}
            </Grid>
            <Grid container item xs={12} alignItems='center' wrap='nowrap'>
              {!isDataEntry && !isEditable && (
                <>
                  <FormControl
                    fullWidth
                    style={{ maxWidth: 160, marginRight: 16 }}
                  >
                    <FormLabel>Max Suggestions:</FormLabel>
                    <Select
                      value={suggestedCPTCodesListSize}
                      onChange={(e) => {
                        setSuggestedCPTCodesListSize(Number(e.target.value));
                      }}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                  <SuggestionModes onSelect={setSuggestionMode} />
                </>
              )}
              <div style={{ marginLeft: "auto", flexShrink: 0 }}>
                {!isEditable && (
                  <Button
                    color='default'
                    variant='contained'
                    disabled={isDownloading || !isExit ? true : false}
                    style={{ marginRight: 16, marginBottom: 8 }}
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => setIsOpen(true)}
                  >
                    Download
                  </Button>
                )}
                {!isEditable && (
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ marginRight: 16, marginBottom: 8 }}
                    onClick={() =>
                      push(`/feedbacks/new`, { opnote_id: data.id })
                    }
                  >
                    Feedback
                  </Button>
                )}
                {!isEditable && (
                  <Button
                    color='default'
                    variant='contained'
                    style={{ marginRight: 16, marginBottom: 8 }}
                    onClick={handleOpenDetails}
                  >
                    Details
                  </Button>
                )}
                {isEditable && (
                  <Button
                    disabled={isUpdating}
                    style={{ marginRight: 16, marginBottom: 8 }}
                    type='submit'
                    color='default'
                    variant='contained'
                  >
                    Submit
                  </Button>
                )}
                {canBeEdited && (
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ marginBottom: 8 }}
                    onClick={handleEditBtnClick}
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </Button>
                )}
              </div>
            </Grid>
            {!isEditable && (
              <>
                {isLoadingApprovedSuggestedCodes || waitingForSuggestionMode ? (
                  <Grid container justify='center'>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <OpNoteCPTCodes
                    opNoteId={data?.id ?? ""}
                    codes={approvedSuggestedCodes || []}
                    rowLimit={suggestedCPTCodesListSize}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
              </>
            )}
          </>
        ) : null}
        {details && (
          <OpNoteDetailsDialog
            data={details}
            open={!!details}
            deletable={canBeEdited}
            onDelete={() => {
              setDetails(null);
              setShowConfirmation(true);
            }}
            codes={approvedSuggestedCodes}
            onClose={handleCloseDetails}
          />
        )}
        <ConfirmationDialog
          disabled={isDeleting}
          text='You are going to delete the OpNote'
          open={showConfirmation}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
        />
        <ConfirmationDialog
          text='The changes you made will be lost. Are you sure you want to leave editing mode?'
          open={showEditConfirmation}
          onClose={() => {
            setShowEditConfirmation(false);
          }}
          onSubmit={() => {
            setIsEditable(false);
            setShowEditConfirmation(false);
          }}
        />
      </form>

      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='bioModal'
      >
        <Modal.Header style={{ height: "170px" }} closeButton>
          <Modal.Title
            style={{ width: "100%" }}
            id='contained-modal-title-vcenter'
          >
            <Container>
              File name:
              <Form.Control
                style={{ marginTop: "10px", marginBottom: "10px" }}
                type='text'
                placeholder='File Name Here'
                value={fileName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFileName(e.target.value)
                }
              />
            </Container>
            <Container style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className={classes.button}
                color='default'
                variant='contained'
                onClick={fileNameSubmit}
              >
                Submit
              </Button>
            </Container>
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </Page>
  );
};
