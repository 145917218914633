import React from "react";
import "@fontsource/plus-jakarta-sans";
import { Modal, Container, Row, Col } from "react-bootstrap";

import "../Home/index.css";
import tyler from "../../image/tyler01.png";

type Props = {
  show?: boolean;
  onHide?: () => void;
  modalData: any;
};
const BioModel = ({ show, onHide, modalData }: Props) => {
  const {
    name,
    qualification,
    designation,
    dp,
    description1,
    description2,
    description3,
  } = modalData;
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="bioModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Container>
              <Row className="modalHeaderRow">
                <Col className="modalHeaderCol">
                  <div className="modalImage">
                    <img src={dp} alt="bio" />
                  </div>
                </Col>
                <Col className="modal-heading-col">
                  <div className="modal-name-text">
                    <span className="person-name-modal">{name} </span>
                    <span className="person-des"> {qualification}</span>
                  </div>
                  <div className="modal-desc-text">{designation}</div>
                </Col>
              </Row>
            </Container>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="bio-desc">
            <p>{description1}</p>

            <p>{description2} </p>

            <p>{description3} </p>
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default BioModel;
