import { actions, Tenant } from "../tenants";
import { actions as userActions, User } from "../user";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { RootState } from "../rootReducer";
import { Tenants } from "./tenants.slice";
import { forwardTo } from "../../history";
import requestSaga, { Request } from "../requestSaga";
import {
  createOpNoteRequestName,
  deleteOpNoteRequestName,
  loadMoreOpNotesRequestName,
  loadOpNotesRequestName,
  OpNote,
  updateOpNoteRequestName,
} from "../opNotes";
import { eventGA } from "../../utils/googleAnalytics";

type TenantsResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Tenant[];
  };
} | void;

const formatTenants = (res: TenantsResponse) => {
  if (!res) {
    return { listTenants: null };
  }

  return {
    listTenants: {
      ...res.data.results,
      items: res.data.results.map((item) => ({
        ...item,
      })),
      count: res.data.count,
      next: res.data.next,
      previous: res.data.previous,
    },
  };
};

export const loadUserProfileTenantsRequestName = "loadUserProfileTenants";

function* loadUserProfileTenantsSaga(
  action: ReturnType<typeof actions.loadUserTenants>
) {
  const res: Request<Tenants> = yield call(requestSaga, {
    operation: /* GraphQL */ `
      query ListTenants(
        $filter: ModelTenantFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            active
          }
        }
      }
    `,
    variables: {
      limit: 1000,
      filter: action.payload
        ? {
            active: {
              eq: true,
            },
          }
        : undefined,
    },
    name: loadUserProfileTenantsRequestName,
  });

  if (res && res.data) {
    yield put(actions.loadedUserTenants(res.data.listTenants.items));
  }
}

export const loadTenantsRequestName = "loadTenants";

function* loadTenantsSaga(action: ReturnType<typeof actions.load>) {
  const { pageRows, sort, page } = action?.payload;
  const localStorageRows = localStorage.getItem("pageRows");
  const filterParam = action?.payload?.filter
    ? Object.entries(action?.payload?.filter).reduce(
        (prev, curr) =>
          (prev += curr[1] ? `${curr[0]}=${String(curr[1])}&` : ""),
        ""
      )
    : "";

  const res: Request<TenantsResponse> = yield call(requestSaga, {
    name: loadTenantsRequestName,
    errorNotification: {
      message: "Invalid token.",
    },
    drf: {
      method: "get",
      url: action.payload
        ? `/api/v1/tenants/?${filterParam}ordering=${
          sort?.dir === "asc" ? sort?.field : `-${sort?.field}`
        }&page=${page || 1}&per_page=${pageRows || localStorageRows || 10}`
        : `/api/v1/tenants/`,
    },
  });

  if (res) {
    // @ts-ignore
    yield put(actions.loaded(formatTenants(res)));
    yield call(eventGA, "Tenants", `Load ${localStorageRows} Tenants`, "Table");
  }
}

export const loadMoreTenantsRequestName = "loadMoreTenants";

function* loadMoreTenantsSaga(action: ReturnType<typeof actions.loadMore>) {
  const localStorageRows = localStorage.getItem("pageRows");
// @ts-ignore
  const nextUrl = yield select(
    (state: RootState) => state.tenants.data.listTenants?.next ?? null
  );
  const res: Request<TenantsResponse> = yield call(requestSaga, {
    name: loadMoreTenantsRequestName,
    errorNotification: {
      message: "Invalid token.",
    },
    drf: {
      method: "get",
      url: nextUrl,
    },
  });
  if (res) {
    // @ts-ignore
    yield put(actions.loaded(formatTenants(res)));
    yield call(eventGA, "Tenants", `Load ${localStorageRows} Tenants`, "Table");
  }
}

export const createTenantRequestName = "createTenant";

function* createTenantSaga(action: ReturnType<typeof actions.create>) {
  const response: { data: Tenant | null } | null = yield call(requestSaga, {
    name: createTenantRequestName,
    drf: {
      method: "post",
      url: `/api/v1/tenants/`,
      data: {
        name: action.payload,
      },
    },
    successNotification: {
      message: "Tenant has been created",
    },
  });

  if (response && response.data) {
    yield forwardTo("/tenants");
    yield call(eventGA, "Tenants", `Create ${action.payload} Tenant`, "Button");
  }
}

export const updateTenantRequestName = "updateTenant";

function* updateTenantSaga(action: ReturnType<typeof actions.update>) {
  const user: User = yield select((state: RootState) => state.user.data);
  const response: { data: Tenant | null } | null = yield call(requestSaga, {
    drf: {
      method: "patch",
      url: `/api/v1/tenants/${action.payload.id}/`,
      data: {
        name: action.payload.name,
        updated_by: user.username,
      },
    },
    name: updateTenantRequestName,
    successNotification: {
      message: "Tenant has been updated",
    },
  });

  if (response && response.data) {
    yield forwardTo("/tenants");
    yield call(
      eventGA,
      "Tenants",
      `Update ${action.payload.name} Tenant`,
      "Table"
    );
  }
}

export const loadTenantRequestName = "loadTenant";

function* loadTenantSaga(action: ReturnType<typeof actions.loadTenant>) {
  const response: { data: Tenant | null } | null = yield call(requestSaga, {
    name: loadTenantsRequestName,
    drf: {
      method: "get",
      url: `/api/v1/tenants/${action.payload}`,
    },
  });

  const responseTenantOpNotes: {
    data: { results: OpNote[] | null } | null;
  } | null = yield call(requestSaga, {
    name: loadTenantsRequestName,
    drf: {
      method: "get",
      url: `/api/v1/op-notes/?created_by__tenant=${action.payload}`,
    },
  });

  if (
    response &&
    response.data &&
    responseTenantOpNotes &&
    responseTenantOpNotes.data
  ) {
    yield put(
      actions.loadedTenant(
        Object.assign(response.data, {
          listOpNotes: responseTenantOpNotes.data.results,
        })
      )
    );
  }
}

export const toggleTenantRequestName = "toggleTenant";

function* toggleTenantSaga(action: ReturnType<typeof actions.toggle>) {
  const user: User = yield select((state: RootState) => state.user.data);
  const response: { data: Tenant | null } | null = yield call(requestSaga, {
    drf: {
      method: "patch",
      url: `/api/v1/tenants/${action.payload.id}/`,
      data: {
        active: !action.payload.active,
        updated_by: user.id,
      },
    },
    name: toggleTenantRequestName,
    successNotification: {
      message: "Tenant has been updated",
    },
  });

  if (response && response.data) {
    yield put(
      actions.toggled({
        id: action.payload.id,
        active: !action.payload.active,
      })
    );
  }
}

export const deleteTenantRequestName = "deleteTenant";

function* deleteTenantSaga(action: ReturnType<typeof actions.deleteTenant>) {
  // @ts-ignore
  const response = yield call(requestSaga, {
    successNotification: {
      message: "Tenant has been deleted",
    },
    name: deleteTenantRequestName,
    drf: {
      method: "delete",
      url: `/api/v1/tenants/${action.payload.id}/`,
    },
  });

  if (response) {
    // @ts-ignore
    const requestParams = yield select(
      (state: RootState) => state.tenants.requestParams
    );

    yield put(actions.load(requestParams));
    yield call(
      eventGA,
      "Tenants",
      `Delete ${action.payload.id} Tenant`,
      "Button"
    );
  }
}

export const deleteTenantsRequestName = "deleteTenants";

function* deleteTenantsSaga(action: ReturnType<typeof actions.deleteTenants>) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {
      drf: {
        method: "post",
        url: "/api/v1/tenants/delete/",
        data: {
          tenants: action.payload,
        },
      },
      name: deleteTenantsRequestName,
      successNotification: {
        message: `${action.payload.length} tenants has been deleted`,
      },
    });

    if (response) {
      // @ts-ignore
      const requestParams = yield select(
        (state: RootState) => state.tenants.requestParams
      );

      yield put(actions.load(requestParams));
      yield call(
        eventGA,
        "Tenants",
        `Delete ${action.payload.length} Tenants`,
        "Button"
      );
    }
  }
}

export const saga = function* () {
  yield takeLatest(actions.load, loadTenantsSaga);
  yield takeLatest(actions.toggle, toggleTenantSaga);
  yield takeLatest(actions.create, createTenantSaga);
  yield takeLatest(actions.update, updateTenantSaga);
  yield takeLatest(actions.deleteTenant, deleteTenantSaga);
  yield takeLatest(actions.deleteTenants, deleteTenantsSaga);
  yield takeLatest(actions.loadTenant, loadTenantSaga);
  yield takeLatest(actions.loadMore, loadMoreTenantsSaga);
  yield takeLatest(actions.loadUserTenants, loadUserProfileTenantsSaga);
};
