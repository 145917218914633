import {call, put, select, takeLatest} from 'redux-saga/effects';
import {actions, Group, User} from './user.slice';
import requestSaga from '../requestSaga';
import {forwardTo} from '../../history';
import {OpNote} from "../opNotes";
import {loadTenantsRequestName} from "../tenants";
import {RootState} from "../rootReducer";
import {eventGA} from "../../utils/googleAnalytics";


export const loadRoleRequestName = 'loadRole';

function* loadRoleSaga() {
  const resUser: { data: { groups: number[] } } = yield call(requestSaga, {
      name: loadRoleRequestName,
      drf: {
        method: 'get',
        url: '/api/v1/accounts/profile/',
      },
    },
  );
  if (resUser.data) {
    // @ts-ignore
    yield put(actions.loadedRole(resUser.data.groups));

  }
}

export const loadGroupsRequestName = 'loadGroups';

function* loadGroupsSaga() {
  const resGroups: { data: Group[] } = yield call(requestSaga, {
    name: loadRoleRequestName,
    drf: {
      method: 'get',
      url: '/api/v1/groups/',
    },
  });
  if (resGroups.data) {
    yield put(actions.loadedGroups(resGroups.data));
  }
}

export const createUserRequestName = 'createUser';

function* createUserSaga(action: ReturnType<typeof actions.create>) {
  const {...attributes} = action.payload;
  const userData = new FormData();
  userData.append('username', attributes.username)
  userData.append('password', attributes.password)
  userData.append('email', attributes.email)
  userData.append('phone_number', attributes.phone_number)
  userData.append('first_name', attributes.first_name)
  userData.append('last_name', attributes.last_name)
  userData.append('specialty', attributes.specialty)
  userData.append('practice', attributes.practice)
  // @ts-ignore
  userData.append('groups', attributes.groups)
  // @ts-ignore
  userData.append('tenant', attributes.tenant)
  // @ts-ignore
  const response = yield call(requestSaga, {
    drf: {
      url: '/auth/signup/',
      method: 'post',
      data: userData,
    },
    successNotification: {
      message: 'User has been created',
    },
    errorNotification: {
      message: 'All fields are required!'
    },
    name: createUserRequestName,
  });

  if (response) {
    if (action.payload.isTenantAdmin) {
      yield put(actions.created());
    } else {
      yield forwardTo('/users');
    }
      yield call(eventGA, 'User', `Create ${attributes.username} User`, 'Button')
  }
}

export const loadUserProfileRequestName = 'loadUserProfile';
export const loadUserRoleRequestName = 'loadUserRole';

function* loadUserProfileSaga(action: ReturnType<typeof actions.load>) {
  const resUser: { data: User } = yield call(requestSaga, {
    name: loadUserProfileRequestName,
    drf: {
      method: 'get',
      url: action.payload ? `/api/v1/accounts/${action.payload}/` : `/api/v1/accounts/profile/`,
    }
  });

  if (resUser) {
    yield put(
      // @ts-ignore
      actions.loadedProfile(resUser.data));
  }
  yield call(eventGA, 'User', `Load ${action.payload ? action.payload : 'profile'} User`, 'Link')
}

export const updateUserProfileRequestName = 'updateUserProfile';
export const removeUserFromGroupRequestName = 'removeUserFromGroup';
export const addUserToGroupRequestName = 'addUserToGroup';
export const updateUserRequestName = [
  updateUserProfileRequestName,
  removeUserFromGroupRequestName,
  addUserToGroupRequestName,
];

function* updateUserProfileSaga(
  action: ReturnType<typeof actions.updateProfile>
) {
  const {...attributes} = action.payload;
  const userData = new FormData();
  userData.append('username', attributes.username)
  userData.append('password', attributes.password)
  userData.append('email', attributes.email)
  userData.append('phone_number', attributes.phone_number)
  userData.append('first_name', attributes.first_name)
  userData.append('last_name', attributes.last_name)
  userData.append('specialty', attributes.specialty)
  userData.append('practice', attributes.practice)
  // @ts-ignore
  userData.append('groups', attributes.groups)
  // @ts-ignore
  userData.append('tenant', attributes.tenant)
  // @ts-ignore
  const response = yield call(requestSaga, {
    drf: {
      url: '/auth/update/',
      method: 'post',
      data: userData,
    },
    successNotification: {
      message: 'Profile has been updated',
    },
    errorNotification: {
      message: 'All fields are required!'
    },
    name: updateUserProfileRequestName,
  });

  if (response) {
    yield put(actions.updatedProfile(action.payload));
    yield call(eventGA, 'User', `Update ${attributes.username} profile`, 'Button')
  }
}

export const loadTenantIdRequestName = 'loadTenantId';

function* loadTenantIdSaga() {
  const resUser: { data: { tenant: string | null } } = yield call(requestSaga, {
      name: loadRoleRequestName,
      drf: {
        method: 'get',
        url: '/api/v1/accounts/profile/',
      },
    },
  );
  if (resUser.data?.tenant) {
    yield put(actions.loadedTenantId(resUser.data.tenant));
  }
}

export const agreementRequestName = 'agreement';

function* agreementSaga() {
  const resUser: { data: User } = yield call(requestSaga, {
      name: agreementRequestName,
      drf: {
        method: 'post',
        url: '/api/v1/accounts/agreement/',
      },
    },
  );
  if (resUser.data) {
    yield put(actions.setUser(resUser.data));
    yield call(eventGA, 'User', `Agreement`, 'Button')
  }
}

export const saga = function* () {
  yield takeLatest(actions.loadGroups.type, loadGroupsSaga);
  yield takeLatest(actions.loadRole.type, loadRoleSaga);
  yield takeLatest(actions.loadTenantId.type, loadTenantIdSaga);
  yield takeLatest(actions.agreement.type, agreementSaga);
  yield takeLatest(actions.create.type, createUserSaga);
  yield takeLatest(actions.loadProfile.type, loadUserProfileSaga);
  yield takeLatest(actions.updateProfile.type, updateUserProfileSaga);
};
