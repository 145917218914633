import React, {FC, useEffect} from 'react';
import { BreadcrumbsItem } from '../../Components/Breadcrumbs';
import { UserProfile } from '../UserProfile';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import {pageViewGA} from "../../utils/googleAnalytics";

const breadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'My Profile',
  },
];

export const MyProfile: FC = () => {
  const userData = useSelector((state: RootState) => state.user.data);

  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);
  return (
    <UserProfile
      mode="view"
      breadcrumbs={breadcrumbs}
      id={userData?.id}
    />
  );
};
