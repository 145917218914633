import { FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Auth } from 'aws-amplify';
import { NewPassword } from './components/NewPassword';
import { BackToSignInLink } from '../BackToSignInLink';
import { LoadingButton } from '../../../../Components/LoadingButton';
import {customAxios} from "../../../../store/customAxios";

export const ForgotPassword: FC = () => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Reset your password</Typography>
      </Grid>
      {isCodeSent ? (
        <NewPassword username={username} />
      ) : (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={username}
              label="Username *"
              placeholder="Enter your username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormHelperText error>{error}</FormHelperText>
          </Grid>
          <Grid container justify="space-between" item xs={12}>
            <BackToSignInLink />
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              disabled={!username}
              onClick={async (e) => {
                e.preventDefault();
                setLoading(true);
                try {
                    const userData = new FormData();
                    userData.append('username', username);
                    await customAxios.post('/auth/forgot-password/', userData);
                    setIsCodeSent(true);
                } catch (e) {
                  // setError(e.message);
                }
                setLoading(false);
              }}
            >
              Send Code
            </LoadingButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};
