import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Table } from "../../../../Components/Table";
import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  approveCPTCodeRequestName,
  CPTCode,
  rejectCPTCodeRequestName,
} from "../../../../store/cptCodes";
import { AddCPTCodeDialog } from "../AddCPTCodeDialog";
import { OpNoteCptCodeLinkType } from "../../../../API";
import { isProcessingSelector } from "../../../../store/requests";
import { Permission, usePermission } from "../../../../Components/Permission";
import { ADMINS, APPROVERS, Roles } from "../../../../store/user";
import { Check, Clear } from "@material-ui/icons";
import { ConfirmationDialog } from "../../../../Components/ConfirmationDialog";
import { forwardTo } from "../../../../history";
import ReactPaginate from "react-paginate";

export type Props = {
  opNoteId: string;
  codes: CPTCode[];
  rowLimit: number;
  selected: Set<string>;
  setSelected: any;
}
export const OpNoteCPTCodes: FC<Props> = memo((props) => {
  const { codes, opNoteId, rowLimit, selected, setSelected } = props;
  const dispatch = useDispatch();

  const isProcessing = useSelector(
    isProcessingSelector([approveCPTCodeRequestName, rejectCPTCodeRequestName])
  );
  const isCoder = usePermission([Roles.Coder]);
  const [isAddCodeDialogOpen, setIsAddCodeDialogOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const allSelectableIds = useMemo(() => {
    return codes.reduce<string[]>((res, code) => {
      return [...res, code.code];
    }, []);
  }, [codes]);
  const rolesWithActions = [...ADMINS, ...APPROVERS, Roles.Coder];
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSelectAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(new Set(e.target.checked ? allSelectableIds : []));
    },
    [setSelected, allSelectableIds]
  );

  const handleDialogClose = useCallback(
    () => setShowConfirmation(false),
    [setShowConfirmation]
  );
  return (
    <>
      <Grid
        item
        xs={12}
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        <div style={{ overflow: "auto" }}>
          <Table stickyHeader>
            <colgroup>
              <Permission roles={rolesWithActions}>
                <col width={30} />
              </Permission>
              <col width={90} />
              <col width={300} />
              <col width={100} />
              <Permission roles={rolesWithActions}>
                <col width={30} />
              </Permission>
            </colgroup>
            <TableHead>
              <TableRow>
                <Permission roles={rolesWithActions}>
                  <TableCell>
                    {codes.length > 0 && (
                      <Checkbox
                        color="primary"
                        checked={selected.size === allSelectableIds.length}
                        onChange={handleSelectAllChange}
                      />
                    )}
                  </TableCell>
                </Permission>
                <TableCell>CPT Code</TableCell>
                <TableCell>CPT Code Description</TableCell>
                <TableCell>By</TableCell>
                <Permission roles={rolesWithActions}>
                  <TableCell />
                </Permission>
              </TableRow>
            </TableHead>
            <TableBody>
              {codes
                .slice(0, rowLimit)
                .map(({ code, description, linkType, approvedBy }) => {
                  return (
                    <TableRow key={code}>
                      <Permission roles={rolesWithActions}>
                        <TableCell>
                          <Checkbox
                            checked={selected.has(code)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.checked) {
                                selected.add(code);
                              } else {
                                selected.delete(code);
                              }

                              setSelected(new Set(selected));
                            }}
                            color="primary"
                          />
                        </TableCell>
                      </Permission>
                      <TableCell>{code}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>{approvedBy}</TableCell>
                      <Permission roles={rolesWithActions}>
                        <TableCell>
                          <Grid container wrap="nowrap" justify="flex-end">
                            <IconButton
                              style={{ marginRight: 16 }}
                              color="primary"
                              disabled={
                                isProcessing ||
                                linkType === OpNoteCptCodeLinkType.APPROVED
                              }
                              onClick={() => {
                                setIsEditable(true);
                                dispatch(
                                  actions.approve({
                                    opNoteId,
                                    cptCodeCodes: [code],
                                  })
                                );
                              }}
                              title="Approve"
                            >
                              <Check />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              disabled={
                                isProcessing ||
                                linkType === OpNoteCptCodeLinkType.REJECTED
                              }
                              onClick={() => {
                                setIsEditable(true);
                                dispatch(
                                  actions.reject({
                                    opNoteId,
                                    cptCodeCodes: [code],
                                  })
                                );
                              }}
                              title="Reject"
                            >
                              <Clear />
                            </IconButton>
                          </Grid>
                        </TableCell>
                      </Permission>
                    </TableRow>
                  );
                }) ?? (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No CPT Codes
                  </TableCell>
                </TableRow>
              )}

            </TableBody>
          </Table>
        </div>
      </Grid> 
      <Grid container>
        {!codes.length && (
          <Grid container justify="center" item xs={12}>
            <Typography>No Data</Typography>
          </Grid>
        )}
        <Permission roles={rolesWithActions}>
          <Grid item xs={6}>
            <Button
              style={{ marginRight: 16, marginBottom: 8 }}
              color="primary"
              size="small"
              variant="contained"
              disabled={selected.size === 0 || isProcessing}
              onClick={() => {
                setSelected(new Set());
                setIsEditable(true);
                dispatch(
                  actions.approve({
                    opNoteId,
                    cptCodeCodes: Array.from(selected),
                  })
                );
              }}
            >
              Approve Selected
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              style={{ marginBottom: 8 }}
              disabled={selected.size === 0 || isProcessing}
              onClick={() => {
                setSelected(new Set());
                setIsEditable(true);
                dispatch(
                  actions.reject({
                    opNoteId,
                    cptCodeCodes: Array.from(selected),
                  })
                );
              }}
            >
              Reject Selected
            </Button>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              style={{ marginRight: 16, marginBottom: 8 }}
              onClick={useCallback(() => {
                setIsAddCodeDialogOpen(true);
                setIsEditable(true);
              }, [setIsAddCodeDialogOpen])}
            >
              Add CPT Code
            </Button>
            {!isCoder && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={
                  Array.from(
                    codes.filter(
                      (code) => code.linkType === OpNoteCptCodeLinkType.APPROVED
                    )
                  ).length === 0
                }
                style={{ marginRight: 16, marginBottom: 8 }}
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                Train
              </Button>
            )}
          </Grid>
          <AddCPTCodeDialog
            opNoteId={opNoteId}
            open={isAddCodeDialogOpen}
            assignedCodes={[...codes]}
            onClose={useCallback(() => {
              setIsAddCodeDialogOpen(false);
            }, [setIsAddCodeDialogOpen])}
          />
          <ConfirmationDialog
            text={`You are submitting the note with the following codes: ${Array.from(
              codes.filter(
                (code) => code.linkType === OpNoteCptCodeLinkType.APPROVED
              ),
              (code) => code.code
            )}`}
            open={showConfirmation}
            onClose={handleDialogClose}
            onSubmit={() => {
              dispatch(
                actions.submit({
                  opNoteId,
                  cptCodeCodes: Array.from(
                    codes.filter(
                      (code) => code.linkType === OpNoteCptCodeLinkType.APPROVED
                    ),
                    (code) => code.code
                  ),
                })
              );
              setIsEditable(false);
              setShowConfirmation(false);
              forwardTo("/opnotes");
            }}
          />
        </Permission>
      </Grid>
    </>
  );
});
