import React, { FC, useEffect } from 'react';
import { Page } from '../../layout/Page';
import {updateUserRequestName} from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../store/requests';
import { actions } from '../../store/user';
import { Breadcrumbs, BreadcrumbsItem } from '../../Components/Breadcrumbs';
import { UserProfileForm } from '../../Components/UserProfileForm';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import { Grid } from '@material-ui/core';
import { NotFound } from '../NotFound';
import {pageViewGA} from "../../utils/googleAnalytics";

const defaultBreadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'Users',
    href: '/users',
  },
  {
    title: 'User Profile',
  },
];

export type Props = {
  id?: string;
  mode?: 'create' | 'edit' | 'view';
  breadcrumbs?: BreadcrumbsItem[];
};

export const UserProfile: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { breadcrumbs = defaultBreadcrumbs, id: userId, mode = 'edit' } = props;
  const { id: paramsId } = useParams<{ id?: string }>();
  const id = userId || paramsId || '';
  const data = useSelector((state: RootState) => state.user.profile);
  const isSaving = useSelector(isProcessingSelector(updateUserRequestName));

  useEffect(() => {
    dispatch(actions.loadProfile(id));
    pageViewGA(window.location.pathname + window.location.search);
  }, [id, dispatch]);

  if (!data) {
    return <NotFound />;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
        <UserProfileForm
          initialValues={data}
          mode={mode}
          disabled={isSaving}
          onSubmit={(values) => {
            if (values) {
              dispatch(actions.updateProfile(values));
            }
          }}
        />
    </Page>
  );
};
