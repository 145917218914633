import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import usePrevious from "../../../../hooks/usePrevious";
import { SearchableSortDirection } from "../../../../API";
import { Filters, Props as FiltersProps } from "../../../Filters";
import {
  CircularProgress,
  Grid,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { TextFilter } from "../../../Filters/TextFilter";
import { FilterItem } from "../../../Filters/FilterItem";
import { Sort, Table, TableFiltersColumn } from "../../Table";
import { FormikValues } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, BreadcrumbsItem } from "../../../Breadcrumbs";
import Pagination from "../../../Pagination/Pagination";
import { RootState } from "../../../../store/rootReducer";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

export type TableFiltersContentProps<T> = {
  columns: TableFiltersColumn[];
  initialSort?: Sort;
  search?: boolean;
  actions?: ReactElement;
  columnsData: {
    [key: string]: any;
  }[];
  singleColumnFilter?: boolean;
  loading?: boolean;
  colGroup?: ReactElement;
  hasMore: boolean;
  loadMore: (value: Object, replace?: boolean) => void;
  loadingMore: boolean;
  breadcrumbs?: BreadcrumbsItem[];
  onSubmit: (values: FormikValues, sort?: Sort) => void;
} & Omit<FiltersProps<T>, "onSubmit">;

const switchSortDirection = (currentDir: SearchableSortDirection) => {
  return currentDir === SearchableSortDirection.asc
    ? SearchableSortDirection.desc
    : SearchableSortDirection.asc;
};

const useStyles = makeStyles({
  content: {
    overflow: "auto",
  },
  breadCrumb: {
    alignItems: "center",
    paddingTop: "6px",
    paddingBottom: "15px",
  },
});

export function TableFiltersContent<T>(props: TableFiltersContentProps<T>) {
  const {
    columns,
    columnsData,
    actions,
    search,
    colGroup,
    loading,
    initialValues = {},
    initialSort,
    onSubmit,
    hasMore,
    loadMore,
    loadingMore,
    singleColumnFilter,
    breadcrumbs,
  } = props;
  const styles = useStyles();
  const [sort, setSort] = useState<Sort | undefined>(initialSort);
  const [values, setValues] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const previousSort = usePrevious(sort);
  const previousValues = usePrevious(values);
  const [prevFilterVal, setPrevFilterVal] = useState("");

  const state = useSelector((state: RootState) => state);
  const [storeCount, setStoreCount] = useState<any>();

  const path = window.location.pathname;
  const cptNotesCount = state?.cptCodes?.listCodes?.count;
  const opNotesCount = state.opNotes.data.listOpNotes?.count;
  const usersCount = state.users.data.listUsers?.count;
  const listTenantsCount = state.tenants.data.listTenants?.count;

  const [componentRenderer, setComponentRenderer] = useState(true);

  useEffect(() => {
    if (path === "/cptcodes") {
      setStoreCount(cptNotesCount);
    } else if (path === "/opnotes" || path === "/opnotes/") {
      setStoreCount(opNotesCount);
    } else if (path === "/users") {
      setStoreCount(usersCount);
    } else if (path === "/tenants") {
      setStoreCount(listTenantsCount);
    } else setStoreCount(0);
  }, [cptNotesCount, opNotesCount, usersCount, listTenantsCount]);

  const localStorageRows = localStorage.getItem("pageRows");
  const [pageRows, setPageRows] = useState<any>(
    localStorageRows ? localStorageRows : 10
  );

  const handleSort = useCallback(
    (field: string) => {
      setSort((value) => ({
        field,
        dir:
          value?.field === field
            ? switchSortDirection(value.dir)
            : SearchableSortDirection.asc,
      }));
    },
    [setSort]
  );

  useEffect(() => {
    if (
      (previousSort && sort !== previousSort) ||
      (previousValues && values !== previousValues)
    ) {
      setComponentRenderer(false);
      // onSubmit(values, sort);
      paginate(1);
      loadMore(
        {
          currentPage: 1,
          pageRows: pageRows,
          sort,
          filter: prevFilterVal ? prevFilterVal : values,
        },
        previousSort !== sort
      );
    }
  }, [sort, previousSort, values, previousValues, onSubmit]);
  const paginate = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    await loadMore({
      currentPage: pageNumber,
      pageRows: pageRows,
      sort,
      filter: prevFilterVal ? prevFilterVal : values,
    });
    setComponentRenderer(true);
  };

  const pageRowsHandler = (e: number) => {
    setPageRows(e);
    localStorage.setItem("pageRows", String(e));
    loadMore({
      currentPage: currentPage,
      pageRows: e,
      sort,
      filter: prevFilterVal ? prevFilterVal : values,
    });
  };

  return (
    <Filters
      singleColumnFilter={singleColumnFilter}
      initialValues={{ search: "", ...initialValues }}
      onSubmit={setValues}
    >
      <Grid container item xs={12} className={styles.breadCrumb}>
        {breadcrumbs && (
          <Grid container item xs={6}>
            <Breadcrumbs items={breadcrumbs} />
          </Grid>
        )}

        {actions && (
          <Grid
            justify="flex-end"
            container
            item
            xs={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            {!!storeCount && (
              <div style={{ marginRight: "100px" }}>
                <span> Row per page</span> &nbsp;
                <select
                  style={{
                    padding: "2px",
                    background: "#fff",
                    borderRadius: "7px",
                    width: "58px",
                  }}
                  value={pageRows}
                  onChange={(e) => {
                    pageRowsHandler(Number(e.target.value));
                  }}
                >
                  {[10, 25, 50, 100].map((pageRows) => (
                    <option key={pageRows} value={pageRows}>
                      {pageRows}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <span>{actions}</span>
          </Grid>
        )}
      </Grid>
      <Grid className={styles.content} item xs={12}>
        <Table stickyHeader>
          {colGroup}
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell key={index}>
                  {col.sort ? (
                    <TableSortLabel
                      direction={sort?.dir.toLowerCase() as any}
                      active={sort?.field === col.name}
                      onClick={() => handleSort(col.name)}
                      style={{ minWidth: "82px" }}
                    >
                      {col.label}
                    </TableSortLabel>
                  ) : (
                    col.label
                  )}
                  {col.filter && (
                    <FilterItem
                      options={col.filter}
                      setPrevFilterVal={setPrevFilterVal}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Grid container item xs={12} justify="center">
                    <span style={{ minHeight: "50vh" }}>
                      <CircularProgress />
                    </span>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              columnsData.map((data: any, index: number) => (
                <TableRow key={index}>
                  {columns.map((col) => {
                    const value = data[col.name];
                    let content = value;
                    if (col.format) {
                      content = col.format(value);
                    } else if (col.render) {
                      content = col.render(data);
                    }
                    return (
                      <TableCell key={`${index}-${col.name}`}>
                        {content}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#f3f2f2",
            marginTop: "50px",
          }}
        >
          {!!storeCount && !!componentRenderer && (
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(event) => paginate(event.selected + 1)}
              pageRangeDisplayed={5}
              pageCount={storeCount / pageRows}
              previousLabel="< "
              marginPagesDisplayed={2}
              containerClassName={"paginationReact"}
              previousLinkClassName={"paginationReact__link"}
              nextLinkClassName={"paginationReact__link"}
              disabledClassName={"paginationReact__link--disabled"}
              activeClassName={"paginationReact__link--active"}
            />
          )}
        </div>
      </Grid>
    </Filters>
  );
}
