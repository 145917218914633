import {Button, Grid} from '@material-ui/core';
import {Form, Formik} from 'formik';
import React, {FC, useEffect} from 'react';
import {MultipleFileUploadField} from './upload';
import {Page} from '../../layout/Page';
import {Breadcrumbs, BreadcrumbsItem} from "../../Components/Breadcrumbs";
import history from '../../history';
import {useHistory} from 'react-router-dom';
import CreateIcon from "@material-ui/icons/Create";
import {buttonsUseStyles} from "../../layout/Styles/Buttons";
import {pageViewGA} from "../../utils/googleAnalytics";

const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/opnotes',
    title: 'OpNotes',
  },
  {
    title: 'Upload new OpNote',
  },
];

export const UploadOpNote: FC = () => {
  const {push} = useHistory();
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);
  return (
    <Page>
      <Grid item xs={12}>
        <Formik
          initialValues={{files: []}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            // setTimeout(() => {
            //   history.push('/opnotes/');
            // }, 5000);
          }}
        >
          {({dirty, isSubmitting}) => (
            <Form>
              <Grid container spacing={2}>
                <Grid container item xs={12} justify="space-between">
                  <Breadcrumbs items={breadcrumbs}/>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => push('/opnotes/new')}
                      startIcon={<CreateIcon/>}
                      className={buttonsUseStyles().main}
                    >
                      Add OpNote
                    </Button>
                    <Button
                      variant="contained"
                      color="default"
                      disabled={!dirty || isSubmitting}
                      className={buttonsUseStyles().main}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <MultipleFileUploadField name="files" isSubmitting={isSubmitting}/>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Page>
  )
};