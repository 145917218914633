import axios from "axios";

export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

customAxios.interceptors.response.use(
  ( res) => {
    let EXP = localStorage.getItem("expiresOn");
    let REFRESH_TOKEN = localStorage.getItem("refreshToken");
    let REFRESH_TIMEOUT = 5 * 60 * 1000;
    if ( EXP && REFRESH_TOKEN ) {
      // @ts-ignore
      if ( EXP * 1000 - Date.now() <= REFRESH_TIMEOUT) {
        const userData = new FormData();
        // @ts-ignore
        userData.append('refresh_token', localStorage.getItem("refreshToken"))
        customAxios
          .post("/auth/refresh/", userData)
          .then(res => {
            if (res.data && res.data.access_token && res.data.id_token && res.data.exp) {
                localStorage.setItem("accessToken", res.data.access_token);
                localStorage.setItem("expiresOn", String(res.data.exp));
                localStorage.setItem("idToken", res.data.id_token);
              }
          });
      }
    }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);