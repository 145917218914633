import { BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { FormikValues } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { usePermission } from "../../Components/Permission";
import { ADMINS, APPROVERS, SUPERS } from "../../store/user";
import { isProcessingSelector } from "../../store/requests";
import { actions, loadMoreCPTCodesRequestName } from "../../store/cptCodes";
import useUserRoleChecker from "../../hooks/useUserRoleChecker";
import { forwardTo } from "../../history";
import { Page } from "../../layout/Page";
import { Button, Checkbox, Grid, Typography } from "@material-ui/core";
import { Sort, TableFilters, TableFiltersColumn } from "../../Components/Table";
import formatDate from "../../utils/formatDate";
import CreateIcon from "@material-ui/icons/Create";
import { buttonsUseStyles } from "../../layout/Styles/Buttons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CPTCode, loadCPTCodesRequestName } from "../../store/cptCodes";
import { DeleteCptCodes } from "./components/DeleteCptCodes";
import { pageViewGA } from "../../utils/googleAnalytics";
import { SearchableSortDirection } from "../../API";

const initialSort = {
  field: "created_at",
  dir: SearchableSortDirection.desc,
};

const getFilter = (values: FormikValues, sort: Sort | undefined) => {
  const sortStringValue = `${
    sort ? `ordering=${sort.dir === "desc" ? "-" : ""}${sort.field}` : ""
  }`;
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : "";
  return value ? `${fieldName}=${value}&${sortStringValue}` : {sort : sort};

};

const useStyles = makeStyles({
  text: {
    maxHeight: 70,
    overflow: "hidden",
  },
});

export const CptCodes: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const storeItems = useSelector(
    (state: RootState) => state.cptCodes.listCodes?.items ?? []
  );
  const storeCount = useSelector(
    (state: RootState) => state.cptCodes.listCodes?.count ?? 0
  );
  const isCreatedByVisible = usePermission([...ADMINS, ...APPROVERS]);
  const isLoading = useSelector(
    isProcessingSelector([loadCPTCodesRequestName])
  );
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreCPTCodesRequestName])
  );
  const isSuperUser = useUserRoleChecker(SUPERS);
  const hasMore = useSelector(
    (state: RootState) => !!(state.cptCodes.listCodes?.next ?? null)
  );
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const allSelectableIds = useMemo(() => {
    return storeItems.reduce<string[]>((res, cptCode) => {
      return [...res, cptCode.id];
    }, []);
  }, [storeItems]);
  const handleSelectAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(new Set(e.target.checked ? allSelectableIds : []));
    },
    [setSelected, allSelectableIds]
  );
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: "CptCodes",
      href: "/cptcodes",
    },
    {
      title: `${storeCount} items`,
    },
  ];
  const handleAdd = useCallback(() => {
    forwardTo("/cptcodes/new");
  }, [forwardTo]);
  const handleUpload = useCallback(() => {
    forwardTo("/cptcodes/upload");
  }, [forwardTo]);

  const localStorageRows = localStorage.getItem("pageRows");
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);

  // @ts-ignore
  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          singleColumnFilter
          initialSort={initialSort}
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          breadcrumbs={breadcrumbs}
          columns={[
            {
              label: (
                <Checkbox
                  color="primary"
                  checked={selected.size === allSelectableIds.length}
                  onChange={handleSelectAllChange}
                />
              ),
              name: "",
              render: (data: CPTCode) => (
                <Checkbox
                  checked={selected.has(data.code)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      selected.add(data.code);
                    } else {
                      selected.delete(data.code);
                    }
                    setSelected(new Set(selected));
                  }}
                  color="primary"
                />
              ),
            },
            {
              label: "Code",
              name: "code",
              filter: {
                name: "code",
              },
              sort: true,
            },
            {
              label: "Created",
              name: "created_at",
              format: formatDate,
              filter: {
                name: "created",
                type: "date",
              },
              sort: true,
            },
            ...(isCreatedByVisible
              ? ([
                  {
                    label: "Created By",
                    name: "created_by",
                    filter: {
                      name: "created_by",
                    },
                    sort: true,
                  },
                ] as TableFiltersColumn[])
              : []),
            {
              label: "Updated",
              name: "updated_at",
              format: formatDate,
              filter: {
                name: "updated",
                type: "date",
              },
              sort: true,
            },
            {
              label: "Updated By",
              name: "updated_by",
              filter: {
                name: "updated_by",
              },
              sort: true,
            },
            {
              label: "Description",
              name: "description",
              filter: {
                name: "description",
              },
              sort: true,
              render: (data: CPTCode) => (
                // @ts-ignore
                <Typography className={styles.text} title={data.description}>
                  {data.description}
                </Typography>
              ),
            },
          ]}
          columnsData={storeItems}
          onSubmit={useCallback(
            (values, sort) => {
              dispatch(actions.load(getFilter(values, sort)));
            },
            [dispatch]
          )}
          onSubmitMore={useCallback(
            (value: any) => {
              dispatch(actions.load(value));
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAdd}
                startIcon={<CreateIcon />}
                className={buttonsUseStyles().main}
              >
                Add CptCode
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={handleUpload}
                startIcon={<CloudUploadIcon />}
                className={buttonsUseStyles().main}
              >
                Upload
              </Button>
            </div>
          }
          colGroup={
            <colgroup>
              <col width={10} />
              <col width={130} />
              <col width={150} />
              <col width={170} />
              <col width={150} />
              <col width={170} />
            </colgroup>
          }
        />
        <Grid container>
          <Grid container item xs={6}>
            <DeleteCptCodes selected={selected} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
