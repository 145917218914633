import { takeLatest, call, put, select } from "redux-saga/effects";
import { actions } from "./cptCodes.slice";
import requestSaga from "../requestSaga";
import { CPTCode } from "../cptCodes";
import { RootState } from "../rootReducer";
import { Roles, User } from "../user";
import { OpNote } from "../opNotes";
import { forwardTo } from "../../history";
import { OpNoteCptCodeLinkType } from "../../API";
import { eventGA } from "../../utils/googleAnalytics";

type CptCodesResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: CPTCode[];
  };
};

const formatCptCodes = (res: CptCodesResponse) => {
  if (!res) {
    return null;
  }

  return {
    ...res.data.results,
    items: res.data.results.map((item) => ({
      ...item,
    })),
    count: res.data.count,
    next: res.data.next,
    previous: res.data.previous,
  };
};

export const createCPTCodeRequestName = "createCPTCode";

function* createCPTCodeSaga(action: ReturnType<typeof actions.create>) {
  const response: { data: CPTCode } = yield call(requestSaga, {
    name: createCPTCodeRequestName,
    drf: {
      method: "post",
      url: `/api/v1/cpt-codes/`,
      data: {
        code: action.payload.code,
        description: action.payload.description,
      },
    },
    errorNotification: {
      message: "Something went wrong. Please try again later.",
    },
  });

  if (response && response.data) {
    yield forwardTo(`/cptcodes`);
    yield call(
      eventGA,
      "CptCodes",
      `Create ${action.payload.code} CptCode`,
      "Button"
    );
  }
}

export const loadCPTCodesRequestName = "loadCPTCodesRequest";

function* loadCPTCodesSaga(action: ReturnType<typeof actions.load>) {
  const { pageRows, sort, page } = action?.payload;
  const localStorageRows = localStorage.getItem("pageRows");
  const filterParam = action?.payload?.filter
    ? Object.entries(action?.payload?.filter).reduce(
        (prev, curr) =>
          (prev += curr[1] ? `${curr[0]}=${String(curr[1])}&` : ""),
        ""
      )
    : "";
  const response: CptCodesResponse = yield call(requestSaga, {
    name: loadCPTCodesRequestName,
    drf: {
      method: "get",
      url: action.payload
        ? `/api/v1/cpt-codes/?${filterParam}ordering=${
            sort?.dir === "asc" ? sort?.field : `-${sort?.field}`
          }&page=${page || 1}&per_page=${pageRows || localStorageRows || 10}`
        : `/api/v1/cpt-codes/`,
    },
  });

  if (response && response.data) {
    // @ts-ignore
    yield put(actions.loaded(formatCptCodes(response)));
    yield call(
      eventGA,
      "CptCodes",
      `Load ${pageRows || localStorageRows || 10} CptCodes`,
      "Table"
    );
  }
}

export const loadMoreCPTCodesRequestName = "loadMoreCPTCodes";

function* loadMoreCPTCodesSaga() {
  // @ts-ignore
  const nextUrl = yield select(
    (state: RootState) => state.cptCodes.listCodes?.next ?? null
  );
  const localStorageRows = localStorage.getItem("pageRows");

  const response: CptCodesResponse = yield call(requestSaga, {
    name: loadMoreCPTCodesRequestName,
    drf: {
      method: "get",
      url: nextUrl,
      // perPage: localStorageRows || 10,
    },
  });

  if (response) {
    // @ts-ignore
    yield put(actions.loadedMore(formatCptCodes(response)));
    yield call(
      eventGA,
      "CptCodes",
      `Load ${localStorageRows} CptCodes`,
      "Table"
    );
  }
}

export const searchCPTCodesRequestName = "searchCPTCodesRequest";

function* searchCPTCodesSaga(action: ReturnType<typeof actions.search>) {
  let response: CptCodesResponse;
  response = yield call(requestSaga, {
    name: searchCPTCodesRequestName,
    drf: {
      method: "get",
      url: `/api/v1/cpt-codes/?code=${action.payload}`,
      // perPage: localStorageRows || 10,
    },
  });
  if (response.data.count === 0) {
    response = yield call(requestSaga, {
      name: searchCPTCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/cpt-codes/?description=${action.payload}`,
        // perPage: localStorageRows || 10,
      },
    });
  }
  if (response && response.data) {
    yield put(actions.searched(response.data.results));
  }
}

export const loadApprovedSuggestedOpNoteCptCodesRequestName =
  "loadApprovedSuggestedOpNoteCptCodes";

function* loadApprovedSuggestedOpNoteCptCodesSaga(
  action: ReturnType<typeof actions.loadOpNoteApprovedSuggestedCodes>
) {
  const limit = action.payload.cptSuggestionSize;
  const mode = action.payload.cptSuggestionMode;
  const opNoteId = action.payload.opNoteId;

  let resApproved: {
    data: {
      id: string;
      cpt_code: CPTCode;
      approved_by: string;
      approved_at: string;
    }[];
  };
  let resAssigned: {
    data: {
      id: string;
      cpt_code: CPTCode;
      assigned_by: string;
      assigned_at: string;
    }[];
  };
  let resCoded: {
    data: {
      id: string;
      cpt_code: CPTCode;
      coded_by: string;
      coded_at: string;
    }[];
  };
  let resRejected: {
    data: {
      id: string;
      cpt_code: CPTCode;
      rejected_by: string;
      rejected_at: string;
    }[];
  };
  let resSuggested: { data: { cpt_code: CPTCode }[] };

  if (mode === "Approved") {
    resSuggested = { data: [] };
    resAssigned = { data: [] };
    resCoded = { data: [] };
    resRejected = { data: [] };
    resApproved = yield call(requestSaga, {
      name: loadApprovedSuggestedOpNoteCptCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/op-notes/${opNoteId}/cpt-codes-approved/`,
      },
    });
  } else if (mode === "Assigned") {
    resSuggested = { data: [] };
    resApproved = { data: [] };
    resCoded = { data: [] };
    resRejected = { data: [] };
    resAssigned = yield call(requestSaga, {
      name: loadApprovedSuggestedOpNoteCptCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/op-notes/${opNoteId}/cpt-codes-assigned/`,
      },
    });
  } else if (mode === "Coded") {
    resSuggested = { data: [] };
    resApproved = { data: [] };
    resAssigned = { data: [] };
    resRejected = { data: [] };
    resCoded = yield call(requestSaga, {
      name: loadApprovedSuggestedOpNoteCptCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/op-notes/${opNoteId}/cpt-codes-coded/`,
      },
    });
  } else if (mode === "Rejected") {
    resSuggested = { data: [] };
    resApproved = { data: [] };
    resAssigned = { data: [] };
    resCoded = { data: [] };
    resRejected = yield call(requestSaga, {
      name: loadApprovedSuggestedOpNoteCptCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/op-notes/${opNoteId}/cpt-codes-rejected/`,
      },
    });
  } else {
    resAssigned = { data: [] };
    resCoded = { data: [] };
    resRejected = { data: [] };
    resApproved = yield call(requestSaga, {
      name: loadApprovedSuggestedOpNoteCptCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/op-notes/${opNoteId}/cpt-codes-approved/`,
      },
    });
    resSuggested = yield call(requestSaga, {
      name: loadApprovedSuggestedOpNoteCptCodesRequestName,
      drf: {
        method: "get",
        url: `/api/v1/op-notes/${opNoteId}/cpt-codes-suggested/`,
      },
    });
  }

  yield put(
    actions.loadedOpNoteApprovedSuggestedCodes(
      (resApproved.data
        ? resApproved.data.map((elem) =>
            Object.assign(elem.cpt_code, {
              approvedBy: elem.approved_by,
              approvedAt: elem.approved_at,
              linkType: OpNoteCptCodeLinkType.APPROVED,
            })
          )
        : []
      )
        .concat(
          // @ts-ignore
          resSuggested.data
            ? resSuggested.data.map((elem) =>
                Object.assign(elem.cpt_code, {
                  linkType: OpNoteCptCodeLinkType.SUGGESTED,
                })
              )
            : []
        )
        .concat(
          resAssigned.data
            ? resAssigned.data.map((elem) =>
                Object.assign(elem.cpt_code, {
                  approvedBy: elem.assigned_by,
                  approvedAt: elem.assigned_at,
                  linkType: OpNoteCptCodeLinkType.APPROVED,
                })
              )
            : []
        )
        .concat(
          resCoded.data
            ? resCoded.data.map((elem) =>
                Object.assign(elem.cpt_code, {
                  approvedBy: elem.coded_by,
                  approvedAt: elem.coded_at,
                  linkType: OpNoteCptCodeLinkType.APPROVED,
                })
              )
            : []
        )
        .concat(
          resRejected.data
            ? resRejected.data.map((elem) =>
                Object.assign(elem.cpt_code, {
                  approvedBy: elem.rejected_by,
                  approvedAt: elem.rejected_at,
                  linkType: OpNoteCptCodeLinkType.REJECTED,
                })
              )
            : []
        )
        // .slice(0, limit)
    )
  );
}

//   if (resSuggested && resApproved) {
//     if (resSuggested.data) {
//       if (resApproved.data) {
//         yield put(
//           actions.loadedOpNoteApprovedSuggestedCodes(
//             // @ts-ignore
//             resApproved.data.map(elem => Object.assign(elem.cpt_code, {
//               approvedBy: elem.approved_by,
//               approvedAt: elem.approved_at,
//               linkType: OpNoteCptCodeLinkType.APPROVED
//               // @ts-ignore
//             })).concat(resSuggested.data.map(elem => Object.assign(elem.cpt_code, {linkType: OpNoteCptCodeLinkType.SUGGESTED}))).slice(0, limit)
//           )
//         );
//       } else {
//         yield put(
//           actions.loadedOpNoteApprovedSuggestedCodes(
//             // @ts-ignore
//             resSuggested.data.map(elem => Object.assign(elem.cpt_code, {linkType: OpNoteCptCodeLinkType.SUGGESTED})).slice(0, limit)
//           )
//         );
//       }
//     } else {
//       yield put(
//         actions.loadedOpNoteApprovedSuggestedCodes(
//           // @ts-ignore
//           resApproved.data.map(elem => Object.assign(elem.cpt_code, {
//             approvedBy: elem.approved_by,
//             approvedAt: elem.approved_at,
//             linkType: OpNoteCptCodeLinkType.APPROVED
//                   // @ts-ignore
//           })).slice(0, limit)
//         )
//       );
//     }
//   }
// }

export const approveCPTCodeRequestName = "approveCPTCode";

function* approveCPTCodeSaga(action: ReturnType<typeof actions.approve>) {
  yield call(requestSaga, {
    name: loadApprovedSuggestedOpNoteCptCodesRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-approved/`,
      data: {
        cpt_codes: action.payload.cptCodeCodes,
      },
    },
    successNotification: {
      message: "Approved successfully",
    },
  });

  const user: User = yield select((state: RootState) => state.user.data);

  yield put(
    actions.approved({
      approver: user?.username ?? "",
      codeIds: action.payload.cptCodeCodes || [],
    })
  );
  yield call(
    eventGA,
    "CptCodes",
    `Approve ${action.payload.cptCodeCodes} CptCodes`,
    "Table"
  );
}

export const submitCPTCodeRequestName = "sumbitCPTCode";

function* submitCPTCodeSaga(action: ReturnType<typeof actions.submit>) {
  yield call(requestSaga, {
    name: submitCPTCodeRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-submitted/`,
      data: {
        cpt_codes: action.payload.cptCodeCodes,
      },
    },
    successNotification: {
      message: "Submitted",
    },
  });
  yield call(
    eventGA,
    "CptCodes",
    `Submit ${action.payload.cptCodeCodes} CptCodes`,
    "Table"
  );
}

export const rejectCPTCodeRequestName = "rejectCPTCode";

function* rejectCPTCodeSaga(action: ReturnType<typeof actions.reject>) {
  yield call(requestSaga, {
    name: rejectCPTCodeRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-rejected/`,
      data: {
        cpt_codes: action.payload.cptCodeCodes,
      },
    },
    successNotification: {
      message: "Rejected successfully",
    },
  });

  yield put(actions.rejected(action.payload.cptCodeCodes || []));
  yield call(
    eventGA,
    "CptCodes",
    `Reject ${action.payload.cptCodeCodes} CptCodes`,
    "Table"
  );
}

export const assignCPTCodeRequestName = "assignCPTCode";

function* assignCPTCodeSaga(action: ReturnType<typeof actions.assign>) {
  yield call(requestSaga, {
    name: assignCPTCodeRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-assigned/`,
      data: {
        cpt_codes: action.payload.cptCodes.map((item) => item.code),
      },
    },
    successNotification: {
      message: "Assigned successfully",
    },
  });

  yield call(requestSaga, {
    name: loadApprovedSuggestedOpNoteCptCodesRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-approved/`,
      data: {
        cpt_codes: action.payload.cptCodes.map((item) => item.code),
      },
    },
  });

  const user: User = yield select((state: RootState) => state.user.data);

  yield put(
    actions.assigned({
      codes: action.payload.cptCodes,
      approver: user?.username ?? "",
    })
  );
  yield call(
    eventGA,
    "CptCodes",
    `Assign ${action.payload.cptCodes.map((item) => item.code)} CptCodes`,
    "Table"
  );
}

export const codeCPTCodeRequestName = "codeCPTCode";

function* codeCPTCodeSaga(action: ReturnType<typeof actions.code>) {
  yield call(requestSaga, {
    name: codeCPTCodeRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-coded/`,
      data: {
        cpt_codes: action.payload.cptCodes.map((item) => item.code),
      },
    },
    successNotification: {
      message: "Coded successfully",
    },
  });

  yield call(requestSaga, {
    name: loadApprovedSuggestedOpNoteCptCodesRequestName,
    drf: {
      method: "post",
      url: `/api/v1/op-notes/${action.payload.opNoteId}/cpt-codes-approved/`,
      data: {
        cpt_codes: action.payload.cptCodes.map((item) => item.code),
      },
    },
  });

  const user: User = yield select((state: RootState) => state.user.data);

  yield put(
    actions.coded({
      codes: action.payload.cptCodes,
      approver: user?.username ?? "",
    })
  );
  yield call(
    eventGA,
    "CptCodes",
    `Code ${action.payload.cptCodes.map((item) => item.code)} CptCodes`,
    "Table"
  );
}

export const loadSuggestionModesRequestName = "loadSuggestionModesRequest";

function* loadSuggestionModesSaga() {
  const userRole: string[] = yield select(
    (state: RootState) => state.user.role
  );
  if (userRole.some((item) => Roles.Coder.includes(item))) {
    yield put(actions.loadedSuggestionModes(["Approved"]));
  } else if (userRole.some((item) => Roles.DataEntry.includes(item))) {
    yield put(actions.loadedSuggestionModes(["Suggested", "Approved"]));
  } else {
    yield put(
      actions.loadedSuggestionModes([
        "Suggested",
        "Approved",
        "Assigned",
        "Coded",
        "Rejected",
      ])
    );
  }
}

export const deleteCPTCodesRequestName = "deleteCPTCodes";

function* deleteCptCodesSaga(
  action: ReturnType<typeof actions.deleteCptCodes>
) {
  if (action.payload) {
    yield call(requestSaga, {
      drf: {
        method: "post",
        url: `/api/v1/cpt-codes/delete/`,
        data: {
          codes: action.payload,
        },
      },
      name: deleteCPTCodesRequestName,
      successNotification: {
        message: `${action.payload.length} CptCodes has been deleted`,
      },
    });
  }
}

export const saga = function* ctpCodesSaga() {
  yield takeLatest(actions.create.type, createCPTCodeSaga);
  yield takeLatest(actions.load.type, loadCPTCodesSaga);
  yield takeLatest(actions.loadMore.type, loadMoreCPTCodesSaga);
  yield takeLatest(actions.search.type, searchCPTCodesSaga);
  yield takeLatest(actions.approve.type, approveCPTCodeSaga);
  yield takeLatest(actions.submit.type, submitCPTCodeSaga);
  yield takeLatest(actions.reject.type, rejectCPTCodeSaga);
  yield takeLatest(actions.code.type, codeCPTCodeSaga);
  yield takeLatest(actions.assign.type, assignCPTCodeSaga);
  yield takeLatest(actions.loadSuggestionModes.type, loadSuggestionModesSaga);
  yield takeLatest(
    actions.loadOpNoteApprovedSuggestedCodes.type,
    loadApprovedSuggestedOpNoteCptCodesSaga
  );
  yield takeLatest(actions.deleteCptCodes.type, deleteCptCodesSaga);
};
