import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Page } from "../../layout/Page";
import { Button, Checkbox, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isProcessingSelector } from "../../store/requests";
import { RootState } from "../../store/rootReducer";
import { FormikValues } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import {
  Feedback,
  loadFeedbacksRequestName,
  loadMoreFeedbacksRequestName,
} from "../../store/feedbacks";
import { Sort, TableFilters } from "../../Components/Table";
import formatDate from "../../utils/formatDate";
import { Check, Done } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/Create";
import { buttonsUseStyles } from "../../layout/Styles/Buttons";
import { actions } from "../../store/feedbacks";
import { DeleteFeedbacks } from "./components/DeleteFeedbacks";
import DoneIcon from "@material-ui/icons/Done";
import { BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { pageViewGA } from "../../utils/googleAnalytics";
import { SearchableSortDirection } from "../../API";

const initialSort = {
  field: "created_at",
  dir: SearchableSortDirection.desc,
};

const getFilter = (values: FormikValues, sort: Sort | undefined) => {
  const sortStringValue = `${
    sort ? `ordering=${sort.dir === "desc" ? "-" : ""}${sort.field}` : ""
  }`;
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : "";
  return value
    ? `${fieldName}=${value}&${sortStringValue}`
    : `${sortStringValue}`;
};

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",

    "& button": {
      marginLeft: 8,
    },
  },
});

export const Feedbacks = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const isLoading = useSelector(
    isProcessingSelector([loadFeedbacksRequestName])
  );
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreFeedbacksRequestName])
  );
  const storeItems = useSelector(
    (state: RootState) => state.feedbacks.data.listFeedbacks?.items ?? []
  );
  const storeCount = useSelector(
    (state: RootState) => state.feedbacks.data.listFeedbacks?.count ?? 0
  );
  const hasMore = useSelector(
    (state: RootState) => !!state.feedbacks.data.listFeedbacks?.next ?? false
  );
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const allSelectableIds = useMemo(() => {
    return storeItems.reduce<string[]>((res, User) => {
      return [...res, User.id];
    }, []);
  }, [storeItems]);
  const handleSelectAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(new Set(e.target.checked ? allSelectableIds : []));
    },
    [setSelected, allSelectableIds]
  );
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: "Feedbacks",
      href: "/feedbacks",
    },
    {
      title: `${storeCount} items`,
    },
  ];
  const handleDone = useCallback(() => {
    setSelected(new Set());
    dispatch(actions.doneFeedbacks(Array.from(selected)));
  }, [dispatch, selected]);
  const handleAdd = useCallback(() => {
    push("/feedbacks/new");
  }, [push]);

  useEffect(() => {
    dispatch(actions.load());
    pageViewGA(window.location.pathname + window.location.search);
  }, [dispatch]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          singleColumnFilter
          initialSort={initialSort}
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          breadcrumbs={breadcrumbs}
          columns={[
            {
              label: (
                <Checkbox
                  color="primary"
                  checked={selected.size === allSelectableIds.length}
                  onChange={handleSelectAllChange}
                />
              ),
              name: "",
              render: (data: Feedback) => (
                <Checkbox
                  checked={selected.has(data.id)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      selected.add(data.id);
                    } else {
                      selected.delete(data.id);
                    }
                    setSelected(new Set(selected));
                  }}
                  color="primary"
                />
              ),
            },
            {
              name: "subject",
              label: "Subject",
              filter: {
                name: "subject",
              },
              sort: true,
            },
            {
              name: "created_by",
              label: "Created By",
              filter: {
                name: "created_by",
              },
              sort: true,
            },
            {
              name: "created_at",
              label: "Created",
              format: formatDate,
              filter: {
                name: "created",
                type: "date",
              },
              sort: true,
            },
            {
              name: "updated_by",
              label: "Updated By",
              filter: {
                name: "updated_by",
              },
              sort: true,
            },
            {
              name: "updated_at",
              label: "Updated",
              format: formatDate,
              filter: {
                name: "updated",
                type: "date",
              },
              sort: true,
            },
            {
              name: "status",
              label: "Status",
              filter: {
                name: "status",
              },
              sort: true,
            },
            {
              name: "message",
              label: "Message",
              filter: {
                name: "message",
              },
              sort: true,
              render: (data: Feedback) => (
                // @ts-ignore
                <Typography className={styles.text} title={data.message}>
                  {data.message}
                </Typography>
              ),
            },
            // {
            //   label: 'OpNotes',
            //   name: 'count_opnotes',
            //   render: (data: Tenant) => {
            //     return (
            //       <div className={styles.buttons}>
            //         <Button
            //           color="default"
            //           variant="contained"
            //           disabled={!data.active}
            //           onClick={() => forwardTo(`/opNotes/?tenant=${data.name}`)}
            //         >
            //           {data.count_opnotes} opNotes
            //         </Button>
            //         {/*// <div className={styles.buttons}>*/}
            //         {/*//   <ToggleTenant id={id} active={active} />*/}
            //         {/*//   <DeleteTenant id={id} active={active} />*/}
            //       </div>
            //     );
            //   },
            // },
          ]}
          columnsData={storeItems}
          onSubmit={useCallback(
            (values, sort) => {
              dispatch(actions.load(getFilter(values, sort)));
            },
            [dispatch, getFilter]
          )}
          onSubmitMore={useCallback(
            (values) => {
              dispatch(actions.loadMore());
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <Button
              color="primary"
              variant="contained"
              onClick={handleAdd}
              startIcon={<CreateIcon />}
              className={buttonsUseStyles().main}
            >
              Add Feedback
            </Button>
          }
          colGroup={
            <colgroup>
              <col width={10} />
              <col width={170} />
              <col width={170} />
              <col width={170} />
              <col width={170} />
              <col width={200} />
            </colgroup>
          }
        />
        <Grid container>
          <Grid container item xs={6}>
            <Button
              className={buttonsUseStyles().main}
              color="primary"
              size={"small"}
              variant="contained"
              disabled={selected.size === 0}
              startIcon={<DoneIcon />}
              onClick={handleDone}
            >
              Done selected
            </Button>
            <DeleteFeedbacks selected={selected} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
