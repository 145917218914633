import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CreateOpNoteMutationVariables,
  ListApprovedSuggestedOpNoteCptCodesQueryVariables,
  OpNoteCptCodeLinkType,
  OpNoteCptCodesApproveRejectInput,
} from '../../API';
import {OpNote, OpNotes} from "../opNotes";

export type CPTCode = {
  id: string;
  code: string;
  description: string | null;
  linkType?: OpNoteCptCodeLinkType;
  approvedBy?: string | null;
  approvedAt?: string | null;
};

export type listCPTCodes = {
   count: number;
    next: string | null;
    previous: string | null;
    items: CPTCode[] | null;
}
export type CPTCodesState = {
  search: string;
  suggestionModes: string[];
  listCodes: listCPTCodes | null;
  searchedCodes: CPTCode[];
  opNoteApprovedSuggestedCodes: CPTCode[];
  opNoteCodedCodes: CPTCode[];
};

const initialState: CPTCodesState = {
  search: '',
  suggestionModes: [],
  listCodes: null,
  searchedCodes: [],
  opNoteApprovedSuggestedCodes: [],
  opNoteCodedCodes: []
};

const slice = createSlice({
  initialState,
  name: 'CPTCodes',
  reducers: {
    create: (state, action: PayloadAction<{code: string, description: string}>) => state,
    load: (state, action: PayloadAction<any | undefined>) => state,
    loaded: (state, action: PayloadAction<listCPTCodes>) => ({
      ...state,
      listCodes: action.payload,
    }),
    loadMore: (state) => state,
    loadedMore: (state, action: PayloadAction<listCPTCodes>) => {
      if (state && state.listCodes) {
        return {
          ...state,
          listCodes: {
            ...state.listCodes,
            ...action.payload,
            items: [
                ...(action.payload?.items ?? []),
              ],
            },
        };
      }

      return state;
    },
    searched: (state, action: PayloadAction<CPTCode[]>) => ({
      ...state,
      searchedCodes: action.payload,
    }),
    search: (state, action: PayloadAction<string>) => ({
      ...state,
      searchedCodes: [],
      // search: action.payload,
    }),
    loadOpNoteApprovedSuggestedCodes: (
      state,
      action: PayloadAction<ListApprovedSuggestedOpNoteCptCodesQueryVariables>
    ) => ({
      ...state,
      opNoteApprovedSuggestedCodes: [],
    }),
    loadedOpNoteApprovedSuggestedCodes: (
      state,
      action: PayloadAction<CPTCode[]>
    ) => ({
      ...state,
      opNoteApprovedSuggestedCodes: action.payload,
    }),
    approve: (state, action: PayloadAction<OpNoteCptCodesApproveRejectInput>) =>
      state,
    submit: (state, action: PayloadAction<OpNoteCptCodesApproveRejectInput>) =>
      state,
    approved: (
      state,
      action: PayloadAction<{ codeIds: string[]; approver: string }>
    ) => ({
      ...state,
      opNoteApprovedSuggestedCodes: state.opNoteApprovedSuggestedCodes.map(
        (item) => {
          const approvedItem = action.payload.codeIds.find(
            (approvedId) => approvedId === item.code
          );
          if (approvedItem) {
            return {
              ...item,
              approvedBy: action.payload.approver,
              linkType: OpNoteCptCodeLinkType.APPROVED,
            };
          }
          return item;
        }
      ),
    }),
    reject: (state, action: PayloadAction<OpNoteCptCodesApproveRejectInput>) =>
      state,
    rejected: (state, action: PayloadAction<string[]>) => ({
      ...state,
      opNoteApprovedSuggestedCodes: state.opNoteApprovedSuggestedCodes.filter(
        (item) => {
          const rejectedItem = action.payload.find(
            (rejectedId) => rejectedId === item.code
          );

          return !rejectedItem;
        }
      ),
    }),
    assign: (
      state,
      action: PayloadAction<{ opNoteId: string; cptCodes: CPTCode[] }>
    ) => state,
    assigned: (
      state,
      action: PayloadAction<{ codes: CPTCode[]; approver: string }>
    ) => {
      const newCodes = new Set(
        action.payload.codes.map((item) => item.code)
      );

      return {
        ...state,
        opNoteApprovedSuggestedCodes: [
          ...action.payload.codes.map((item) => ({
            ...item,
            approvedBy: action.payload.approver,
            linkType: OpNoteCptCodeLinkType.APPROVED,
          })),
          ...state.opNoteApprovedSuggestedCodes.filter(
            (item) => !newCodes.has(item.code)
          ),
        ],
      };
    },
    code: (
      state,
      action: PayloadAction<{ opNoteId: string; cptCodes: CPTCode[] }>
    ) => state,
    coded: (
      state,
      action: PayloadAction<{ codes: CPTCode[]; approver: string }>
    ) => {
      const newCodes = new Set(
        action.payload.codes.map((item) => item.code)
      );

      return {
        ...state,
        opNoteApprovedSuggestedCodes: [
          ...action.payload.codes.map((item) => ({
            ...item,
            approvedBy: action.payload.approver,
            linkType: OpNoteCptCodeLinkType.APPROVED,
          })),
          ...state.opNoteApprovedSuggestedCodes.filter(
            (item) => !newCodes.has(item.code)
          ),
        ],
      };
    },
    loadSuggestionModes: (state) => state,
    loadedSuggestionModes: (state, action: PayloadAction<string[]>) => ({
      ...state,
      suggestionModes: action.payload,
    }),
    deleteCptCodes: (state, actions: PayloadAction<string[]>) => {
      if (state && state.listCodes && state.listCodes.items) {
        return {
          ...state,
            listCodes: {
              ...state.listCodes,
              items: [
                ...(state.listCodes?.items.filter(item => !actions.payload.includes(item.code)) ?? []),
              ],
          },
        };
      }

      return state;
    },
  },
});

export const {reducer, actions} = slice;
