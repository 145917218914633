import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { Button, TextField, Grid, FormLabel } from "@material-ui/core";
import { Page } from "../../layout/Page";
import { useDispatch, useSelector } from "react-redux";
import { actions, createOpNoteRequestName } from "../../store/opNotes";
import { actions as userActions } from "../../store/user";
import { isProcessingSelector } from "../../store/requests";
import { Breadcrumbs, BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { RootState } from "../../store/rootReducer";
import { pageViewGA } from "../../utils/googleAnalytics";

const regExp = /\S/;
const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: "/opnotes",
    title: "OpNotes"
  },
  {
    title: "Enter new OpNote"
  }
];

export const NewOpNote: FC = () => {
  const [note, setNote] = useState("");
  const { push } = useHistory();
  const isProcessing = useSelector(
    isProcessingSelector([createOpNoteRequestName])
  );
  const agreement = useSelector(
    (state: RootState) => state.user.data?.agreement ?? ""
  );
  const dispatch = useDispatch();
  const isValidNote = useMemo(() => {
    return regExp.test(note);
  }, [note]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setNote(e.target.value);
    },
    [setNote]
  );
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      dispatch(
        actions.create({
          input: { text: note.trim() }
        })
      );
    },
    [note, dispatch]
  );

  const [open, setOpen] = React.useState(!agreement);
  const handleAgree = () => {
    dispatch(userActions.agreement());
    setOpen(false);
  };

  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);
  return (
    <Page>
      <Grid item xs={12}>
        <Dialog
          open={open}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle id='scroll-dialog-title'>
            SOFTWARE END USER LICENSE AGREEMENT
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <div>
                This End User License Agreement, including the Order Form which
                by this reference is incorporated herein (this "Agreement"), is
                a binding agreement between GaleAI, Inc. ("Licensor") and the
                person or entity identified on the Order Form as the licensee of
                the Software ("Licensee"). LICENSOR PROVIDES THE SOFTWARE SOLELY
                ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON
                THE CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY
                CLICKING THE "ACCEPT" BUTTON YOU (A) ACCEPT THIS AGREEMENT AND
                AGREE THAT LICENSEE IS LEGALLY BOUND BY ITS TERMS; AND (B)
                REPRESENT AND WARRANT THAT: (I) YOU ARE OF LEGAL AGE TO ENTER
                INTO A BINDING AGREEMENT; AND (II) IF LICENSEE IS A CORPORATION,
                GOVERNMENTAL ORGANIZATION, OR OTHER LEGAL ENTITY, YOU HAVE THE
                RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON
                BEHALF OF LICENSEE AND BIND LICENSEE TO ITS TERMS. IF LICENSEE
                DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, LICENSOR WILL NOT
                AND DOES NOT LICENSE THE SOFTWARE TO LICENSEE AND YOU MUST NOT
                USE THE SOFTWARE OR DOCUMENTATION. NOTWITHSTANDING ANYTHING TO
                THE CONTRARY IN THIS AGREEMENT OR YOUR OR LICENSEE'S ACCEPTANCE
                OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, NO LICENSE IS
                GRANTED (WHETHER EXPRESSLY, BY IMPLICATION, OR OTHERWISE) UNDER
                THIS AGREEMENT, AND THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT,
                CONCERNING ANY SOFTWARE THAT LICENSEE DID NOT ACQUIRE LAWFULLY
                OR THAT IS NOT A LEGITIMATE, AUTHORIZED COPY OF LICENSOR'S
                SOFTWARE. 1. Definitions. For purposes of this Agreement, the
                following terms have the following meanings: "Authorized Users"
                means the following individual persons authorized to use the
                Software pursuant to the license granted under this Agreement:
                solely those individuals authorized to use the Software pursuant
                to the license granted under this Agreement, as set forth on the
                Order Form. "Documentation" means user manuals, technical
                manuals, and any other materials provided by Licensor, in
                printed, electronic, or other form, that describe the operation,
                use, or technical specifications of the Software. "Intellectual
                Property Rights" means any and all registered and unregistered
                rights granted, applied for, or otherwise now or hereafter in
                existence under or related to any patent, copyright, trademark,
                trade secret, database protection, or other intellectual
                property rights laws, and all similar or equivalent rights or
                forms of protection, in any part of the world. "Licensee" has
                the meaning set forth in the preamble. “Licensee Data” means
                non-public data provided by Licensee to Licensor in the course
                of use of the Software by Licensee or its Authorized Users.
                "License Fees" means the license fees, including all taxes
                thereon, paid or required to be paid by Licensee for the license
                granted under this Agreement. "Licensor" has the meaning set
                forth in the preamble. "Order Form" means the order form filled
                out and submitted by or on behalf of Licensee, and accepted by
                Licensor, for Licensee's purchase of the license for the
                Software granted under this Agreement. "Person" means an
                individual, corporation, partnership, joint venture, limited
                liability company, governmental authority, unincorporated
                organization, trust, association, or other entity. "Resultant
                Data" means data and information related to Licensee's use of
                the Software that is used by Licensor in an aggregate and
                anonymized manner, including to compile statistical and
                performance information related to the provision and operation
                of the Software. "Software" means the software programs for
                which Licensee is purchasing a license, as expressly set forth
                in the Order Form. "Term" has the meaning set forth in Section
                10. "Third Party" means any Person other than Licensee or
                Licensor. "Update" has the meaning set forth in Section 7(b). 2.
                License Grant and Scope. Subject to and conditioned upon
                Licensee's payment of the License Fees and Licensee's strict
                compliance with all terms and conditions set forth in this
                Agreement, Licensor hereby grants to Licensee a non-exclusive,
                non-transferable, non-sublicensable limited license during the
                Term to use, solely by and through its Authorized Users, the
                Software and Documentation, solely as set forth in this Section
                2 and subject to all conditions and limitations set forth in
                Section 4 or elsewhere in this Agreement. This license grants
                Licensee the right, exercisable solely by and through Licensee's
                Authorized Users, to use and run the Software in accordance with
                this Agreement and the Documentation, solely as set forth in the
                Documentation and solely for Licensee's internal business
                purposes. 3. Third-Party Materials. The Software includes
                software, content, data, or other materials, including related
                documentation, that are owned by Persons other than Licensor and
                that are provided to Licensee on licensee terms that are in
                addition to and/or different from those contained in this
                Agreement ("Third-Party Licenses"). Licensee is bound by and
                shall comply with all Third-Party Licenses. Any breach by
                Licensee or any of its Authorized Users of any Third-Party
                License is also a breach of this Agreement. 4. Use Restrictions.
                Licensee shall not, and shall require its Authorized Users not
                to, directly or indirectly: (a) use the Software or
                Documentation beyond the scope of the license granted under
                Section 2; (b) provide any other Person, including any
                subcontractor, independent contractor, affiliate, or service
                provider of Licensee, with access to or use of the Software or
                Documentation; (c) modify, translate, adapt, or otherwise create
                derivative works or improvements, whether or not patentable, of
                the Software or Documentation or any part thereof; (d) combine
                the Software or any part thereof with, or incorporate the
                Software or any part thereof in, any other programs; (e) reverse
                engineer, disassemble, decompile, decode, or otherwise attempt
                to derive or gain access to the source code of the Software or
                any part thereof; (f) remove, delete, alter, or obscure any
                trademarks or any copyright, trademark, patent, or other
                intellectual property or proprietary rights notices provided on
                or with the Software or Documentation, including any copy
                thereof; (g) copy the Software or Documentation, in whole or in
                part; (h) rent, lease, lend, sell, sublicense, assign,
                distribute, publish, transfer, or otherwise make available the
                Software, or any features or functionality of the Software, to
                any Third Party for any reason, whether or not over a network or
                on a hosted basis, including in connection with the internet or
                any web hosting, wide area network (WAN), virtual private
                network (VPN), virtualization, time-sharing, service bureau,
                software as a service, cloud, or other technology or service;
                (i) use the Software or Documentation in, or in association
                with, the design, construction, maintenance, or operation of any
                hazardous environments or systems, including: (i) power
                generation systems; (ii) aircraft navigation or communication
                systems, air traffic control systems, or any other transport
                management systems; (iii) safety-critical applications,
                including medical or life-support systems, vehicle operation
                applications, or any police, fire, or other safety response
                systems; and (iv) military or aerospace applications, weapons
                systems, or environments; (j) use the Software or Documentation
                in violation of any law, regulation, or rule; or (k) use the
                Software or Documentation for purposes of competitive analysis
                of the Software, the development of a competing software product
                or service, or any other purpose that is to the Licensor's
                commercial disadvantage. 5. Licensee Responsibilities. (a)
                Licensee is responsible and liable for all uses of the Software
                and Documentation through access thereto provided by Licensee,
                directly or indirectly. Specifically, and without limiting the
                generality of the foregoing, Licensee is responsible and liable
                for all actions and failures to take required actions with
                respect to the Software and Documentation by its Authorized
                Users or by any other Person to whom Licensee or an Authorized
                User may provide access to or use of the Software and/or
                Documentation, whether such access or use is permitted by or in
                violation of this Agreement. (b) Licensee shall ensure that all
                Licensee Data is de-identified of any protected health
                information before being uploaded or otherwise entered into the
                Software, in accordance with 45 C.F.R Part 164.514 of the Health
                Insurance Portability and Accountability Act of 1996, as
                amended. 6. Compliance Measures. (a) The Software may contain
                technological copy protection or other security features
                designed to prevent unauthorized use of the Software, including
                features to protect against any use of the Software that is
                prohibited under Section 4. Licensee shall not, and shall not
                attempt to, remove, disable, circumvent, or otherwise create or
                implement any workaround to, any such copy protection or
                security features. (b) On Licensor's written request, Licensee
                shall conduct a review of its and its Authorized Users use the
                Software and certify to Licensor in a written instrument signed
                by an officer of Licensee that it is in full compliance with
                this Agreement or, if Licensee discovers any noncompliance: (i)
                Licensee shall immediately remedy such noncompliance and provide
                Licensor with written notice thereof. Licensee shall provide
                Licensor with all access and assistance as Licensor requests to
                further evaluate and remedy such noncompliance; and (ii) If
                Licensee's use of the Software exceeds the number of Authorized
                Users permitted under the license, Licensor shall have the
                remedies set forth in Section 6(d). (c) During the Term,
                Licensor may, in Licensor's sole discretion, audit Licensee's
                use of the Software to ensure Licensee's compliance with this
                Agreement, provided that (i) any such audit shall be conducted
                on not less than thirty (30) days' prior notice to Licensee, and
                (ii) no more than two (2) audits may be conducted in any six (6)
                month period except for good cause shown. Licensor also may, in
                its sole discretion, audit Licensee's systems within six (6)
                months after the end of the Term to ensure Licensee has ceased
                use of the Software. The Licensee shall fully cooperate with
                Licensor's personnel conducting such audits and provide all
                access requested by the Licensor to records, systems, equipment,
                information, and personnel, including machine IDs, serial
                numbers, and related information. Licensor shall only examine
                information directly related to the Licensee's use of the
                Software. Licensor may conduct audits in a manner that does not
                unreasonably interfere with the Licensee's business operations.
                (d) If any of the measures taken or implemented under this
                Section 6 determines that the Licensee's use of the Software
                exceeds or exceeded the use permitted by this Agreement then:
                (i) Licensee shall, within thirty (30) days following the date
                of Licensor's written notification thereof, pay to Licensor the
                retroactive License Fees for such excess use and, unless
                Licensor terminates this Agreement pursuant to Section
                6(d)(iii), obtain and pay for a valid license to bring
                Licensee's use into compliance with this Agreement. In
                determining the Licensee Fee payable pursuant to the foregoing,
                (x) unless Licensee can demonstrate otherwise by documentary
                evidence, all excess use of the Software shall be deemed to have
                commenced on the commencement date of this Agreement or, if
                later, the completion date of any audit previously conducted by
                Licensor hereunder, and continued uninterrupted thereafter, and
                (y) the rates for such licenses shall be determined without
                regard to any discount to which Licensee may have been entitled
                had such use been properly licensed prior to its commencement
                (or deemed commencement). (ii) If the use exceeds or exceeded
                the use permitted by this Agreement by more than 3%, Licensee
                shall also pay to Licensor, within thirty (30) days following
                the date of Licensor's written request therefor, Licensor's
                reasonable costs incurred in conducting the audit. (iii) If the
                use exceeds or exceeded the use permitted by this Agreement by
                more than 5%, Licensor shall also have the right to terminate
                this Agreement and the license granted hereunder, effective
                immediately upon written notice to Licensee. Licensor's remedies
                set forth in this Section 6(d) are cumulative and are in
                addition to, and not in lieu of, all other remedies the Licensor
                may have at law or in equity, whether under this Agreement or
                otherwise. 7. Maintenance and Support. (a) Subject to Section
                7(c), the license granted hereunder entitles Licensee to the
                basic software maintenance and support services in accordance
                with Licensor’s standard practice. (b) Maintenance and support
                services will include provision of such updates, upgrades, bug
                fixes, patches, and other error corrections (collectively,
                "Updates") as Licensor makes generally available free of charge
                to all licensees of the Software then entitled to maintenance
                and support services. Licensor may develop and provide Updates
                in its sole discretion, and Licensee agrees that Licensor has no
                obligation to develop any Updates at all or for particular
                issues. Licensee further agrees that all Updates will be deemed
                Software, and related documentation will be deemed
                Documentation, all subject to all terms and conditions of this
                Agreement. Maintenance and support services do not include any
                new version or new release of the Software that Licensor may
                issue as a separate or new product, and Licensor may determine
                whether any issuance qualifies as a new version, new release, or
                Update in its sole discretion. (c) Licensor has no obligation to
                provide maintenance and support services, including Updates if
                Licensee is in breach under this Agreement. 8. Collection and
                Use of Information. (a) Licensee acknowledges that Licensor may,
                directly or indirectly through the services of Third Parties,
                collect and store information regarding use of the Software and
                about equipment on which the Software is accessed and used
                (including without limitation, information concerning Licensee
                Data and data derived therefrom). (b) Licensee agrees that the
                Licensor may use such information for any purpose related to any
                use of the Software by Licensee or on Licensee's equipment,
                including but not limited to: (i) improving the performance of
                the Software or developing Updates; (ii) verifying Licensee's
                compliance with the terms of this Agreement and enforcing the
                Licensor's rights, including all Intellectual Property Rights in
                and to the Software; or (iii) disclosing such data solely in
                aggregate or other de-identified form in connection with its
                business. (c) In furtherance of the foregoing, Licensee hereby
                unconditionally and irrevocably grants to Licensor an assignment
                of all right, title, and interest in and to the Resultant Data,
                including all Intellectual Property Rights relating thereto. (d)
                Licensee shall own all right, title, and interest in and to the
                Licensee Data. 9. Intellectual Property Rights. Licensee
                acknowledges and agrees that the Software and Documentation are
                provided under license, and not sold, to Licensee. Licensee does
                not acquire any ownership interest in the Software or
                Documentation under this Agreement, or any other rights thereto,
                other than to use the same in accordance with the license
                granted and subject to all terms, conditions, and restrictions
                under this Agreement. Licensor reserves and shall retain its
                entire right, title, and interest in and to the Software and all
                Intellectual Property Rights arising out of or relating to the
                Software, except as expressly granted to the Licensee in this
                Agreement. Licensee shall safeguard all Software from
                infringement, misappropriation, theft, misuse, or unauthorized
                access. Licensee shall promptly notify Licensor if Licensee
                becomes aware of any infringement of the Licensor's Intellectual
                Property Rights in the Software and fully cooperate with
                Licensor in any legal action taken by Licensor to enforce its
                Intellectual Property Rights. 10. Term and Termination. (a) This
                Agreement and the license granted hereunder shall remain in
                effect for the term set forth on the Order Form or until earlier
                terminated as set forth herein (the "Term"). (b) Licensor may
                terminate this Agreement, effective upon written notice to
                Licensee, if Licensee, breaches this Agreement and such breach:
                (i) is incapable of cure; or (ii) being capable of cure, remains
                uncured thirty (30) days after Licensor provides written notice
                thereof. (c) Licensor may terminate this Agreement, effective
                immediately, if Licensee files, or has filed against it, a
                petition for voluntary or involuntary bankruptcy or pursuant to
                any other insolvency law, makes or seeks to make a general
                assignment for the benefit of its creditors or applies for, or
                consents to, the appointment of a trustee, receiver, or
                custodian for a substantial part of its property. (d) Upon
                expiration or earlier termination of this Agreement, the license
                granted hereunder shall also terminate, and Licensee shall cease
                using the Software and Documentation. No expiration or
                termination shall affect Licensee's obligation to pay all
                Licensee Fees and Support Fees that may have become due before
                such expiration or termination, or entitle Licensee to any
                refund, in each case except as set forth in Section 11(c)(ii).
                11. Limited Warranties, Exclusive Remedy, and Warranty
                Disclaimer. (a) Solely with respect to Software for which
                Licensor receives a License Fee, Licensor warrants that, for a
                period of one hundred and eighty ([180]) days following the
                purchase date set forth on the Order Form: (i) any media on
                which the Software is accessed will be free of material damage
                and defects in materials and workmanship under normal use; and
                (ii) the Software will substantially contain the functionality
                described in the Documentation, and when used on a computer
                meeting the specifications set forth in, and operated in
                accordance with, the Documentation, will substantially perform
                in accordance therewith. THE FOREGOING WARRANTIES DO NOT APPLY,
                AND LICENSOR STRICTLY DISCLAIMS ALL WARRANTIES, WITH RESPECT TO
                ANY THIRD-PARTY MATERIALS. (b) The warranties set forth in
                Section 11(a)(i) and Section 11(a)(ii) will not apply and will
                become null and void if Licensee breaches any material provision
                of this Agreement, or if Licensee, any Authorized User, or any
                other Person provided access to the Software by Licensee or any
                Authorized User, whether or not in violation of this Agreement
                misuses the Software, including any use of the Software other
                than as specified in the Documentation. (c) If, during the
                period specified in Section 11(a), any Software covered by the
                warranty set forth in such Section fails to perform
                substantially in accordance with the Documentation, and such
                failure is not excluded from warranty pursuant to the Section
                11(b), Licensor will, subject to Licensee's promptly notifying
                Licensor in writing of such failure, at its sole option, either:
                (i) repair or replace the Software, provided that Licensee
                provides Licensor with all information Licensor requests to
                resolve the reported failure, including sufficient information
                to enable the Licensor to recreate such failure; or (ii) refund
                the License Fees paid for such Software, subject to Licensee's
                ceasing all use the Software. If Licensor repairs or replaces
                the Software, the warranty will continue to run from the initial
                date specified on the Order Form, and not from Licensee's
                receipt of the repair or replacement. The remedies set forth in
                this Section 11(c) are Licensee's sole remedies and Licensor's
                sole liability under this Agreement. (d) EXCEPT FOR THE LIMITED
                WARRANTY SET FORTH IN SECTION 11(a), THE SOFTWARE AND
                DOCUMENTATION ARE PROVIDED TO LICENSEE "AS IS" AND WITH ALL
                FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM
                EXTENT PERMITTED UNDER APPLICABLE LAW, LICENSOR, ON ITS OWN
                BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
                RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS
                ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                OTHERWISE, WITH RESPECT TO THE SOFTWARE AND DOCUMENTATION,
                INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
                WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
                PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE
                FOREGOING, THE LICENSOR PROVIDES NO WARRANTY OR UNDERTAKING, AND
                MAKES NO REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE
                WILL MEET THE LICENSEE'S REQUIREMENTS, ACHIEVE ANY INTENDED
                RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
                APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT
                INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR
                BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
                CORRECTED. 12. Limitation of Liability. TO THE FULLEST EXTENT
                PERMITTED UNDER APPLICABLE LAW: (a) IN NO EVENT WILL LICENSOR OR
                ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR
                SERVICE PROVIDERS, BE LIABLE TO LICENSEE OR ANY THIRD PARTY FOR
                ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE SOFTWARE;
                LOST REVENUES OR PROFITS; DELAYS, INTERRUPTION, OR LOSS OF
                SERVICES, BUSINESS, OR GOODWILL; LOSS OR CORRUPTION OF DATA;
                LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE,
                MALFUNCTION, OR SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ,
                OR TRANSMIT INFORMATION; FAILURE TO UPDATE OR PROVIDE CORRECT
                INFORMATION; SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT
                COMPATIBILITY INFORMATION; OR BREACHES IN SYSTEM SECURITY; OR
                FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF OR IN CONNECTION
                WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING
                NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES
                WERE FORESEEABLE AND WHETHER OR NOT THE LICENSOR WAS ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES. (b) IN NO EVENT WILL LICENSOR'S
                AND ITS AFFILIATES', INCLUDING ANY OF ITS OR THEIR RESPECTIVE
                LICENSORS' AND SERVICE PROVIDERS', COLLECTIVE AGGREGATE
                LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENTOR ITS
                SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING
                BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
                LIABILITY, AND OTHERWISE, EXCEED THE TOTAL AMOUNT PAID TO THE
                LICENSOR PURSUANT TO THIS AGREEMENT FOR THE SOFTWARE IN THE
                TWELVE (12) MONTHS PRECEDING THE EVENT THAT GAVE RISE TO THE
                CLAIM. (c) THE LIMITATIONS SET FORTH IN SECTION 12(a) AND
                SECTION 12(b) SHALL APPLY EVEN IF THE LICENSEE'S REMEDIES UNDER
                THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE. 13. Export
                Regulation. The Software and Documentation may be subject to US
                export control laws, including the Export Control Reform Act and
                its associated regulations. The Licensee shall not, directly or
                indirectly, export, re-export, or release the Software or
                Documentation to, or make the Software or Documentation
                accessible from, any jurisdiction or country to which export,
                re-export, or release is prohibited by law, rule, or regulation.
                The Licensee shall comply with all applicable federal laws,
                regulations, and rules, and complete all required undertakings
                (including obtaining any necessary export license or other
                governmental approval), prior to exporting, re-exporting,
                releasing, or otherwise making the Software or Documentation
                available outside the US. 14. US Government Rights. The Software
                is commercial computer software, as such term is defined in 48
                C.F.R. §2.101. Accordingly, if the Licensee is the US Government
                or any contractor therefor, Licensee shall receive only those
                rights with respect to the Software and Documentation as are
                granted to all other end users under license, in accordance with
                (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with
                respect to the Department of Defense and their contractors, or
                (b) 48 C.F.R. §12.212, with respect to all other US Government
                licensees and their contractors. 15. Miscellaneous. (a) All
                matters arising out of or relating to this Agreement shall be
                governed by and construed in accordance with the internal laws
                of the State of Texas without giving effect to any choice or
                conflict of law provision or rule. Any legal suit, action, or
                proceeding arising out of or relating to this Agreement or the
                transactions contemplated hereby shall be instituted in the
                federal courts of the United States of America or the courts of
                the State of Texas in each case located in the City of Austin
                and County of Travis, and each party irrevocably submits to the
                exclusive jurisdiction of such courts in any such legal suit,
                action, or proceeding. Service of process, summons, notice, or
                other document by mail to such party's address set forth herein
                shall be effective service of process for any suit, action, or
                other proceeding brought in any such court. (b) Licensor will
                not be responsible or liable to Licensee, or deemed in default
                or breach hereunder by reason of any failure or delay in the
                performance of its obligations hereunder where such failure or
                delay is due to strikes, labor disputes, civil disturbances,
                riot, rebellion, invasion, epidemic, hostilities, war, terrorist
                attack, embargo, natural disaster, acts of God, flood, fire,
                sabotage, fluctuations or non-availability of electrical power,
                heat, light, air conditioning, or Licensee equipment, loss and
                destruction of property, or any other circumstances or causes
                beyond Licensor's reasonable control. (c) All notices, requests,
                consents, claims, demands, waivers, and other communications
                hereunder shall be in writing and shall be deemed to have been
                given: (i) when delivered by hand (with written confirmation of
                receipt); (ii) when received by the addressee if sent by a
                nationally recognized overnight courier (receipt requested);
                (iii) on the date sent by email (with confirmation of
                transmission) if sent during normal business hours of the
                recipient, and on the next business day if sent after normal
                business hours of the recipient; or (iv) on the third day after
                the date mailed, by certified or registered mail, return receipt
                requested, postage prepaid. Such communications must be sent to
                the respective parties at the addresses set forth on the Order
                Form (or to such other address as may be designated by a party
                from time to time in accordance with this Section 15(c)). (d)
                This Agreement, together with the Order Form, any Annexes, and
                all other documents that are incorporated by reference herein,
                constitutes the sole and entire agreement between Licensee and
                Licensor with respect to the subject matter contained herein,
                and supersedes all prior and contemporaneous understandings,
                agreements, representations, and warranties, both written and
                oral, with respect to such subject matter. (e) Licensee shall
                not assign or otherwise transfer any of its rights, or delegate
                or otherwise transfer any of its obligations or performance,
                under this Agreement, in each case whether voluntarily,
                involuntarily, by operation of law, or otherwise, without
                Licensor's prior written consent, which consent Licensor may
                give or withhold in its sole discretion. No delegation or other
                transfer will relieve Licensee of any of its obligations or
                performance under this Agreement. Any purported assignment,
                delegation, or transfer in violation of this Section 15(e) is
                void. Licensor may freely assign or otherwise transfer all or
                any of its rights, or delegate or otherwise transfer all or any
                of its obligations or performance, under this Agreement without
                Licensee's consent. This Agreement is binding upon and inures to
                the benefit of the parties hereto and their respective permitted
                successors and assigns. (f) This Agreement is for the sole
                benefit of the parties hereto and their respective successors
                and permitted assigns and nothing herein, express or implied, is
                intended to or shall confer on any other Person any legal or
                equitable right, benefit, or remedy of any nature whatsoever
                under or by reason of this Agreement. (g) This Agreement may
                only be amended, modified, or supplemented by an agreement in
                writing signed by each party hereto. No waiver by any party of
                any of the provisions hereof shall be effective unless
                explicitly set forth in writing and signed by the party so
                waiving. Except as otherwise set forth in this Agreement, no
                failure to exercise, or delay in exercising, any right, remedy,
                power, or privilege arising from this Agreement shall operate or
                be construed as a waiver thereof; nor shall any single or
                partial exercise of any right, remedy, power, or privilege
                hereunder preclude any other or further exercise thereof or the
                exercise of any other right, remedy, power, or privilege. (h) If
                any term or provision of this Agreement is invalid, illegal, or
                unenforceable in any jurisdiction, such invalidity, illegality,
                or unenforceability shall not affect any other term or provision
                of this Agreement or invalidate or render unenforceable such
                term or provision in any other jurisdiction. (i) For purposes of
                this Agreement, (a) the words "include," "includes," and
                "including" shall be deemed to be followed by the words "without
                limitation"; (b) the word "or" is not exclusive; and (c) the
                words "herein," "hereof," "hereby," "hereto," and "hereunder"
                refer to this Agreement as a whole. Unless the context otherwise
                requires, references herein: (x) to Sections, Annexes,
                Schedules, and Exhibits refer to the Sections of, and Annexes,
                Schedules, and Exhibits attached to, this Agreement; (y) to an
                agreement, instrument, or other document means such agreement,
                instrument, or other document as amended, supplemented, and
                modified from time to time to the extent permitted by the
                provisions thereof and (z) to a statute means such statute as
                amended from time to time and includes any successor legislation
                thereto and any regulations promulgated thereunder. This
                Agreement shall be construed without regard to any presumption
                or rule requiring construction or interpretation against the
                party drafting an instrument or causing any instrument to be
                drafted. The Order Form referred to herein shall be construed
                with, and as an integral part of, this Agreement to the same
                extent as if they were set forth verbatim herein. (j) The
                headings in this Agreement are for reference only and do not
                affect the interpretation of this Agreement. ANNEX I AUTHORIZED
                USER TERMS OF USE These Software Terms of Use ("Terms of Use")
                govern your use of the GaleAI software (the "Software"),
                including all user manuals, technical manuals, and any other
                materials provided by Licensor, in printed, electronic, or other
                form, that describe the Software or its use or specifications
                (the "Documentation") provided to you ("you" or "your") for use
                pursuant to and subject to a software license agreement (the
                "Software License Agreement") between GaleAI, Inc. ("Licensor")
                and your employer or other person or entity who owns or
                otherwise lawfully controls the computer on which the Software
                is accessed ("Licensee"). BY CLICKING THE "ACCEPT" BUTTON YOU:
                (i) REPRESENT THAT YOU ARE DULY AUTHORIZED BY LICENSEE TO ACCESS
                AND USE THE SOFTWARE; AND (ii) ACCEPT THESE AUTHORIZED USER
                TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM. IF YOU DO
                NOT AGREE TO THESE TERMS OF USE, DO NOT CLICK THE "ACCEPT"
                BUTTON AND YOU WILL HAVE NO LICENSE TO, AND MUST NOT ACCESS OR
                USE, THE SOFTWARE. 1. License Grant. Subject to your strict
                compliance with these Terms of Use, Licensor hereby grants you a
                non-exclusive, non-transferable, non-sublicensable, limited
                license to use the Software solely in accordance with the
                Documentation on the equipment provided by Licensee and for
                Licensee's internal business purposes. The foregoing licensee
                will terminate immediately on the earlier to occur of: (a) the
                expiration or earlier termination of the Software License
                Agreement between Licensor and Licensee; or (b) your ceasing to
                be authorized by Licensee to use the Software for any or no
                reason. 2. Use Restrictions. You shall not, directly or
                indirectly: (a) use the Software or Documentation except as set
                forth in Section 1; (b) copy the Software or Documentation, in
                whole or in part; (c) modify, translate, adapt, or otherwise
                create derivative works or improvements, whether or not
                patentable, of the Software or any part thereof; (d) combine the
                Software or any part thereof with, or incorporate the Software
                or any part thereof in, any other programs; (e) reverse
                engineer, disassemble, decompile, decode, or otherwise attempt
                to derive or gain access to the source code of the Software or
                any part thereof; (f) remove, delete, alter, or obscure any
                trademarks or any copyright, trademark, patent, or other
                intellectual property or proprietary rights notices included on
                or in the Software or Documentation, including any copy thereof;
                (g) rent, lease, lend, sell, sublicense, assign, distribute,
                publish, transfer, or otherwise provide any access to or use of
                the Software or any features or functionality of the Software,
                for any reason, to any other person or entity, including any
                subcontractor, independent contractor, affiliate, or service
                provider of Licensee, whether or not over a network and whether
                or not on a hosted basis, including in connection with the
                internet, web hosting, wide area network (WAN), virtual private
                network (VPN), virtualization, time-sharing, service bureau,
                software as a service, cloud, or other technology or service;
                (h) use the Software or Documentation in, or in association
                with, the design, construction, maintenance, or operation of any
                hazardous environments or systems, including: (i) power
                generation systems; (ii) aircraft navigation or communication
                systems, air traffic control systems, or any other transport
                management systems; (iii) safety-critical applications,
                including medical or life-support systems, vehicle operation
                applications, or any police, fire, or other safety response
                systems; and (iv) military or aerospace applications, weapons
                systems, or environments. (i) use the Software or Documentation
                in violation of any law, regulation, or rule; or (j) use the
                Software or Documentation for purposes of competitive analysis
                of the Software, the development of a competing software product
                or service, or any other purpose that is to the Licensor's
                commercial disadvantage. 3. Compliance Measures. (a) The
                Software may contain technological copy protection or other
                security features designed to prevent unauthorized use of the
                Software, including features to protect against use of the
                Software: (i) beyond the scope of the license granted to
                pursuant to Section 1; or (ii) prohibited under Section 2. You
                shall not, and shall not attempt to, remove, disable,
                circumvent, or otherwise create or implement any workaround to,
                any such copy protection or security features. 4. Collection and
                Use of Information. (a) Licensor may, directly or indirectly
                through the services of others, collect and store information
                regarding use of the Software and about equipment on which the
                Software is accessed and used, by means of (i) providing
                maintenance and support services and (ii) security measures
                included in the Software as described in Section 3. (b) You
                agree that the Licensor may use such information for any purpose
                related to any use of the Software by you, including but not
                limited to: (i) improving the performance of the Software or
                developing updates; and verifying compliance with the terms of
                this Agreement and enforcing Licensor's rights, including all
                intellectual property rights in and to the Software. 5.
                Intellectual Property Rights. You acknowledge that the Software
                is provided under license, and not sold, to you. You do not
                acquire any ownership interest in the Software under this
                Agreement, or any other rights to the Software other than to use
                the Software in accordance with the license granted under this
                Agreement, subject to all terms, conditions, and restrictions.
                Licensor reserves and shall retain its entire right, title, and
                interest in and to the Software and all intellectual property
                rights arising out of or relating to the Software, subject to
                the license expressly granted to the Licensee in this Agreement.
                You shall safeguard all Software from infringement,
                misappropriation, theft, misuse, or unauthorized access. 6.
                 Disclaimer of Liability. IN NO EVENT WILL LICENSOR OR ITS
                AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR
                SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY USE, INTERRUPTION,
                DELAY, OR INABILITY TO USE THE SOFTWARE. YOU ARE PROVIDED THE
                SOFTWARE PURSUANT TO THE SOFTWARE LICENSE AGREEMENT BETWEEN
                LICENSOR AND LICENSEE, SOLELY FOR THE BENEFIT OF LICENSEE AND AT
                LICENSEE'S DISCRETION. YOU ACKNOWLEDGE THAT YOU HAVE NO RIGHTS
                UNDER THAT AGREEMENT INCLUDING ANY RIGHTS TO ENFORCE ANY OF ITS
                TERMS. ANY OBLIGATION OR LIABILITY LICENSOR OR ITS AFFILIATES,
                OR ANY OF ITS OR THEIR LICENSORS OR SERVICE PROVIDERS, MAY HAVE
                WITH RESPECT TO YOUR USE OR INABILITY TO USE THE SOFTWARE SHALL
                BE SOLELY TO LICENSEE PURSUANT TO THAT AGREEMENT AND SUBJECT TO
                ALL LIMITATIONS OF LIABILITY SET FORTH THEREIN. 7. Export
                Regulation. The Software may be subject to US export control
                laws, including the US Export Administration Act and its
                associated regulations. You shall not, directly or indirectly,
                export, re-export, or release the Software to, or make the
                Software or Documentation accessible from, any jurisdiction or
                country to which export, re-export, or release is prohibited by
                law, rule, or regulation. You shall comply with all applicable
                federal laws, regulations, and rules, and complete all required
                undertakings (including obtaining any necessary export license
                or other governmental approval), prior to exporting,
                re-exporting, releasing, or otherwise making the Software
                available outside the US. 8. Governing Law. These Terms of Use
                are governed by and construed in accordance with the internal
                laws of the State of Texas without giving effect to any choice
                or conflict of law provision or rule (whether of the State of
                Texas or any other jurisdiction) that would cause the
                application of Laws of any jurisdiction other than those of the
                State of Texas.
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color='secondary'
              onClick={async () => {
                try {
                  dispatch(userActions.signOut());
                } catch (e) {}
              }}
            >
              REJECT
            </Button>
            <Button onClick={handleAgree} color='primary' autoFocus>
              ACCEPT
            </Button>
          </DialogActions>
        </Dialog>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify='space-between'>
              <Breadcrumbs items={breadcrumbs} />
              <div>
                <Button
                  color='default'
                  variant='contained'
                  onClick={() => push("/opnotes/upload")}
                  startIcon={<CloudUploadIcon />}
                  style={{ marginRight: 16 }}
                >
                  Upload
                </Button>
                <Button
                  disabled={isProcessing || !isValidNote}
                  type='submit'
                  color='default'
                  variant='contained'
                >
                  Submit
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Please enter OpNote:</FormLabel>
              <TextField
                fullWidth
                multiline
                value={note}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Page>
  );
};
