import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ModelTenantFilterInput, UpdateTenantInput} from '../../API';
import {User} from '../user';
import {Sort} from '../../Components/Table';
import {OpNote} from "../opNotes";

export type LoadRequestParams = {
  filter: ModelTenantFilterInput | null;
  sort?: Sort;
} | null;

export type Tenant = {
  id: string;
  name: string;
  created_by: string;
  updated_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  count_opnotes: number;
  count_ednotes: number;
  listOpNotes?: OpNote[];
};

export type Tenants = {
  listTenants: {
    count?: number | null;
    next?: string | null;
    previous?: string | null;
    items: Tenant[];
  };
};

export type TenantsState = {
  data: Tenants;
  requestParams: LoadRequestParams;
  userProfileTenants: Tenant[];
  selectedTenant: Tenant | null;
};

const initialState: TenantsState = {
  data: {
    listTenants: {
      items: [],
    },
  },
  requestParams: null,
  selectedTenant: null,
  userProfileTenants: [],
};

const slice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<any | undefined>) => ({
      ...state,
    }),
    loaded(state, action: PayloadAction<Tenants>) {
      return {
        ...state,
        data: action.payload,
      };
    },
    loadMore: (state, action: PayloadAction<any | undefined>) => state,
    loadedMore(state, action: PayloadAction<Tenants>) {
      if (state.data && state.data.listTenants) {
        return {
          ...state,
          data: {
            listTenants: {
              ...state.data.listTenants,
              ...action.payload.listTenants,
              items: [
                ...(action.payload?.listTenants?.items ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    loadTenant: (state, action: PayloadAction<string>) => state,
    loadedTenant: (state, action: PayloadAction<Tenant>) => ({
      ...state,
      selectedTenant: action.payload,
    }),
    create: (state, action: PayloadAction<string>) => state,
    update: (state, action: PayloadAction<UpdateTenantInput>) => state,
    loadUserTenants: (state, action: PayloadAction<boolean | undefined>) =>
      state,
    loadedUserTenants: (state, action: PayloadAction<Tenant[]>) => ({
      ...state,
      userProfileTenants: action.payload,
    }),
    toggle: (state, action: PayloadAction<{ id: string; active: boolean }>) =>
      state,
    toggled: (
      state,
      {
        payload: {id, active},
      }: PayloadAction<{ id: string; active: boolean }>
    ) => ({
      ...state,
      data: {
        ...state.data,
        listTenants: {
          ...state.data.listTenants,
          items: state.data.listTenants.items.map((item) => {
            return item.id === id ? {...item, active} : item;
          }),
        },
      },
    }),
    deleteTenant: (state, action: PayloadAction<{ id: string; active: boolean }>) =>
      state,
    deleteTenants: (state, action: PayloadAction<string[]>) => {
      if (state.data && state.data.listTenants && state.data.listTenants.items) {
        return {
          ...state,
          data: {
            listTenants: {
              ...state.data.listTenants,
              items: [
                ...(state.data.listTenants?.items.filter(item => !action.payload.includes(item.id)) ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
