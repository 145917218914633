import React, { FC, useReducer } from 'react';
import { FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { User } from '../../../../store/user';
import { ScreenNavLink } from '../ScreenNavLink';
import { LoadingButton } from '../../../../Components/LoadingButton';
import { customAxios } from '../../../../store/customAxios';

type Action = {
  type: string;
  payload?: any;
};

type State = {
  error: string;
  username: string;
  password: string;
  initialised: boolean;
  isProcessing: boolean;
  usernameToRestore: string;
};

const initialState: State = {
  error: '',
  username: '',
  password: '',
  initialised: false,
  isProcessing: false,
  usernameToRestore: '',
};

type signInResponse = {
  data: {
    exp: number,
    id_token: string,
    access_token: string,
    refresh_token: string,
  }
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateUsername': {
      return {
        ...state,
        username: action.payload,
      };
    }
    case 'updatePassword': {
      return {
        ...state,
        password: action.payload,
      };
    }
    case 'setError': {
      return {
        ...state,
        isProcessing: false,
        error: action.payload,
      };
    }
    case 'setProcessing': {
      return {
        ...state,
        isProcessing: action.payload,
      };
    }
    case 'reset': {
      return initialState;
    }
    case 'initialised': {
      return {
        ...state,
        initialised: true,
      };
    }
    default:
      return state;
  }
};

const formatErrorMessage = (message: string): string => {
  if (message === 'User does not exist.') {
    return 'Incorrect username or password.';
  }

  return message;
};

export type Props = {
  onSignedIn: (user: User) => void;
};

export const SignIn: FC<Props> = (props) => {
  const { onSignedIn } = props;
  const [state, dispatchState] = useReducer(reducer, initialState);

  const isFieldsEmpty = !state.username || !state.password;

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          dispatchState({
            type: 'setProcessing',
            payload: true,
          });
          const userData = new FormData();
          userData.append('username', state.username);
          userData.append('password', state.password);
          const res: signInResponse = await customAxios.post('/auth/login/', userData);
          localStorage.setItem('expiresOn', String(res.data.exp));
          localStorage.setItem('idToken', res.data.id_token);
          localStorage.setItem('accessToken', res.data.access_token);
          localStorage.setItem('refreshToken', res.data.refresh_token);
          const user: {data: User} = await customAxios.get('/api/v1/accounts/profile/',{  headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },});
          onSignedIn(user.data);
        } catch (e) {
          dispatchState({
            type: 'setError',
            // payload: formatErrorMessage(e.message),
          });
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Sign in to your account</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={state.username}
            label="Username *"
            placeholder="Enter your username"
            onChange={(e) => {
              dispatchState({
                type: 'updateUsername',
                payload: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            placeholder="Enter your password"
            value={state.password}
            label="Password *"
            onChange={(e) => {
              dispatchState({
                type: 'updatePassword',
                payload: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText error>{state.error}</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Forgot your password?{' '}
            <ScreenNavLink screen="forgotPassword" text="Reset password" />
          </Typography>
          {/*<Typography>*/}
          {/*  No account?{' '}*/}
          {/*  <ScreenNavLink screen="signUp" text="Sign up" />*/}
          {/*</Typography>*/}
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={state.isProcessing}
            disabled={isFieldsEmpty}
            type="submit"
            variant="contained"
            color="primary"
          >
            Sign In
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
