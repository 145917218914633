import React, { FC, useCallback, useRef, useState, MouseEvent } from "react";
import { Popover, IconButton } from "@material-ui/core";
import { FilterListOutlined } from "@material-ui/icons";
import { TextFilter } from "../TextFilter";
import { DateFilter } from "../DateFilter";
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";

export type FilterOptions = {
  name: string;
  type?: "text" | "date";
  placeholder?: string;
};

export type Props = {
  options: FilterOptions;
  setPrevFilterVal: any;
};

function renderFilter({ type, name, placeholder }: FilterOptions) {
  switch (type) {
    case "date":
      return <DateFilter name={name} />;
    default:
      return <TextFilter name={name} placeholder={placeholder} />;
  }
}

const useStyles = makeStyles({
  popover: {
    padding: 8,
  },
});

export const FilterItem: FC<Props> = (props) => {
  const { options, setPrevFilterVal } = props;
  const styles = useStyles();
  const anchorEl = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const { values } = useFormikContext<any>();
  const value = values[options.name];
  const isFilled =
    value && (Array.isArray(value) ? value.some((v) => !!v) : value);
  React.useEffect(() => {
    if (value) {
      const filterTamplet = { [options.name]: value };
      setPrevFilterVal(filterTamplet);
    }
  }, []);
  const toggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setIsOpen((value) => !value);
    },
    [setIsOpen]
  );

  return (
    <>
      <IconButton
        ref={anchorEl}
        size="small"
        onClick={toggle}
        color={isFilled ? "secondary" : undefined}
      >
        <FilterListOutlined />
      </IconButton>
      <Popover
        classes={{
          paper: styles.popover,
        }}
        anchorEl={anchorEl.current}
        open={isOpen}
        onClose={toggle}
      >
        {renderFilter(options)}
      </Popover>
    </>
  );
};
