import React from 'react';
import { makeStyles } from '@material-ui/core';

export const buttonsUseStyles = makeStyles({
  main: {
    marginRight: 8,
    marginBottom: 8,
    marginTop: 8,
    marginLeft: 8
  },
});
