/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:76778d0a-dc3c-46be-a0f1-3283d948f114",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FJDUwJ9TK",
    "aws_user_pools_web_client_id": "2sqmuot9h33u8m84la06nks6ca",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://62fmplikqc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://szqwwhrdfvdhvfinebsxo5snoq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "billenoteamplifyapp-20201103211509-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://billenoteamplifyapp-20201103211509-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
