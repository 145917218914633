import React, { FC, useEffect } from 'react';
import { CircularProgress, Grid, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions,
  loadUserProfileTenantsRequestName,
} from '../../store/tenants';
import { RootState } from '../../store/rootReducer';
import { isProcessingSelector } from '../../store/requests';

export type Props = {
  name: string;
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
};

export const TenantSelector: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { onChange, value, disabled, name } = props;
  const items = useSelector(
    (state: RootState) => state.tenants.data.listTenants?.items ?? []
  );
  const isLoading = useSelector(
    isProcessingSelector([loadUserProfileTenantsRequestName])
  );

  const localStorageRows = localStorage.getItem("pageRows");

  useEffect(() => {
    dispatch(
      actions.load({  })
    );
  }, [dispatch]);

  if (isLoading) {
    return (
      <Grid container item xs={12} justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Select
      name={name}
      disabled={disabled}
      value={value || ''}
      onChange={onChange as any}
    >
      {items.map((item) => (
        <MenuItem key={item.name} value={item.name}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};
