import React, { PropsWithChildren, useState } from 'react';
import * as Yup from 'yup';
import {ADMINS, isSuperUser, Roles, SUPERS} from '../../store/user';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { TenantField } from './components/TenantField';
import NumberFormat from 'react-number-format';
import {usePermission} from "../Permission";

const phoneRegExp = /([+]\d{1,2}[.-/\s]?)(\d{3}[.-]?){2}\d{4}/;
const attributesSchema = {
  first_name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(35, 'Must be 35 characters or less')
  .required('Required'),
  last_name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(35, 'Must be 35 characters or less')
  .required('Required'),
  specialty: Yup.string()
    .nullable()
  .required('Required'),
  practice: Yup.string()
      .nullable()
  .required('Required'),
  email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
};

const commonFieldsSchema = {
  username: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(35, 'Must be 35 characters or less')
    .required('Required'),
  groups: Yup.string().required('Required'),
};

export const validationSchemasWithPassword = Yup.object({
  ...commonFieldsSchema,
  password: Yup.string().required('Required').min(8),
});

const useStyles = makeStyles(({ palette: { text: { primary } } }) => ({
  root: {
    maxWidth: 700,
    margin: '0 auto',
    '& input:disabled': {
      color: primary,
    },
    '& [aria-disabled]': {
      color: primary,
    },
    '& [class~="Mui-disabled"] svg': {
      visibility: 'hidden',
    },
  },
  submitButton: {
    marginRight: 8,
  },
}));

export type Props<T> = {
  initialValues: T;
  disabled?: boolean;
  mode: 'create' | 'edit' | 'view';
  onSubmit: (values: T) => void;
};

export const getFieldTestId = (name: string) =>
  `user-profile-form-field-${name}`;
const getInputProps = (name: string) => ({
  'data-testid': getFieldTestId(name),
});
export const submitButtonTestId = 'user-profile-form-submit';
export const editButtonTestId = 'user-profile-form-edit';

export function UserProfileForm<T>(props: PropsWithChildren<Props<T>>) {
  const styles = useStyles();
  const { onSubmit, initialValues, disabled, mode } = props;
  const useRole = useSelector((state: RootState) => state.user.role);
  const isSuperAdmin = useRole.includes(Roles.SuperAdmin);
  const isSuper =  usePermission(SUPERS);
  const isAdmin = usePermission(ADMINS);
  const isSuperApprover = useRole.includes(Roles.SuperApprover);
  const [isTenantRequired, setIstenantRequired] = useState(
    !isSuperUser(Roles.SuperAdmin)
  );
  const [edit, setEdit] = useState(mode !== 'edit');
  const readOnly = mode === 'view' || !edit;
  const hasTenant = !!get(initialValues, 'tenant', false);
  const isSuperRolesVisible = isSuperAdmin || mode === 'view';
  const isRegularRolesVisible = !isSuperRolesVisible || mode === 'create';

  return (
    <Formik
      validateOnBlur={false}
      validationSchema={Yup.object({
        ...commonFieldsSchema,
          ...attributesSchema,
          ...(isTenantRequired
            ? {
                'tenant': Yup.string().required('Required').min(2),
              }
            : {}),
          ...(mode === 'create'
            ? {
                password: Yup.string().required('Required').min(8),
              }
            : {}),
      })}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Grid item xs={12}>
        <Form className={styles.root}>
          <Grid container spacing={2}>
            {mode === 'create' && (
              <Grid item xs={12}>
                <Field name="username">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      value={field.value || ''}
                      error={edit && !!meta.error}
                      helperText={edit && meta.error}
                      label="User name"
                      inputProps={getInputProps(field.name)}
                    />
                  )}
                </Field>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field name="first_name">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="First name"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
             <Grid item xs={12}>
              <Field name="last_name">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Last name"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            {mode === 'create' && (
              <Grid item xs={12}>
                <Field name="password">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      type="password"
                      disabled={readOnly}
                      value={field.value || ''}
                      error={edit && !!meta.error}
                      helperText={edit && meta.error}
                      label="Password"
                      inputProps={getInputProps(field.name)}
                    />
                  )}
                </Field>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field name="email">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Email address"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="phone_number">
                {({ field, meta }: FieldProps) => (
                  <NumberFormat
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    placeholder="+12345678910"
                    label="Phone number"
                    inputProps={getInputProps(field.name)}
                    format="+1##########"
                    mask="_"
                    customInput={TextField}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="practice">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Name of practice"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="specialty">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Specialty"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="groups">
                {(fieldProps: FieldProps) =>
                  fieldProps.field.value && (
                    <FormControl fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select
                        {...fieldProps.field}
                        onChange={(e) => {
                          fieldProps.field.onChange(e);
                          setIstenantRequired(
                            !isSuperUser(e.target.value as any)
                          );
                        }}
                        disabled={readOnly}
                        inputProps={getInputProps(fieldProps.field.name)}
                      >
                          {(isSuperAdmin && <MenuItem value={Roles.SuperAdmin}>SuperAdmin</MenuItem>)}
                          {(isSuper && <MenuItem value={Roles.SuperApprover}>SuperApprover</MenuItem>)}
                          {(isSuper && <MenuItem value={Roles.Coder}>Coder</MenuItem>)}
                          {(isAdmin && <MenuItem value={Roles.Admin}>Admin</MenuItem>)}
                          <MenuItem value={Roles.Approver}>Approver</MenuItem>
                          <MenuItem value={Roles.DataEntry}>DataEntry</MenuItem>
                      </Select>
                    </FormControl>
                  )
                }
              </Field>
            </Grid>
            {(isAdmin && <TenantField disabled={readOnly} />)}
            <Grid item xs={12}>
              {mode !== 'view' && edit && (
                <Button
                  disabled={disabled}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={styles.submitButton}
                  data-testid={submitButtonTestId}
                >
                  Submit
                </Button>
              )}
              {mode === 'edit' && (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={disabled}
                  onClick={() => setEdit((value) => !value)}
                  data-testid={editButtonTestId}
                >
                  {edit ? 'Cancel' : 'Edit'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Formik>
  );
}
