import {call, put, select, takeLatest} from 'redux-saga/effects';
import requestSaga, {Request} from '../requestSaga';
import {createOpNote} from '../../graphql/mutations';
import {actions, EdNote} from '../edNotes';
import {RootState} from '../rootReducer';
import {User} from '../user';
import {forwardTo} from '../../history';
import fileDownload from 'js-file-download';
import {eventGA} from "../../utils/googleAnalytics";

const limit = 20;

type EdNotesResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: EdNote[];
  };
} | void;

const formatEdNote = (res: EdNotesResponse) => {
  if (!res) {
    return {listEdNotes: null};
  }

  return {
    listEdNotes: {
      ...res.data.results,
      items: res.data.results.map((item) => ({
        ...item,
      })),
      count: res.data.count,
      next: res.data.next,
      previous: res.data.previous,
    },
  };
};

export const loadEdNotesRequestName = 'loadEdNotes';

function* loadEdNotesSaga(action: ReturnType<typeof actions.load>) {
  const res: EdNotesResponse = yield call(requestSaga, {
      name: loadEdNotesRequestName,
      drf: {
        method: 'get',
        url: action.payload ? `/api/v1/ed-notes/?${action.payload}` : `/api/v1/ed-notes/`,
        perPage: limit,
      },
    },
  );

  if (res && res.data) {
    yield put(actions.loaded(formatEdNote(res)));
    yield call(eventGA, 'EdNotes', `Load ${limit} EdNotes`, 'Table')
  }
}

export const loadMoreEdNotesRequestName = 'loadMoreEdNotes';

function* loadMoreEdNotesSaga() {
  // @ts-ignore
  const nextUrl = yield select(
    (state: RootState) => state.edNotes.data.listEdNotes?.next ?? null
  );
  const res: EdNotesResponse = yield call(requestSaga, {
      name: loadMoreEdNotesRequestName,
      errorNotification: {
        message: 'Invalid token.',
      },
      drf: {
        method: 'get',
        url: nextUrl,
      },

    },
  );

  if (res) {
    yield put(actions.loadedMore(formatEdNote(res)));
    yield call(eventGA, 'EdNotes', `Load ${limit} EdNotes`, 'Table')
  }
}

export const loadEdNoteRequestName = 'loadEdNote';

function* loadEdNoteSaga(action: ReturnType<typeof actions.loadedEdNote>) {
  const response: Request<EdNote> = yield call(requestSaga, {
      name: loadEdNoteRequestName,
      drf: {
        method: 'get',
        url: `/api/v1/ed-notes/${action.payload}`,
      },
    },
  );
  if (response) {
    yield put(actions.loadedEdNote(response.data));
    yield call(eventGA, 'EdNotes', `Load ${action.payload} EdNote`, 'Link')
  }
}

export const createEdNoteRequestName = 'createEdNote';

function* createEdNoteSaga(action: ReturnType<typeof actions.create>) {
  const response: { data: EdNote | null } | null = yield call(requestSaga, {
      name: createEdNoteRequestName,
      drf: {
        method: 'post',
        url: `/api/v1/ed-notes/`,
        data: {
          'text': action.payload.input?.text,
        }
      },
      errorNotification: {
        message: 'Something went wrong. Please try again later.',
      },
    },
  );

  if (response && response.data) {
    yield forwardTo(`/ednotes/${response.data.id}`);
    yield call(eventGA, 'EdNotes', 'Create EdNote', 'Button')
  }
}

export const updateEdNoteRequestName = 'updateEdNote';

function* updateEdNoteSaga(action: ReturnType<typeof actions.update>) {
  const user: User = yield select((state: RootState) => state.user.data);
  const response: { data: EdNote | null } | null = yield call(requestSaga, {
      drf: {
        method: 'patch',
        url: `/api/v1/ed-notes/${action.payload.id}/`,
        data: {
          'text': action.payload.text,
          'updated_by': user.username,
        }
      },
      name: updateEdNoteRequestName,
      successNotification: {
        message: 'EdNote has been updated',
      },
    },
  );
  if (response && response.data) {
    yield put(
      actions.updated({
        data: response.data,
        updater: user.username,
      })
    );
    yield call(eventGA, 'EdNotes', 'Update EdNote', 'Button')
  }
}

export const deleteEdNoteRequestName = 'deleteEdNote';

function* deleteEdNoteSaga() {
  // @ts-ignore
  const opNote = yield select(
    (state: RootState) => state.edNotes.selectedEdNote
  );
  if (opNote) {
    // @ts-ignore
    const response = yield call(requestSaga, {
        drf: {
          method: 'delete',
          url: `/api/v1/ed-notes/${opNote.id}/`,
        },
        name: deleteEdNoteRequestName,
        successNotification: {
          message: 'EdNote has been deleted',
        },
      },
    );

    if (response) {
      yield put(actions.deleted());
      yield forwardTo('/');
      yield call(eventGA, 'EdNotes', 'Delete EdNote', 'Button')
    }
  }
}

export const deleteEdNotesRequestName = 'deleteEdNotes';

function* deleteEdNotesSaga(action: ReturnType<typeof actions.deleteEdNotes>) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {
        drf: {
          method: 'post',
          url: `/api/v1/ed-notes/delete/`,
          data: {
            'ed_notes': action.payload
          }
        },
        name: deleteEdNotesRequestName,
        successNotification: {
          message: `${action.payload.length} EdNotes has been deleted`,
        },
      },
    );
    if (response) {
      yield call(eventGA, 'EdNotes', `Delete ${action.payload.length} EdNotes`, 'Button')
    }
  }
}

export const downloadEdNoteRequestName = 'downloadEdNote';

function* downloadEdNoteSaga() {
  // @ts-ignore
  const edNote = yield select(
    (state: RootState) => state.edNotes.selectedEdNote
  );
  if (edNote) {
    // @ts-ignore
    const response = yield call(requestSaga, {
        drf: {
          method: 'get',
          type: 'blob',
          url: `/api/v1/ed-notes/${edNote.id}/download/`,
        },
        name: downloadEdNoteRequestName,
      },
    );

    if (response) {
      fileDownload(response.data, response.headers['content-disposition'].split('filename=')[1]);
      yield call(eventGA, 'EdNotes', `Export ${edNote.id} EdNote`, 'Button')
    }
  }
}

export const downloadEdNotesRequestName = 'downloadEdNotes';

function* downloadEdNotesSaga(action: ReturnType<typeof actions.downloadEdNotes>) {
  if (action.payload) {
    // @ts-ignore
    const response = yield call(requestSaga, {

        drf: {
          method: 'post',
          type: 'blob',
          url: `/api/v1/ed-notes/download/`,
          data: {
            'ed_notes': action.payload
          }
        },
        name: downloadEdNotesRequestName
      },
    );

    if (response) {
      fileDownload(response.data, response.headers['content-disposition'].split('filename=')[1]);
      yield call(eventGA, 'EdNotes', `Export ${action.payload.length} EdNotes`, 'Button')
    }
  }
}

export const saga = function* opNotesSaga() {
  yield takeLatest(actions.load.type, loadEdNotesSaga);
  yield takeLatest(actions.loadMore.type, loadMoreEdNotesSaga);
  yield takeLatest(actions.create.type, createEdNoteSaga);
  yield takeLatest(actions.loadEdNote.type, loadEdNoteSaga);
  yield takeLatest(actions.update.type, updateEdNoteSaga);
  yield takeLatest(actions.delete.type, deleteEdNoteSaga);
  yield takeLatest(actions.deleteEdNotes.type, deleteEdNotesSaga);
  yield takeLatest(actions.download.type, downloadEdNoteSaga);
  yield takeLatest(actions.downloadEdNotes.type, downloadEdNotesSaga);
};