import { all } from 'redux-saga/effects';
import { saga as opNotesSaga } from './opNotes';
import { saga as edNotesSaga } from './edNotes';
import { saga as cptCodesSaga } from './cptCodes';
import { saga as userSaga } from './user';
import { saga as usersSaga } from './users';
import { saga as tenantsSaga } from './tenants';
import { saga as feedbacksSaga } from './feedbacks';

export default function* rootSaga() {
  yield all([
    opNotesSaga(),
    edNotesSaga(),
    cptCodesSaga(),
    userSaga(),
    usersSaga(),
    tenantsSaga(),
    feedbacksSaga(),
  ]);
}
