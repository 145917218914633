import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Grid, Table as MUITable, TableProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FilterOptions } from "../Filters/FilterItem";
import { SearchableSortDirection } from "../../API";
import {
  TableFiltersContent,
  TableFiltersContentProps,
} from "./components/TableFiltersContent";
import { LoadRequestParams } from "../../store/opNotes";
import { FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import filtersToQuery from "../../utils/filtersToQuery";
import { Breadcrumbs } from "../Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  root: {
    wordBreak: "break-word",

    "& tr:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },

    "& button": {
      wordBreak: "normal",
    },
  },
}));

export const Table: FC<TableProps> = (props) => {
  const styles = useStyles();
  const { className = "", ...rest } = props;

  return <MUITable className={`${styles.root} ${className}`} {...rest} />;
};

export type Sort = {
  field: string;
  dir: SearchableSortDirection;
};
export type TableFiltersColumn = {
  label?: string | ReactElement;
  name: string;
  sort?: boolean;
  filter?: FilterOptions;
  format?: (value: any) => string | number;
  render?: (data: any) => ReactElement;
};

export type TableFiltersProps<T> = {
  loadingMore: boolean;
  hasMore: boolean;
  onSubmitMore: (values: FormikValues, sort?: Sort) => void;
} & Omit<TableFiltersContentProps<T>, "loadMore">;

const useTableFiltersStyles = makeStyles({
  grid: {
    flexDirection: "column",
  },
});

export const TableFilters: FC<TableFiltersProps<any>> = (props) => {
  const {
    replace,
    location: { pathname, search },
  } = useHistory();
  const {
    onSubmit,
    loadingMore,
    onSubmitMore,
    initialValues,
    initialSort,
    breadcrumbs,
    ...rest
  } = props;
  const queryValues = (search.split("?")[1] || "")
    .split("&")
    .reduce<{ sortBy?: string; sortDir?: SearchableSortDirection }>(
      (res, item) => {
        const [key, value] = item.split("=");

        return {
          ...res,
          [key]: value,
        };
      },
      {}
    );
  const { sortBy, sortDir, ...initialFilters } = queryValues;
  const filter = initialFilters || initialValues;
  const sort =
    sortBy && sortDir ? { field: sortBy, dir: sortDir } : initialSort;
  const [requestData, setRequestData] = useState<LoadRequestParams>({
    filter,
    sort,
  });
  const styles = useTableFiltersStyles();

  const loadMore = useCallback(
    ({ currentPage, sort: sortVal, filter, pageRows }, isReplace) => {
      if (isReplace) replace(`${pathname}?${filtersToQuery(filter, sortVal)}`);
      onSubmitMore({
        page: currentPage,
        sort: sortVal || requestData?.sort,
        filter,
        pageRows: pageRows,
      });
    },
    [onSubmitMore, requestData]
  );

  useEffect(() => {
    replace(
      `${pathname}?${filtersToQuery(requestData?.filter, requestData?.sort)}`
    );
    onSubmit(requestData?.filter ?? {}, requestData?.sort);
  }, [onSubmit, replace, pathname]);

  return (
    <Grid className={`heightWrapper ${styles.grid}`} container spacing={2}>
      <TableFiltersContent
        onSubmit={useCallback(
          (values, sort) => {
            setRequestData({
              sort,
              filter: values,
            });
          },
          [setRequestData]
        )}
        loadingMore={loadingMore}
        loadMore={loadMore}
        initialValues={filter}
        initialSort={sort}
        breadcrumbs={breadcrumbs}
        {...rest}
      />
    </Grid>
  );
};
