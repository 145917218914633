import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Page} from '../../layout/Page';
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  actions,
  deleteEdNoteRequestName, downloadEdNoteRequestName,
  loadEdNoteRequestName,
  EdNote,
  updateEdNoteRequestName,
} from '../../store/edNotes';
import {Breadcrumbs, BreadcrumbsItem} from '../../Components/Breadcrumbs';
import {RootState} from '../../store/rootReducer';
import {isProcessingSelector} from '../../store/requests';
import usePrevious from '../../hooks/usePrevious';
import {ConfirmationDialog} from '../../Components/ConfirmationDialog';
import {usePermission} from '../../Components/Permission';
import {Roles} from '../../store/user';
import {NotFound} from '../NotFound';
import {Expandable} from '../../Components/Expandable';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CreateIcon from "@material-ui/icons/Create";
import {pageViewGA} from "../../utils/googleAnalytics";

const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/ednotes',
    title: 'EdNotes',
  },
  {
    title: 'View EdNote',
  },
];

const useStyles = makeStyles({
  form: {
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      padding: 8,
      flexBasis: 'auto',
    },
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);

export const ViewEdNote: FC = () => {
  const styles = useStyles();
  const classes = useStyles2();
  const {push} = useHistory();
  const {id} = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [suggestedCPTCodesListSize, setSuggestedCPTCodesListSize] = useState(
    10
  );
  const [suggestionMode, setSuggestionMode] = useState('');
  const [editingText, setEditingText] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [details, setDetails] = useState<EdNote | null>(null);
  const isLoading = useSelector(isProcessingSelector([loadEdNoteRequestName]));
  const isDownloading = useSelector(
    isProcessingSelector([downloadEdNoteRequestName])
  );
  const isUpdating = useSelector(
    isProcessingSelector([updateEdNoteRequestName])
  );
  const isDeleting = useSelector(
    isProcessingSelector([deleteEdNoteRequestName])
  );
  const data = useSelector((state: RootState) => state.edNotes.selectedEdNote);
  const previousData = usePrevious(data);
  const isDataEntry = usePermission([Roles.DataEntry]);
  const isCoder = usePermission([Roles.Coder]);
  const waitingForSuggestionMode = !isDataEntry && !isCoder && !suggestionMode;

  const handleEdenDetails = useCallback(() => {
    setDetails(data);
  }, [setDetails, data]);
  const handleCloseDetails = useCallback(() => {
    setDetails(null);
  }, [setDetails]);
  const handleEditBtnClick = useCallback(() => {
    setIsEditable((value) => {
      if (value) {
        setShowEditConfirmation(true);
        return value;
      }
      setEditingText(data?.text ?? '');
      return true;
    });
  }, [setIsEditable, setEditingText, data]);
  const handleChangeText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEditingText(e.target.value);
    },
    [setEditingText]
  );
  const handleDialogClose = useCallback(() => setShowConfirmation(false), [
    setShowConfirmation,
  ]);
  const handleDialogSubmit = useCallback(() => {
    dispatch(actions.delete());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.loadEdNote(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      data &&
      previousData &&
      data !== previousData &&
      editingText &&
      isEditable
    ) {
      setEditingText('');
      setIsEditable(false);
    }
  }, [
    data,
    previousData,
    editingText,
    isEditable,
    setEditingText,
    setIsEditable,
  ]);
  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, [dispatch]);

  if (!isLoading && !data) {
    return <NotFound/>;
  }

  return (
    <Page withoutScroll={!isEditable}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (data) {
            dispatch(
              actions.update({
                id: data.id,
                text: editingText,
              })
            );
          }
        }}
      >
        {isLoading ? (
          <Grid container item xs={12} justify="center">
            <CircularProgress/>
          </Grid>
        ) : data ? (
          <>
            <Grid container item xs={12} justify="space-between">
              <Breadcrumbs items={breadcrumbs}/>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => push('/ednotes/new')}
                  startIcon={<CreateIcon/>}
                  className={classes.button}
                >
                  Add EdNote
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => push('/opnotes/upload')}
                  startIcon={<CloudUploadIcon/>}
                  className={classes.button}
                >
                  Upload
                </Button>
              </div>
            </Grid>
            {data.filename ? (<Grid item xs={12}>
              <FormLabel>Filename:</FormLabel>
              <Typography className={styles.text} component="pre">
                    {data.filename}
              </Typography>
            </Grid>) : (<Grid item xs={12}>
              <FormLabel>ID:</FormLabel>
              <Typography className={styles.text} component="pre">
                    {data.id}
              </Typography>
            </Grid>)
            }
            <Grid item xs={12}>
              <FormLabel>Text:</FormLabel>
              {isEditable ? (
                <TextField
                  fullWidth
                  multiline
                  value={editingText}
                  onChange={handleChangeText}
                />
              ) : (
                <Expandable>
                  <Typography className={styles.text} component="pre">
                    {data.text}
                  </Typography>
                </Expandable>
              )}
            </Grid>
            <Grid container item xs={12} alignItems="center" wrap="nowrap">
              <div style={{marginLeft: 'auto', flexShrink: 0}}>
                {!isEditable && (
                  <Button
                    color="default"
                    variant="contained"
                    disabled={isDownloading}
                    style={{marginRight: 16, marginBottom: 8}}
                    startIcon={<CloudDownloadIcon/>}
                    onClick={() => {
                      dispatch(actions.download())
                    }}
                  >
                    Download
                  </Button>
                )}
                {isEditable && (
                  <Button
                    disabled={isUpdating}
                    style={{marginRight: 16, marginBottom: 8}}
                    type="submit"
                    color="default"
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
                {
                  <Button
                    color="primary"
                    variant="contained"
                    style={{marginBottom: 8}}
                    onClick={handleEditBtnClick}
                  >
                    {isEditable ? 'Cancel' : 'Edit'}
                  </Button>
                }
              </div>
            </Grid>
          </>
        ) : null}

        <ConfirmationDialog
          disabled={isDeleting}
          text="You are going to delete the EdNote"
          open={showConfirmation}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
        />
        <ConfirmationDialog
          text="The changes you made will be lost. Are you sure you want to leave editing mode?"
          open={showEditConfirmation}
          onClose={() => {
            setShowEditConfirmation(false);
          }}

          onSubmit={() => {
            setIsEditable(false);
            setShowEditConfirmation(false);
          }}
        />
      </form>
    </Page>
  );
};
