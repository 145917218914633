import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import { actions, loadUserProfileRequestName, User } from "../../store/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { ForgotPassword } from "./components/ForgotPassword";
import { ChangePassword } from "./components/ChnagePassword";
import { SignIn } from "./components/SignIn";
import { SignUp } from "./components/SignUp";
import { customAxios } from "../../store/customAxios";
import { useHistory } from "react-router-dom";

export type Screens = "signIn" | "signUp" | "changePassword" | "forgotPassword";

export type Context = {
  setScreen: (screen: Screens) => void;
};

export const AuthenticatorContext = createContext<Context>({
  setScreen: () => null,
});

const useStyles = makeStyles({
  root: {
    width: 380,
    padding: 20,
    margin: "0 auto",
    boxShadow: "rgba(0, 0, 0, 0.15) 1px 1px 4px 0px",
  },
});

export const Authenticator: FC = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [screen, setScreen] = useState<Screens>("signIn");
  const [authorizedUser, setAuthorizedUser] = useState<User | null>(null);
  const [initialised, setInitialised] = useState(false);
  const history = useHistory();

  const user = useSelector((state: RootState) => state.user.data);
  const userRole = useSelector((state: RootState) => state.user.role);

  const handleUser = useCallback(async () => {
    try {
      const user: { data: User } = await customAxios.get(
        "/api/v1/accounts/profile/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      dispatch(actions.loadProfile(null));
      dispatch(actions.setUser(user.data));
      dispatch(actions.loadGroups());
      dispatch(actions.loadRole());
      dispatch(actions.loadTenantId());
      localStorage.setItem('userId',user?.data?.id)
    } catch (err) {
      setScreen("signIn");
      setInitialised(true);
    }
  }, [dispatch]);

  useEffect(() => {
    handleUser();
  }, [handleUser]);
  useEffect(() => {
    if (userRole.length) {
      setInitialised(true);
    }
  }, [userRole]);

  const renderContent = () => {
    switch (screen) {
      case "changePassword":
        return <ChangePassword user={authorizedUser} onSuccess={handleUser} />;
      case "forgotPassword":
        return <ForgotPassword />;
      case "signUp":
        return <SignUp />;
      default:
        return (
          <SignIn
            onSignedIn={(user) => {
              setAuthorizedUser(user);
              if (!user.agreement) {
                setScreen("changePassword");
                return;
              } else {
                handleUser();
              }
            }}
          />
        );
    }
  };

  if (!initialised) {
    return null;
  }

  if (user && user.id && window.location.pathname === "/login") {
    history.push("/opnotes/new");
  }
  if (user) {
    return <>{props.children}</>;
  }

  return (
    <div className={styles.root}>
      <AuthenticatorContext.Provider
        value={{
          setScreen,
        }}
      >
        {renderContent()}
      </AuthenticatorContext.Provider>
    </div>
  );
};
