import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import logoBottom from "../../image/logo02.png";
import "./footer.css";

const Footer = () => {
  const { pathname } = useLocation();
  const isLogin = pathname === "/login";
  if (isLogin) return <></>;

  return (
    <>
      <Container fluid style={{ background: "#F8F9F9" }}>
        <Container className="mainContainer">
          <div className="footerLogo">
            <img src={logoBottom} alt="logo" />
          </div>
          <Row className="rowCustom">
            <Col md={7} lg={8} xl={9} className="copyright-main-container">
              <span className="copyRight-span">
                <div className="rights-text">
                  © 2021 GaleAI. All rights reserved.
                </div>
              </span>
            </Col>

            <Col className="mb-4 column company">
              <div
                className="com-text-group text-group footer-custom-group"
                style={{ padding: "1rem 0 0 0" }}
              >
                <NavLink
                  className="ab-text"
                  to="/about"
                  style={{ textDecoration: "none" }}
                >
                  About Us
                </NavLink>
                <NavLink
                  className="ab-text"
                  to="/contact"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </NavLink>
                <NavLink
                  className="ab-text"
                  to="/solution"
                  style={{ textDecoration: "none" }}
                >
                  Solution
                </NavLink>
              </div>
            </Col>

            {/* <Col className="mb-4 column company">
              <div className="com-text">Company</div>
              <div className="com-text-group text-group" style={{ padding: "1rem 0 0 0" }}>
                <NavLink
                  className="ab-text"
                  to="/about"
                  style={{ textDecoration: "none" }}
                >
                  About Us
                </NavLink>
                <NavLink
                  className="ab-text"
                  to="/contact"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </NavLink>
                <div className="ab-text">Terms of Service</div>
                <div className="ab-text">Privacy Policy</div>
              </div>
            </Col>
            <Col className="mb-4 column product">
              <div className="com-text">Product</div>
              <div className="text-group" style={{ padding: "1rem 0 0 0" }}>
                <NavLink
                  className="ab-text"
                  to="/solution"
                  style={{ textDecoration: "none" }}
                >
                  Solution
                </NavLink>
                <div className="ab-text">Pricing</div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Footer;
