import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, TextField, Grid, FormLabel } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import { actions, createEdNoteRequestName } from '../../store/edNotes';
import { actions as userActions} from '../../store/user';
import { isProcessingSelector } from '../../store/requests';
import { Breadcrumbs, BreadcrumbsItem } from '../../Components/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {RootState} from "../../store/rootReducer";
import {pageViewGA} from "../../utils/googleAnalytics";

const regExp = /\S/;
const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/ednotes',
    title: 'EdNotes',
  },
  {
    title: 'Enter new EdNote',
  },
];

export const NewEdNote: FC = () => {
  const [note, setNote] = useState('');
  const { push } = useHistory();
  const isProcessing = useSelector(
    isProcessingSelector([createEdNoteRequestName])
  );
  const agreement = useSelector(
    (state: RootState) => state.user.data?.agreement ?? ''
  );
  const dispatch = useDispatch();
  const isValidNote = useMemo(() => {
    return regExp.test(note);
  }, [note]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setNote(e.target.value);
    },
    [setNote]
  );
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      dispatch(
        actions.create({
          input: { text: note.trim() },
        })
      );
    },
    [note, dispatch]
  );

  const [open, setOpen] = React.useState(!agreement);
  const handleAgree = () => {
    dispatch(userActions.agreement());
    setOpen(false);
  };

  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);
  return (
    <Page>
      <Grid item xs={12}>
       <Dialog
        open={open}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Test title</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
          Test text
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify="space-between">
              <Breadcrumbs items={breadcrumbs} />
              <div>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => push('/ednotes/upload')}
                  startIcon={<CloudUploadIcon />}
                  style={{ marginRight: 16 }}
                >
                  Upload
                </Button>
                <Button
                  disabled={isProcessing || !isValidNote}
                  type="submit"
                  color="default"
                  variant="contained"
                >
                  Submit
              </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Please enter EdNote:</FormLabel>
              <TextField
                fullWidth
                multiline
                value={note}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Page>
  );
};
