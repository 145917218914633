import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../../../store/requests';
import {actions, deleteCPTCodesRequestName} from "../../../../store/cptCodes";
import { ConfirmationDialog } from '../../../../Components/ConfirmationDialog';
import useToggle from '../../../../hooks/useToggle';
import {buttonsUseStyles} from "../../../../layout/Styles/Buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import {eventGA} from "../../../../utils/googleAnalytics";

export type Props = {
  selected: Set<string>
};

export const DeleteCptCodes: FC<Props> = (props) => {
  const { selected } = props;
  const dispatch = useDispatch();
  const { value: isOpen, close, open } = useToggle();
  const isLoading = useSelector(isProcessingSelector([deleteCPTCodesRequestName]));

  const handleToggle = useCallback(() => {
    dispatch(actions.deleteCptCodes(Array.from(selected)));
    selected.clear();
  }, [selected, dispatch]);

  const handleSubmit = useCallback(() => {
    handleToggle();
    close();
  }, [close, handleToggle]);

  return (
    <>
      <Button
        className={buttonsUseStyles().main}
        color="secondary"
        size={"small"}
        variant="contained"
        disabled={isLoading || selected.size === 0}
        startIcon={<DeleteIcon/>}
        onClick={open}
      >
        Delete selected
      </Button>
      <ConfirmationDialog
        disabled={isLoading}
        open={isOpen}
        onClose={close}
        title="Warning!"
        inputConfirmationText="delete"
        text="Attention! Cpt Codes will be deleted and cannot be restored."
        onSubmit={handleSubmit}
      />
    </>
  );
};
