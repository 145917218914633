import React, { useState } from "react";
import "@fontsource/plus-jakarta-sans";
import { Container, Col, Row, Button } from "react-bootstrap";

import "./about.css";
import "../Home/index.css";
import BioModel from "./BioModel";
import r01 from "../../image/r01.png";
import r02 from "../../image/r02.png";
import r03 from "../../image/r03.png";
import r04 from "../../image/r04.png";
import brad from "../../image/bradNew.jpg";
import grant from "../../image/grantNew.jpg";
import group1 from "../../image/group1.svg";
import group2 from "../../image/group2.svg";
import tyler from "../../image/tyler.png";
import Rectangle from "../../image/Rectangle.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const About = () => {
  const [bio, setBio] = useState(false);
  const history = useHistory();
  type ItemProp = {
    name: string;
    qualification: string;
    designation: string;
    dp: string;
    description1: string;
    description2: string;
    description3: string;
  };

  const [modalData, setModalData] = useState<ItemProp | {}>({});

  const leadersData = [
    {
      name: "Brad Gillman",
      qualification: "M.D.",
      designation: "Chief Operations Officer",
      dp: brad,
      description1:
        "Dr. Gillman is a Board-certified, fellowship trained hand and upper extremity surgeon currently practicing in Austin, Texas. He earned his Bachelor of Science Degree from the University of Wisconsin at Platteville, Masters of Science and Doctorate of Medicine from the University of Wisconsin School of Medicine and Public Health. He completed his orthopaedic surgery residency training at Louisiana State University in Shreveport, and two post-graduate fellowships, including a hand and microsurgery fellowship at Allegheny Hospital in Pittsburgh, PA and the acclaimed Rockwood shoulder and elbow fellowship in San Antonio, Texas. His surgical expertise includes hand and microvascular surgery and complex shoulder and elbow reconstruction through open and arthroscopic techniques.",
      description2:
        "Dr. Gillman has spent time throughout his career developing surgical techniques and implant design. He is currently working with companies to develop unique upper extremity surgical implants to improve healing times and decreased surgical comorbidity. He has numerous peer-reviewed journal articles, book chapters, and presentations at national conferences, and is a member of the American Board of Orthopaedic Surgery (ABOS), American Academy of Orthopaedic Surgery (AAOS), and American Society of Surgery of the Hand (ASSH). Brad dedicates his time to the improvement of healthcare delivery, efficiency, and cost savings in his practice.",
      description3:
        "Outside of medicine, Dr. Gillman is an avid scuba diver, with rescue diver certification. He has traveled to countless countries throughout Southeast Asia and Central America in his quest for new cultural and personal experiences. He enjoys spending time with friends and family and stays active with swimming and working out.",
    },

    
    {
      name: "Grant Muller",
      qualification: "M.D.",
      designation: "Chief Technology Officer",
      dp: grant,
      description1:
        "Dr. Muller is a fellowship trained foot and ankle orthopaedic surgeon. He currently practices in Austin, TX. He completed his undergraduate degree in microbiology at the University of Georgia. His medical training began at Emory University School of medicine where he was voted “Person you would most like to be stuck on call with.” From there he took a brief stop in Lexington, KY to complete his intern year in General surgery. After that he headed out west to University of Texas Health Science Center at Houston, now known as McGovern Medical School where he completed a residency in Orthopaedics at the #1 AO ranked trauma program in the country. He flew south and completed a 1 year fellowship in Foot and Ankle at the Miami Ankle Foot International Alliance (M.A.F.I.A) where he was one of Dr. Tom SanGiovanni and Dr. Chris Hodgkins’s Goodfellows. Grant spent a brief time down under completing a fellowship in Osseointegration with the esteemed Professor Munjed Al-Muderis in Sydney Austraila with the Osseointegration Group of Australia and will be one of a handful of surgeons performing this procedure in the US.",
      description2:
        "It all began in first grade. Grant’s dad, also Dr. Muller, was tired of paying his patient to come fix the family’s Tandy 386 and so he said “Grant, watch him and see what he does.” He was hooked from then on. His mom loves to tell the story of him getting pulled out of class in the 4th grade to go fix a computer. He ran his own IT company in high school, ran his own web design studio in college and then worked with the Tannenbaum lab at Georgia Tech while he was at Emory working on computer vision. AI has been an interest of his for some time now. He has never had any formal computer training (unless you count Mavis Beacon teaches typing). His coding style is described as “make a change until it stops breaking.”",
      description3:
        "Grant’s hobbies include pursuing his 15 year long and counting quest of trying to get to the weight he was in highschool. He has dreams of commanding a Golden Retriever Army. He enjoys jogging, peloton, stand up paddleboarding, cooking, and video games and trying to teach a computer to do things for him. His religion on the most recent census was marked as “UGA Football.",
    },


    {
      name: "Kenneth Nwosu",
      qualification: "M.D. M.B.A",
      designation: "Head of Research",
      dp: Rectangle,
      description1:
        "“Dr. Nwosu earned his Bachelor of Science from California State University San Bernardino. He completed medical school at David Geffen School of Medicine at UCLA, and subsequently his Orthopaedic Surgery residency at Harbor-UCLA Medical Center. Dr. Nwosu then moved east to complete a spine surgery fellowship at the Harvard Combined Spine Fellowship Program. He subsequently earned an MBA at the University of Washington Foster School of Business and has been deeply involved in the booming healthcare-technology sector in the Seattle area since.",
      description2:
        "Dr. Nwosu philosophy of consistently challenging the status quo and living outside his comfort zone propelled him from an impoverished household in Lagos Nigeria, to where he is today. He is passionate about solving complex problems by demonstrating value and is immensely gratified by giving back via philanthropic activities. He has embodied these characteristics as a leader and team member of several ventures. After college, he founded a Tutor Center that provided free haircuts to students in the underserved neighborhood where he grew up. He also organized several Medical Mission to Nigeria during medical school which he continues to partake in today after co-founding an Orthopaedic Hospital in Lagos. He has subsequently been involved in several other business activities including co-founding ScheduleMate Inc., and most recently founding Axis Spine LLC; a healthcare and technology incubator and accelerator focused on surgery. He also hosts an annual Healthcare-Technology convention that attracts the brightest minds to discuss advances in the fields. ",
      description3:
        "These minds have the tendency to play hard as well partaking in cross country skiing and snow shoeing in the North Cascades of the Pacific Northwest. His entrepreneurial vigor overflows into his practice as a Spine Surgeon where he is consistently practicing cutting-edge robotic and endoscopic techniques to address chronic pain and mobility issues caused by underlying spinal disease or injury with a goal of providing the highest value to the patient and the healthcare system. These practices are supported by his academic rigor over several published manuscripts, podium presentations, and textbook chapters. During his spare time. Dr. Nwosu loves to travel with his growing family, play competitive soccer and ping-pong, and hike.",
    },

    {
      name: "Tyler Gonzalez",
      qualification: "M.D.",
      designation: "Chief Executive Officer",
      dp: tyler,
      description1:
        "Dr. Gonzalez is a fellowship-trained Orthopaedic Foot and Ankle Surgeon. He earned his Bachelor of Science from University of California Berkeley, MBA from UCLA Anderson School of Management, and medical degree from UCLA David Geffen School of Medicine. Dr. Gonzalez completed his residency training in orthopaedic surgery at Harvard Medical School, then went on to pursue a subspecialty fellowship training in orthopaedic foot and ankle surgery at Cedar Sinai Medical Center in Los Angeles.Throughout his career, Dr. Gonzalez has focused on improving patient care, medical education, entrepreneurship and research. His committed interest and passion in translational, clinical and biomechanical research has prompted over 75 peer-reviewed scientific publications and presentations as well as several book chapters in orthopedic surgery. Additionally, Dr. Gonzalez routinely lectures in regional, national and international forums on foot and ankle topics and improving value-based patient care. As such, Dr. Gonzalez acts as an active reviewer for both Foot and Ankle International , Foot and Ankle Orthopaedics and Foot and Ankle Specialist. He is a member of various foot and ankle surgery societies including the AAOS, the American Orthopaedic Foot and Ankle Society (AOFAS),AOFAS Post Graduate Education Committee and the AOFAS Physicians Resource Center(PRC) Committee.He is also the President and Co-Founder of Orthopaedic Foot and Ankle Society, Inc. a collaboration of foot and ankle surgeons to improve delivery of health care, innovate on current surgical technologies and advance overall patient care.",
      description2:
        "Dr. Gonzalez is a healthcare entrepreneur with experience in biotech and health tech startups. He was COO and head of Research Development for the biotech start up Phase Scientific form 2010-2012 and helped build their platform for development and large commercialization. Currently Dr. Gonzalez has changed his focus to the healthcare sector to improve the lives of patients all over the world by improving healthcare delivery and provider care. He has Co- founded and is CEO of both Pombo and Medbuddy, medical education applications to improve research and patient care as well as and Redwood Tech, LLC a healthcare driven Think Tank to optimize and improve healthcare delivery, technology and education.",
      description3:
        "Outside of these clinical interests, Dr. Gonzalez’s passions include medical education, entrepreneurship, healthcare management and policy, inventing anything and everything, patient quality and safety, value based healthcare research and clinical outcomes research.",
    },
  ];

  const ModalClickHandler = (item: ItemProp) => {
    setModalData(item);
    setBio(!bio);
  };

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col style={{ padding: "5rem 0 0 0" }}>
            <div className="about-text aboutChange">
              At the crossroads of healthcare and technology is GaleAI.
            </div>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="about-desc aboutChangeDesc">
                <span className="about-desc">
                  As doctors, we know how much of a chore day-to-day operations
                  can be. We, as surgeons, have always been taught to value
                  efficiency and bring that ideal to the forefront to help you
                  work smarter.
                </span>
                <span className="about-desc">
                  Using our highly skilled team of physicians, engineers,
                  coders, and insurance specialists, we ensure that every note
                  entered sees the highest amount of reimbursement it can
                  ethically achieve. Understanding that the medical coding and
                  billing realm is constantly changing, we are continually
                  analyzing new data to take note of new patterns, trends, and
                  possibilities for further improvement and implementation.
                </span>

                <span className="about-desc">
                  Because we live it, we must improve it. The future of
                  healthcare is GaleAI.”
                </span>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col
            className="d-flex justify-content-center"
            md={{ span: 8, offset: 2 }}
          >
            <Button
              variant="outline-success"
              className="btn-btn-get-home"
              onClick={() => history.push("/solution")}
              style={{ width: "300px" }}
            >
              &nbsp;Explore&nbsp; the&nbsp; solution&nbsp;
            </Button>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className="our-about-text1">Our Leaders</div>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="our-about-desc ourChange">
                Our team has extensive knowledge and vast experience across
                healthcare and technology sectors. We inherently share a sense
                of purpose and a willingness to innovate to resolve the most
                persistent challenges to healthcare. We are dedicated to
                transforming the future of healthcare with AI.”
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <Container className="d-flex align-center justify-content-center">
        <Row className="mainGroup" style={{ padding: "5rem 0 0 0" }}>
          {leadersData?.map((item, index) => {
            return (
              <Col
                md={3}
                sm={4}
                xs={12}
                className="mb-5 personGroup"
                key={index}
              >
                <div className="Img-main">
                  <div className="Img-css">
                    <img src={item.dp} alt="bio" />
                  </div>
                  <div className="Img-desc">
                    <div className="bio-name-text-about">
                      <span>{item.name}</span> <span>{item.qualification}</span>
                      <div className="desc-about-text">{item.designation}</div>
                    </div>
                    <div>
                      <span
                        className="bio-btn-text"
                        onClick={() => ModalClickHandler(item)}
                      >
                        + &nbsp;Read More
                      </span>
                      <span className="bio-btn-text linkedinIcon">
                        <a href="https://www.linkedin.com" target="_blank">
                          <FontAwesomeIcon icon={faLinkedin as IconProp} />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className="board-text">Board of Advisors</div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row style={{ padding: "4rem 0 64px 0" }}>
          <Col md={3} className="mb-5 pb-1 mb-sm-4 col-board">
            <div>
              <img src={r03} alt="bio" />
            </div>
            <div className="board-bio-name-text">
              EJ Liao{" "}
              <span className="linkedinIcon">
                <a href="https://www.linkedin.com" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin  as IconProp} />
                </a>
              </span>
            </div>
            <div className="board-desc-about-text">
              VP of Product at Forethought.
              <br />
              Angel Investor, & Mentor Advisor for Techstars Indy Sports
              Accelerator. <br />
              Former product leader with Amazon, Yahoo, and early stage
              startups.
            </div>
          </Col>

          <Col md={3} className="mb-5 pb-1 mb-sm-4 col-board">
            <div>
              <img src={r04} alt="bio" />
            </div>
            <div className="board-bio-name-text">
              George Runger{" "}
              <span className="linkedinIcon">
                <a href="https://www.linkedin.com" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} />
                </a>
              </span>
            </div>
            <div className="board-desc-about-text">
              Professor in School of Computing and Augmented Intelligence at
              ASU.
            </div>
          </Col>

          <Col md={3} className="mb-5 pb-1 mb-sm-4 col-board">
            <div>
              <img src={r01} alt="bio" />
            </div>
            <div className="board-bio-name-text">
              Paul Barsley{" "}
              <span className="linkedinIcon">
                <a href="https://www.linkedin.com" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} />
                </a>
              </span>
            </div>
            <div className="board-desc-about-text">
              Founder, COO and VP of engineering of IDEO{" "}
            </div>
          </Col>

          <Col md={3} className="mb-5 pb-1 mb-sm-4 col-board">
            <div>
              <img src={r02} alt="bio" />
            </div>
            <div className="board-bio-name-text">
              Phil Marcoux{" "}
              <span className="linkedinIcon">
                <a href="https://www.linkedin.com" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} />
                </a>
              </span>
            </div>
            <div className="board-desc-about-text">
              Silicon Valley CEO.
              <br />
              Company Founder, Mentor and Coach for startups.
              <br />
              Investor of medical software.
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
      <BioModel show={bio} onHide={() => setBio(!bio)} modalData={modalData} />
    </>
  );
};

export default About;
