import React, { useState } from "react";
import { Button, Container, Form, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import "./contact.css";

const Contact = () => {
  const history = useHistory();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      title: title,
      company_name: company,
      message: message,
    };
    if (!firstName || !lastName || !email || !title || !company || !message) {
      toast.error("All fields are required");
    } else
      fetch("https://api.dev.galeai.co/v1/contacts", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Thanks for your feedback");
            setFirstName("");
            setLastName("");
            setTitle("");
            setCompany("");
            setMessage("");
            setError("");
            setTimeout(() => {
              history.push("/");
            }, 2000);
          } else {
            setError("Some thing went wrong, try again later");
            toast.error("Some thing went wrong, try again later");
          }
        })
        .catch((err) => toast.error("Some thing went wrong on server, Please try again later"));
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="col-contact-text">
            <div className="contact-text">Let’s connect!</div>
            <Col className="contact-Col">
              <div className="contact-text-second">
                Whether you need customer support, are interested in partnering
                with GaleAI, on the hunt for your next world-changing gig, or
                simply want to learn more about AI, the conversation starts
                here. Send us a message today to connect with the future of
                medical billing and coding.
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <Container className="formContainerMain">
        <Row>
          <Col>
            <Form className="form-wrapper" onSubmit={(e) => handleSubmit(e)}>
              <Row>
                <Col md={6} className="mb-4">
                  <Form.Group as={Col} controlId="formGridText">
                    <Form.Label className="form-firstName">
                      First Name
                    </Form.Label>
                    <Form.Control
                      className="form-control-placeholder"
                      type="text"
                      placeholder="Jane"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-4">
                  <Form.Group as={Col} controlId="formGridText">
                    <Form.Label className="form-firstName">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className="form-control-placeholder"
                      type="text"
                      placeholder="Doe"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="formGridEmail">
                <Form.Label className="form-firstName">Work Email</Form.Label>
                <Form.Control
                  className="form-control-placeholder mb-4"
                  type="email"
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Row>
                <Col md={6} className="mb-4">
                  <Form.Group as={Col} controlId="formGridText">
                    <Form.Label className="form-firstName">Title</Form.Label>
                    <Form.Control
                      className="form-control-placeholder"
                      type="text"
                      placeholder=""
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-4">
                  <Form.Group as={Col} controlId="formGridText">
                    <Form.Label className="form-firstName">Company</Form.Label>
                    <Form.Control
                      className="form-control-placeholder"
                      type="text"
                      placeholder=""
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                controlId="exampleForm.ControlTextarea1"
                className="mb-4"
              >
                <Form.Label className="form-firstName">Message</Form.Label>
                <Form.Control
                  className="form-control-placeholder textArea"
                  as="textarea"
                  rows={4}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
              {/* {error && <p className="errorMessage">{error}</p>} */}
              <Button
                className="contact-btn-submit mb-4"
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <div style={{ position: "relative" }}>
        <iframe
          title="map"
          width="100%"
          height="450px"
          loading="lazy"
          className="ifram"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3445.8791056617442!2d-97.75429238555081!3d30.269025614884615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b58b71aef655%3A0x29234688d076a3c0!2s801%20W%205th%20St%2C%20Austin%2C%20TX%2078703%2C%20USA!5e0!3m2!1sen!2sin!4v1634647447996!5m2!1sen!2sin"
        />
        <div className="maps">
          <Row>
            <Col>
              <div className="our-map">Our Offices</div>
              <div className="address-map">
                801 W 5TH ST APT 1801 Austin, TX 78703 United States
              </div>
              <div className="open-map">Open in Maps</div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
