import React, {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {isProcessingSelector} from "../../../../store/requests";
import {cancelButtonTestId, submitButtonTestId, textTestId} from "../../../../Components/ConfirmationDialog";
import useToggle from "../../../../hooks/useToggle";
import {buttonsUseStyles} from "../../../../layout/Styles/Buttons";
import EmailIcon from "@material-ui/icons/Email";
import {FormikValues} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import {RootState} from "../../../../store/rootReducer";
import {actions as actionsOpNotes, loadMoreOpNotesRequestName, loadOpNotesRequestName, OpNote} from "../../../../store/opNotes";
import {actions as actionsUsers, alertUsersAPIRequestName} from "../../../../store/users";
import {NavLink} from "react-router-dom";
import formatDate from "../../../../utils/formatDate";
import {TableFilters} from "../../../../Components/Table";


const getFilter = (values: FormikValues) => {
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : '';
  return value ? `${fieldName}=${value}` : '';
};

const useStyles = makeStyles({
  text: {
    maxHeight: 70,
    overflow: 'hidden',
  },
  dialogPaper: {
        minHeight: '80vh',
        maxWidth: '100vh',
    },
});

export type Props = {
  selected: Set<string>
};

export const AlertUsers: FC<Props> = (props) => {
  const {selected} = props;
  const dispatch = useDispatch();
  const {value: isOpen, close, open} = useToggle();
  const styles = useStyles();
  const storeItems = useSelector((state: RootState) => state.opNotes.data.listOpNotes?.items ?? []);
  const isLoadingUsersAlert = useSelector(isProcessingSelector([alertUsersAPIRequestName]));
  const isLoadingOpNotes = useSelector(isProcessingSelector([loadOpNotesRequestName]));
  const isLoadingOpNotesMore = useSelector(isProcessingSelector([loadMoreOpNotesRequestName]));
  const hasMore = useSelector((state: RootState) => !!(state.opNotes.data.listOpNotes?.next ?? null));
  const [selectedOpNotes, setSelectedOpNotes] = useState<Set<string>>(new Set());
  const allSelectableOpNotesIds = useMemo(() => {
    return storeItems.reduce<string[]>((res, opNote) => {
      return [...res, opNote.id];
    }, []);
  }, [storeItems]);
  const handleSelectOpNotesAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedOpNotes(new Set(e.target.checked ? allSelectableOpNotesIds : []));
    },
    [setSelectedOpNotes, allSelectableOpNotesIds]
  );
  const handleToggle = useCallback(() => {
    dispatch(actionsUsers.alertUsers({ users: Array.from(selected), op_notes: Array.from(selectedOpNotes)}));
    selected.clear();
    selectedOpNotes.clear();
  }, [selected, selectedOpNotes, dispatch]);

  const handleSubmit = useCallback(() => {
    handleToggle();
    close();
  }, [close, handleToggle]);

  return (
    <>
      <Button
        className={buttonsUseStyles().main}
        color="primary"
        size={"small"}
        variant="contained"
        disabled={isLoadingOpNotes || selected.size === 0}
        startIcon={<EmailIcon/>}
        onClick={open}
      >
        Alert selected
      </Button>
      <Dialog open={isOpen} onClose={close} maxWidth={"lg"}
      >
        <DialogContent>
          <Typography variant="h5">User alert</Typography>
          <Typography data-testid={textTestId}>Notify selected users of added OpNotes</Typography>
          <Grid className="heightWrapper" item xs={12}>
            <TableFilters
              singleColumnFilter
              hasMore={hasMore}
              loading={isLoadingOpNotes}
              loadingMore={isLoadingOpNotesMore}
              columns={[
                {
                  label: <Checkbox
                    color="primary"
                    checked={selectedOpNotes.size === allSelectableOpNotesIds.length}
                    onChange={handleSelectOpNotesAllChange}
                  />,
                  name: '',
                  render: (data: OpNote) => (
                    <Checkbox
                      checked={selectedOpNotes.has(data.id)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          selectedOpNotes.add(data.id);
                        } else {
                          selectedOpNotes.delete(data.id);
                        }
                        setSelectedOpNotes(new Set(selectedOpNotes));
                      }}
                      color="primary"
                    />
                  ),
                },
                {
                  label: 'ID',
                  name: 'id',
                  filter: {
                    name: 'id',
                  },
                  render: (data: OpNote) => (
                    <NavLink title={data.id} to={`/opnotes/${data.id}`}>
                      {data.id.split('-')[0]}
                    </NavLink>
                  ),
                },
                {
                  label: 'Created',
                  name: 'created_at',
                  format: formatDate,
                  filter: {
                    name: 'created',
                    type: 'date'
                  }
                },
                {
                  label: 'Created By',
                  name: 'created_by',
                  filter: {
                    name: 'created_by',
                  },
                },
                {
                  label: 'Tenant',
                  name: 'tenant',
                  filter: {
                    name: 'tenant',
                  },
                },
                 {
              label: 'CPT Codes',
              name: 'cpt_codes_approved',
              filter: {
                name: 'cpt_codes_approved',
              },
              format: (data) =>
                data
                  .map((item: string) => item)
                  .join(', '),
            },
                {
                  label: 'OpNotes',
                  name: 'text',
                  filter: {
                    name: 'text',
                  },
                  render: (data: OpNote) => (
                    <Typography className={styles.text} title={data.text}>
                      {data.text}
                    </Typography>
                  ),
                },
              ]}
              columnsData={storeItems}
              onSubmit={useCallback(
                (values) => {
                  dispatch(actionsOpNotes.load(getFilter(values)));
                },
                [dispatch]
              )}
              onSubmitMore={useCallback(
                (values) => {
                  // dispatch(actionsOpNotes.loadMore());
                },
                [dispatch]
              )}
              initialValues={{}}
              colGroup={
                <colgroup>
                  <col width={10}/>
                  <col width={150}/>
                  <col width={150}/>
                  <col width={150}/>
                  <col width={150}/>
                  <col width={150}/>
                </colgroup>
              }
            />
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            // disabled={disabled}
            onClick={close}
            variant="contained"
            color="primary"
            data-testid={cancelButtonTestId}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            data-testid={submitButtonTestId}
            disabled={isLoadingUsersAlert || selectedOpNotes.size === 0}
          >
            Alert
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
