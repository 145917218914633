import React from "react";
import "@fontsource/plus-jakarta-sans";
import { Container, Col, Row, Button } from "react-bootstrap";

import "./solution.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useHistory } from "react-router";

const Solution = () => {
  const history = useHistory();
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="solutionMainCol">
            <div className="solution-text-natural">
              Natural Language Processing
            </div>
            <div className="solution-text-natural-secondary">
              The language of the future
            </div>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="solution-text-second-natural">
                The painstaking chore of medical billing and coding practices
                among clinical practitioners continually drives lost revenues
                into the billions, creates space for unknowingly fraudulent
                billing practices, facilitates lost earning potential for
                providers, and has increased risk of suicide amongst those
                caring for patients.
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <div className="back-cover">
        <Row className="back-cover-col">
          <Col>
            <div className="backFirstPara">
              The medical billing and coding process has become so convoluted
              that there is an entire industry devoted to solving coding issues.
              That ends with us.
            </div>
          </Col>
        </Row>
      </div>

      <Container>
        <Row>
          <Col>
            <Col
              md={{ span: 8, offset: 2 }}
              style={{ padding: "0 2rem 0 1rem" }}
            >
              <div className="solution-text-second">
                <h1 style={{ fontWeight: "800", color: "black" }}>
                  Our solution simplifies.
                </h1>
                Our primary job responsibilities are complex enough. We
                shouldn’t have to spend countless hours piecing together a
                puzzle to get paid for our care.  Our solution is simple. Using
                Natural Language Processing and advanced machine learning, we
                can easily access deep neural nets to learn how to optimally and
                efficiently handle the process of coding- without the headache.
                By aggregating this data, we can make it easier on everyone
                involved.
                <div className="header-btn custom-button">
                  <Button
                    variant="outline-success"
                    className="btn-btn-get-home"
                    onClick={() => history.push("/opnotes/new")}
                  >
                    &nbsp;Get&nbsp; started&nbsp; for&nbsp; free&nbsp;
                    now!&nbsp;
                  </Button>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />

      {/* <Container fluid style={{ background: '#F8F9F9' }}>
        <Container style={{ padding: '4rem 0 0 0' }}>
          <Row>
            <Col md={8}>
              <div>
                <img src={logoBottom} alt="logo" />
              </div>
              <div className="rights-text">
                © 2021 Gale AI. All rights reserved.
              </div>
              <Row xs="auto" style={{ padding: '1rem 0 0 0' }}>
                <Col>
                  <div>
                    <img src={facebook} alt="fb" />
                  </div>
                </Col>
                <Col>
                  <div>
                    <img src={twitter} alt="fb" />
                  </div></Col>
                <Col>
                  <div>
                    <img src={instagram} alt="fb" />
                  </div></Col>
                <Col>
                  <div>
                    <img src={mail} alt="fb" />
                  </div></Col>
              </Row>
            </Col>
            <Col>
              <div className="com-text">
                Company
              </div>
              <div style={{ padding: '1rem 0 0 0' }}>
                <div className="ab-text">
                  About Us
                </div>
                <div className="ab-text">
                  Blog
                </div>
                <div className="ab-text">
                  Contact Us
                </div>
                <div className="ab-text">
                  Terms of Service
                </div>
                <div className="ab-text">
                  Privacy Policy
                </div>
              </div>
            </Col>
            <Col>
              <div className="com-text">
                Product
              </div>
              <div style={{ padding: '1rem 0 0 0' }}>
                <div className="ab-text">
                  Solution
                </div>
                <div className="ab-text">
                  Pricing
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container> */}
    </>
  );
};
export default Solution;
