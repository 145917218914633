import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Checkbox, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CreateIcon from "@material-ui/icons/Create";
import EmailIcon from "@material-ui/icons/Email";
import { NavLink } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Sort, TableFilters, TableFiltersColumn } from "../../Components/Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { isProcessingSelector } from "../../store/requests";
import {
  actions,
  loadMoreOpNotesRequestName,
  loadOpNotesRequestName,
  OpNote,
} from "../../store/opNotes";
import formatDate from "../../utils/formatDate";
import { usePermission } from "../../Components/Permission";
import { ADMINS, APPROVERS, SUPERS } from "../../store/user";
import useUserRoleChecker from "../../hooks/useUserRoleChecker";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { forwardTo } from "../../history";
import { buttonsUseStyles } from "../../layout/Styles/Buttons";
import { DeleteOpNotes } from "./components/DeleteOpNotes";
import { FormikValues } from "formik";
import { BreadcrumbsItem } from "../../Components/Breadcrumbs";
import { pageViewGA } from "../../utils/googleAnalytics";
import { SearchableSortDirection } from "../../API";
import cx from "classnames";

const initialSort = {
  field: "created_at",
  dir: SearchableSortDirection.desc,
};

const getFilter = (values: FormikValues, sort: Sort | undefined) => {
  const sortStringValue = `${
    sort ? `ordering=${sort.dir === "desc" ? "-" : ""}${sort.field}` : ""
  }`;
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : "";

  return value ? `${fieldName}=${value}&${sortStringValue}` : { sort: sort };
};

const useStyles = makeStyles({
  text: {
    maxHeight: 70,
    overflow: "hidden",
  },
  addBtn: {
    background: `#29abef`,
  },
  uploadBtn: {
    background: "#1d4354",
    color: "white",
  },
});

export const OpNotes: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const storeItems = useSelector(
    (state: RootState) => state.opNotes.data.listOpNotes?.items ?? []
  );
  const storeCount = useSelector(
    (state: RootState) => state.opNotes.data.listOpNotes?.count ?? 0
  );
  const isCreatedByVisible = usePermission([...ADMINS, ...APPROVERS]);
  const isLoading = useSelector(isProcessingSelector([loadOpNotesRequestName]));
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreOpNotesRequestName])
  );
  const isSuperUser = useUserRoleChecker(SUPERS);
  const hasMore = useSelector(
    (state: RootState) => !!(state.opNotes.data.listOpNotes?.next ?? null)
  );
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const allSelectableIds = useMemo(() => {
    return storeItems.reduce<string[]>((res, opNote) => {
      return [...res, opNote.id];
    }, []);
  }, [storeItems]);
  const handleSelectAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(new Set(e.target.checked ? allSelectableIds : []));
    },
    [setSelected, allSelectableIds]
  );
  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: "OpNotes",
      href: "/opnotes",
    },
    {
      title: `${storeCount} items`,
    },
  ];
  const handleAdd = useCallback(() => {
    forwardTo("/opnotes/new");
  }, [forwardTo]);
  const handleUpload = useCallback(() => {
    forwardTo("/opnotes/upload");
  }, [forwardTo]);
  const handleFeedback = useCallback(() => {
    forwardTo("/feedbacks/new");
  }, [forwardTo]);
  const handleExport = useCallback(() => {
    setSelected(new Set());
    dispatch(actions.downloadOpNotes(Array.from(selected)));
  }, [dispatch, selected]);

  useEffect(() => {
    pageViewGA(window.location.pathname + window.location.search);
  }, []);
  // @ts-ignore
  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          initialSort={initialSort}
          singleColumnFilter
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          breadcrumbs={breadcrumbs}
          columns={[
            {
              label: (
                <Checkbox
                  color="primary"
                  checked={selected.size === allSelectableIds.length}
                  onChange={handleSelectAllChange}
                />
              ),
              name: "",
              render: (data: OpNote) => (
                <Checkbox
                  checked={selected.has(data.id)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      selected.add(data.id);
                    } else {
                      selected.delete(data.id);
                    }
                    setSelected(new Set(selected));
                  }}
                  color="primary"
                />
              ),
            },
            {
              label: "ID",
              name: "id",
              filter: {
                name: "id",
              },
              render: (data: OpNote) => (
                <NavLink title={data.id} to={`/opnotes/${data.id}`}>
                  {data.id.split("-")[0]}
                </NavLink>
              ),
              sort: true,
            },
            {
              label: "Created",
              name: "created_at",
              format: formatDate,
              filter: {
                name: "created",
                type: "date",
              },
              sort: true,
            },
            ...(isCreatedByVisible
              ? ([
                  {
                    label: "Created By",
                    name: "created_by",
                    filter: {
                      name: "created_by",
                    },
                    sort: true,
                  },
                ] as TableFiltersColumn[])
              : []),
            {
              label: "Updated",
              name: "updated_at",
              format: formatDate,
              filter: {
                name: "updated",
                type: "date",
              },
              sort: true,
            },
            {
              label: "Updated By",
              name: "updated_by",
              filter: {
                name: "updated_by",
              },
              sort: true,
            },
            ...(isSuperUser
              ? [
                  {
                    label: "Tenant",
                    name: "tenant",
                    filter: {
                      name: "tenant",
                    },
                    sort: true,
                  },
                ]
              : []),
            {
              label: "CPT Codes",
              name: "cpt_codes_approved",
              filter: {
                name: "cpt_codes_approved",
              },
              sort: true,
              format: (data) => data.map((item: string) => item).join(", "),
            },
            {
              label: "Filename",
              name: "filename",
              filter: {
                name: "filename",
              },
              sort: true,
            },
            {
              label: "OpNotes",
              name: "text",
              filter: {
                name: "text",
              },
              sort: true,
              render: (data: OpNote) => (
                <Typography className={styles.text} title={data.text}>
                  {data.text}
                </Typography>
              ),
            },
          ]}
          columnsData={storeItems}
          onSubmit={useCallback(
            (values, sort) => {
              dispatch(actions.load(getFilter(values, sort)));
            },
            [dispatch]
          )}
          onSubmitMore={useCallback(
            (value: FormikValues) => {
              dispatch(actions.load(value));
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAdd}
                startIcon={<CreateIcon />}
                className={cx(buttonsUseStyles().main, styles.addBtn)}
              >
                Add OpNote
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={handleUpload}
                startIcon={<CloudUploadIcon />}
                className={cx(buttonsUseStyles().main, styles.uploadBtn)}
              >
                Upload
              </Button>
            </div>
          }
          colGroup={
            <colgroup>
              <col width={10} />
              <col width={150} />
              <col width={150} />
              <col width={170} />
              {isSuperUser && <col width={150} />}
              {isCreatedByVisible && <col width={170} />}
              <col width={170} />
              <col width={170} />
              <col width={170} />
            </colgroup>
          }
        />
        <Grid container>
          <Grid container item xs={6}>
            <Button
              className={cx(buttonsUseStyles().main, styles.addBtn)}
              color="primary"
              size={"small"}
              variant="contained"
              disabled={selected.size === 0}
              startIcon={<CloudDownloadIcon />}
              onClick={handleExport}
            >
              Export selected
            </Button>
            <DeleteOpNotes selected={selected} uploadBtn={styles.uploadBtn} />
          </Grid>
          <Grid justify="flex-end" container item xs={6}>
            <Button
              className={cx(buttonsUseStyles().main, styles.uploadBtn)}
              color="default"
              size={"small"}
              variant="contained"
              startIcon={<EmailIcon />}
              onClick={handleFeedback}
            >
              Leave feedback
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
