import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@material-ui/core';

export type BreadcrumbsItem = {
  href?: string;
  title: string;
};
export type Props = {
  items: BreadcrumbsItem[];
};

export const breadcrumbLinkTestId = 'breadcrumb-link';
export const breadcrumbTextTestId = 'breadcrumb-text';

export const Breadcrumbs: FC<Props> = memo((props) => {
  const { items } = props;

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {items.map((item, index) => {
        if (item.href) {
          return (
            <NavLink
              key={index}
              to={item.href}
              data-testid={breadcrumbLinkTestId}
            >
             <span >  {item.title}</span>
            </NavLink>
          );
        }

        return (
          <Typography
            key={index}
            color="textPrimary"
            data-testid={breadcrumbTextTestId}
          >
            {item.title}
          </Typography>
        );
      })}
    </MUIBreadcrumbs>
  );
});
